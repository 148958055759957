import {Record} from "@axys-lab/smart-report-shared";
import {Container, Header} from "semantic-ui-react";

import "./style.css"

export const RecordViewHeader = (props: {
    recordId: string,
    record: Record | null,
    editing: boolean,
    onEdit: (name: string) => void
}): JSX.Element => {
    const {editing, onEdit, recordId, record} = props;
    return (
        <Container
            textAlign="center"
            text={true}
        >
            {!editing ?
                <Header
                    as="h2"
                    content={record && record?.recordId && recordId === record.recordId ? record.name : ""}
                    inverted={true}
                    size="huge"
                /> :
                <p
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    className="record-header-edit"
                    onBlur={(e) => {
                        onEdit(e.target.innerText);
                    }}
                    onKeyDown={(e) => {
                        if (e.key.toLowerCase() == "enter") {
                            e.preventDefault();
                            onEdit((e.target as HTMLParagraphElement).innerText);
                            return false;
                        }
                        return true;
                    }}
                >
                    {record && record?.recordId && recordId === record.recordId ? record.name : ""}
                </p>
            }
        </Container>
    );
}