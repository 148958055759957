import * as React from "react";
import {useContext} from "react";
import {connect} from "react-redux";
import {Feature} from "@axys-lab/smart-report-shared";
import {State} from "../reducers";

type Context = {
    enabledFeatures: Feature[],
};

const UnderlyingContext = React.createContext<Context>({
    enabledFeatures: []
});

const mapStateToProps = (state: State) => ({
    value: {
        enabledFeatures: state.operations.features.features
    }
});

const mapDispatchToProps = () => ({});

// At least one feature must be enabled
export const useFeaturesEnabled = (...requiredFeatures: Feature[]): boolean => {
    const contextValue = useContext(UnderlyingContext)
    return !(requiredFeatures && requiredFeatures.length &&
        requiredFeatures.filter(requiredFeature => contextValue.enabledFeatures.includes(requiredFeature)).length < 1)
}

export const FeaturesProvider = connect(mapStateToProps, mapDispatchToProps)(UnderlyingContext.Provider);