import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {operation} from "../common";
import {USER_SESSION_OPEN, USER_SESSION_OPEN_ERROR, USER_SESSION_OPEN_SUCCESS} from "@axys-lab/smart-report-shared";
import {WEBSOCKET_CONNECTED, WEBSOCKET_DISCONNECTED, WEBSOCKET_RECONNECTED} from "../sockets";

export const session = operation(
    [
        {
            remote: true,
            sent: {
                actionCreator: USER_SESSION_OPEN,
                priority: 1000
            },
            success: USER_SESSION_OPEN_SUCCESS,
            errors: USER_SESSION_OPEN_ERROR,
            timeout: {
                trigger: () => USER_SESSION_OPEN_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }
    ],
    [
        WEBSOCKET_DISCONNECTED,
        WEBSOCKET_RECONNECTED,
        WEBSOCKET_CONNECTED
    ],
);