import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {
    USER_BASIC_DETAILS_UPDATE,
    USER_BASIC_DETAILS_UPDATE_ERROR,
    USER_BASIC_DETAILS_UPDATE_SUCCESS
} from "@axys-lab/smart-report-shared";

export const basicDetailsUpdate = operation(
    [{
        remote: true,
        sent: USER_BASIC_DETAILS_UPDATE,
        success: USER_BASIC_DETAILS_UPDATE_SUCCESS,
        errors: USER_BASIC_DETAILS_UPDATE_ERROR,
        timeout: {
            trigger: () => USER_BASIC_DETAILS_UPDATE_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
        }
    }],
    LOCATION_CHANGED,
);
