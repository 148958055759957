import {useEffect} from "react";
import {Container, Message, Segment} from "semantic-ui-react";
import {FileSelector} from "./file-selector";
import {FileUploadCancelButton} from "./file-upload-cancel-button";
import {FileUploadProgressBar} from "./file-upload-progress-bar";
import {useBoolean} from "react-use";
import {Accept, FileWithPath} from "react-dropzone";
import {FileUploadProgress} from "@axys-lab/smart-report-shared";
import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";

export type FileUploaderProps = {
    disabled?: boolean
    accept?: Accept,
    started: boolean,
    upload: (file: FileWithPath) => void
    cancel: () => void;
    progress: FileUploadProgress;
    errors: string[];
    className?: string
}

export const FileUploader = (props: FileUploaderProps): JSX.Element => {
    const [internalStarted, setStarted] = useBoolean(false);
    const {disabled, className, accept, upload, cancel, progress, errors, started} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    useEffect(() => {
        setStarted(started);
    }, [started])

    return (
        <Container className={`file-uploader ${className || ""}`}>
            <Segment basic={true} textAlign="center">
                {errors && errors.length ? (<Message
                    negative={true}
                    list={errors.map(error => t(error))}
                />) : (<></>)}
                <Segment basic={true}>
                    <FileSelector accept={accept} disabled={disabled || internalStarted} onFileSelected={(file) => {
                        setStarted(true);
                        upload(file);
                    }}/>
                </Segment>
                <FileUploadProgressBar visible={!disabled && internalStarted} progress={progress}/>
                <FileUploadCancelButton onClick={() => {
                    setStarted(false);
                    cancel();
                }} visible={!disabled && internalStarted}/>
            </Segment>
        </Container>
    );
};
