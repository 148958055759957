
import { Trans } from "react-i18next";
import {Header, Icon, SemanticICONS, Statistic} from "semantic-ui-react";
import { PRIMARY_COLOR } from "../../../utils/constants";

import "./style.css";

export const HomeFeature = (props: {
    i18nKey: string,
    icon?: SemanticICONS
}): JSX.Element => {
    const {i18nKey, icon} = props;
    return (
        <Statistic>
            <Statistic.Value>
                <Icon name={icon || "checkmark"} color={PRIMARY_COLOR}/>
            </Statistic.Value>
            <Statistic.Label>
                <Trans i18nKey={i18nKey}>
                    message
                    <Header>emphasis</Header>
                </Trans>
            </Statistic.Label>
        </Statistic>
    );
};
