import {connect} from "react-redux";
import {State} from "../reducers";
import {Record, RecordType} from "@axys-lab/smart-report-shared";
import {RecordViewDetailsMenu} from "../views/record-view/record-view-details-menu";
import {RECORD_EDIT_DETAILS} from "../ducks/operations/records/record-view/edit";

export const ConnectedRecordViewDetailsMenu = connect(
    (state: State) => ({
        record: state.operations.recordView.editOp.data.editing ?
            state.operations.recordView.editOp.data.draft :
            state.operations.recordView.data.record,
        editing: state.operations.recordView.editOp.data.editing
    }),
    (dispatch) => ({
        onEdit: (allowUsageinTrainingDataset: boolean, recordType: RecordType) => {
            dispatch(RECORD_EDIT_DETAILS({allowUsageinTrainingDataset, recordType}))
        },
    })
)((props: {
    recordId: string,
    record: Record | null,
    editing: boolean,
    onEdit: (allowUsageinTrainingDataset: boolean, recordType: RecordType) => void
}) => {
    const {editing, onEdit, record, recordId} = props;

    if (!record || record?.recordId && recordId !== record.recordId) {
        return <></>
    }

    return (
        <RecordViewDetailsMenu
            recordType={record.type}
            allowUsageinTrainingDataset={record.allowUsageinTrainingDataset}
            editing={editing}
            onEdit={onEdit}
        />);
});