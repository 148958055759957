/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return */
import {createReducer, getType, PayloadAction, PayloadActionCreator, Reducer} from "typesafe-actions";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {ErrorResponse} from "@axys-lab/microservices-common-shared";
import {registerRemoteMessageChannel} from "../../sagas/shared";
import {PrioritizedAction} from "../../utils/prioritized-action-channel";

export type OperationState = {
    status: RemoteCommandStatus;
    errors: string[];
};

export const initialOperationState: OperationState = {
    status: RemoteCommandStatus.NOT_STARTED,
    errors: []
};
export type ActionCreator<P> = PayloadActionCreator<string, P>;
export type ActionCreatorOrArray<P> = Array<ActionCreator<P>> | ActionCreator<P>

export type PrioritizedActionCreator<P> = {
    priority: number;
    actionCreator: ActionCreator<P>
}
export type PrioritizedActionCreatorOrArray<P> = Array<PrioritizedActionCreator<P>> | PrioritizedActionCreator<P>

export type OperationChannel = {
    remote: boolean,
    sent?: ActionCreatorOrArray<any> | PrioritizedActionCreatorOrArray<any>,
    success?: ActionCreatorOrArray<any>,
    errors?: Array<PayloadActionCreator<string, ErrorResponse>> | PayloadActionCreator<string, ErrorResponse>
    timeout?: {
        ms?: number,
        trigger: () => { type: string; payload: ErrorResponse; }
    }
};

const flattenActions = <AC>(
    channels: OperationChannel[],
    selector: (channel: OperationChannel) =>
        AC | AC[] | undefined
): Set<AC> => {
    return channels.reduce(
        (accumulator, value) => {
            const selected = selector(value);
            if (selected) {
                if (Array.isArray(selected)) {
                    selected.forEach(v => accumulator.add(v));
                } else {
                    accumulator.add(selected);
                }
            }
            return accumulator;
        },
        new Set<AC>()
    );
};

const normalizeSentChannelActions = <P>(
    channel: OperationChannel
): Array<string | PrioritizedAction> => {
    return (!channel.sent ? [] : Array.isArray(channel.sent) ? channel.sent : [channel.sent])
        .map(a => {
            if ((a as PrioritizedActionCreator<P>).priority) {
                return {
                    priority: (a as PrioritizedActionCreator<P>).priority,
                    action: getType((a as PrioritizedActionCreator<P>).actionCreator)
                }
            }
            return getType(a as ActionCreator<P>)
        });
};

const normalizeChannelActions = <P>(
    channel: OperationChannel,
    selector: (channel: OperationChannel) =>
        ActionCreatorOrArray<P> | undefined
): Array<string> => {
    const selected = selector(channel);
    return (!selected ? [] : Array.isArray(selected) ? selected : [selected])
        .map(a => getType(a));
};

export const customOperation = <T extends OperationState = OperationState>(
    channels: OperationChannel[],
    initialState: T,
    resetAction?: Array<PayloadActionCreator<string, unknown>> | PayloadActionCreator<string, unknown>,
): Reducer<T, any> => {

    let reducer = createReducer<T>(initialState);

    channels.forEach(channel => {
        if (channel.remote) {
            registerRemoteMessageChannel({
                sent: normalizeSentChannelActions(channel),
                received: [
                    ...normalizeChannelActions(channel, c => c.success),
                    ...normalizeChannelActions(channel, c => c.errors),
                ],
                timeout: channel.timeout
            });
        }
    });

    const sentActions = flattenActions(channels, c => c.sent);
    sentActions.forEach(t => {
            const actionCreator = (t as PrioritizedActionCreator<any>).priority ?
                (t as PrioritizedActionCreator<any>).actionCreator :
                t;
            reducer = reducer.handleAction(actionCreator, (state: T) => ({
                ...state,
                status: RemoteCommandStatus.PENDING,
                errors: []
            }))
        }
    );

    const successActions = flattenActions(channels, c => c.success);
    successActions.forEach(s =>
        reducer = reducer.handleAction(s, (state: T) => ({
            ...state,
            status: RemoteCommandStatus.SUCCESS,
            errors: []
        }))
    );

    const errorActions = flattenActions(channels, c => c.errors);
    errorActions.forEach(e =>
        reducer = reducer.handleAction(e, (state: T, action: PayloadAction<string, ErrorResponse>) => ({
            ...state,
            status: RemoteCommandStatus.ERROR,
            errors: action.payload.message_codes
        }))
    );

    const resetActions = (!resetAction ? [] : Array.isArray(resetAction) ? resetAction : [resetAction])
    resetActions.forEach(r =>
        reducer = reducer.handleAction(r, (state: T) => ({
            ...state,
            status: RemoteCommandStatus.NOT_STARTED,
            errors: []
        }))
    );

    return reducer;
};

export const operation = (
    channels: OperationChannel[],
    resetAction?: ActionCreatorOrArray<any>
): Reducer<OperationState, any> => customOperation(channels, initialOperationState, resetAction);
