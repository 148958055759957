import {all, put, takeLatest} from "redux-saga/effects";
import {FEATURE_FLAGS_CHANGED, FEATURES_GET, USER_SESSION_OPEN_SUCCESS} from "@axys-lab/smart-report-shared";
import {WEBSOCKET_CONNECTED} from "../ducks/operations/sockets";
import {registerRemoteMessageChannel} from "./shared";
import {getType} from "typesafe-actions";

registerRemoteMessageChannel({
    received: [getType(FEATURE_FLAGS_CHANGED)]
})

export function* getFeaturesSaga(): Generator {
    yield put(FEATURES_GET({}));
}

export function* featuresSaga(): Generator {
    yield all([
        takeLatest([WEBSOCKET_CONNECTED, FEATURE_FLAGS_CHANGED, USER_SESSION_OPEN_SUCCESS], getFeaturesSaga),
    ]);
}