import {useTranslation} from "react-i18next";
import {Container, Header, Segment} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {Layout} from "../../components/layout";

export const About = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Layout>
            <Segment inverted={true} vertical={true} padded="very" className="about-header fancy-header">
                <Container
                    textAlign="center"
                    text={true}
                >
                    <div className="about">
                        <Header
                            as="h2"
                            content={t("about")}
                            inverted={true}
                            size="huge"
                        />
                    </div>
                </Container>
            </Segment>
        </Layout>
    );
};
