import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { EmailValidationForm } from "../views/email-validation/email-validation-form";
import { State } from "../reducers";
import {EmailValidationToken, USER_EMAIL_VALIDATE} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.emailValidation.status,
    remoteErrors: state.operations.emailValidation.errors
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (token: EmailValidationToken) => dispatch(USER_EMAIL_VALIDATE(token))
});

export const ConnectedEmailValidationForm = connect(mapStateToProps, mapDispatchToProps)(EmailValidationForm);