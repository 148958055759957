import {object, ObjectSchema} from "yup";
import {SocialProviderSchema} from "../../utils";
import {SocialProvider} from "../../utils";

export type UserSocialProvider = {
    provider: SocialProvider;
}

export const UserSocialProviderSchema: ObjectSchema<UserSocialProvider> = object({
    provider: SocialProviderSchema()
}).required();