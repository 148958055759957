import {Action} from "redux";
import {all, call, cancel, cancelled, delay, fork, put, select, take, takeEvery} from "redux-saga/effects";
import {
    actionsTriggeringUserReload,
    USER_LOAD,
    USER_LOAD_NOT_FOUND,
    USER_LOAD_SUCCESS,
    UserTokenState
} from "../ducks/user";
import {State} from "../reducers";
import {JWT_REFRESH_FREQUENCY_IN_MS, SERVER_URL} from "../utils/constants";
import {getType, isActionOf} from "typesafe-actions";
import {WEBSOCKET_CONNECTED, WEBSOCKET_RECONNECTED} from "../ducks/operations/sockets";
import {
    SerializedJWT,
    USER_AUTHENTICATION_REFRESH,
    USER_AUTHENTICATION_REFRESH_ERROR,
    USER_AUTHENTICATION_REFRESH_SUCCESS,
    USER_EMAIL_VALIDATE_SUCCESS,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_SESSION_OPEN,
    USER_SESSION_OPEN_ERROR,
    USER_SESSION_OPEN_SUCCESS,
    USER_SOCIAL_LOGIN_SUCCESS,
    UserToken
} from "@axys-lab/smart-report-shared";
import {registerRemoteMessageChannel} from "./shared";

registerRemoteMessageChannel({
    sent: [getType(USER_AUTHENTICATION_REFRESH)],
    received: [
        getType(USER_AUTHENTICATION_REFRESH_SUCCESS),
        getType(USER_AUTHENTICATION_REFRESH_ERROR)
    ]
});

const localStorageKey = "jwt";

const getLoggedInUser = (state: State): UserTokenState | undefined => state.user.token;
const getToken = (state: State): SerializedJWT | undefined =>
    state.user.token ? state.user.token.serialized : undefined;

const updateServerCookie = async (userToken: UserToken) => {
    return await fetch(`${SERVER_URL}/api/users/cookies`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        redirect: "follow",
        body: JSON.stringify(userToken)
    });
}

const clearServerCookie = async () => {
    return await fetch(`${SERVER_URL}/api/users/cookies`, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        redirect: "follow",
    });
}

function* persistUser(): Generator<unknown> {
    const loggedIn: UserTokenState | undefined = (yield select(getLoggedInUser)) as (UserTokenState | undefined);
    if (loggedIn) {
        localStorage.setItem(localStorageKey, JSON.stringify(loggedIn));
        yield call(updateServerCookie, {token: loggedIn.serialized})
    }
}

function* loadUser() {
    const serialized = localStorage.getItem(localStorageKey);
    if (serialized) {
        yield put(USER_LOAD_SUCCESS(JSON.parse(serialized)));
    } else {
        yield put(USER_LOAD_NOT_FOUND({}));
    }
}

function* clearUser() {
    localStorage.removeItem(localStorageKey);
    yield call(clearServerCookie)
}

function* refreshToken() {
    const loggedIn: UserTokenState | undefined = yield select(getLoggedInUser);
    if (loggedIn) {
        yield put(USER_AUTHENTICATION_REFRESH({
            token: loggedIn.serialized
        }));
    }
}

function* refreshTokenLoop(): any {
    try {
        while (true) {
            yield delay(JWT_REFRESH_FREQUENCY_IN_MS);
            const loggedIn = yield select(getLoggedInUser);
            if (loggedIn) {
                yield call(refreshToken);
            }
        }
    } finally {
        if (yield cancelled()) {
            // the user logged out or had his token refused by server
        }
    }
}

function* userSessionWatcher(): any {
    while (yield take(USER_SESSION_OPEN_SUCCESS)) {
        const backgroundRefreshTokenLoop = yield fork(refreshTokenLoop);
        yield take([USER_LOGOUT, USER_AUTHENTICATION_REFRESH_ERROR]);
        yield cancel(backgroundRefreshTokenLoop);
    }
}

function* openSession(): any {
    const token = yield select(getToken);
    if (token) {
        yield put(USER_SESSION_OPEN({
            token
        }));
    }
}

function* openSessionError() {
    // yield put(NOTIFICATION_DISPLAY({
    //     id: v4(),
    //     type: NotificationType.ERROR,
    //     message_codes: ["user_session_open_failed"]
    // }));
}

function* loggedInUser() {
    yield all([
        takeEvery(actionsTriggeringUserReload.map(action => getType(action)), persistUser),
        takeEvery(getType(USER_LOAD), loadUser),
        takeEvery(getType(USER_LOGOUT), clearUser),
        takeEvery(getType(USER_AUTHENTICATION_REFRESH_ERROR), clearUser),
        takeEvery(getType(USER_EMAIL_VALIDATE_SUCCESS), refreshToken),
        takeEvery([getType(USER_LOGIN_SUCCESS), getType(USER_SOCIAL_LOGIN_SUCCESS)], openSession),
        takeEvery(
            (action: Action) =>
                (isActionOf(WEBSOCKET_CONNECTED, action) || isActionOf(WEBSOCKET_RECONNECTED, action)), openSession
        ),
        takeEvery(getType(USER_SESSION_OPEN_ERROR), openSessionError)
    ]);

    yield userSessionWatcher();
}

function* start() {
    yield put(USER_LOAD({}));
}

export function* usersSaga(): Generator {
    yield all([
        loggedInUser(),
        start(),
    ]);
}