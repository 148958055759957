import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {LoginButton} from "../components/login-button";
import {DISPLAY_LOGIN} from "../ducks/ui";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onClick: () => dispatch(DISPLAY_LOGIN({})),
});

export const ConnectedLoginButton = connect(mapStateToProps, mapDispatchToProps)(LoginButton);