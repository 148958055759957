import {Button, Icon} from "semantic-ui-react";
import {useBoolean} from "react-use";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {useTranslation} from "react-i18next";

export const ExportButton = (props: {
    displayText?: boolean;
    exportUrl: string | null | undefined
}): JSX.Element => {
    const [disabled, toggle] = useBoolean(false);
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {displayText, exportUrl} = props;
    return (
        <Button
            className="export-button"
            icon={true}
            color="teal"
            labelPosition={displayText ? "left" : undefined}
            disabled={disabled}
            href={exportUrl}
            target="_blank"
            onClick={() => {
                toggle(true);
                setTimeout(() => {
                    toggle(false)
                }, 5000);
            }}
        >
            <Icon name="file word outline"/>
            {displayText && t("record_export")}
        </Button>
    )
}