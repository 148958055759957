import {Notification as Notif, NotificationMessage, NotificationType} from "@axys-lab/smart-report-shared";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Message} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {TFunction} from "i18next";


type Props = {
    notification: Notif;
    onDismiss: () => void;
};

const icon = (type: NotificationType) => {
    switch (type) {
        case NotificationType.WARNING:
            return "warning circle";
        case NotificationType.ERROR:
            return "remove";
        case NotificationType.INFO:
        default:
            return "announcement";
    }
};

export const Notification = (props: Props): JSX.Element => {
    const {onDismiss, notification} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Message
            className="notification"
            icon={icon(notification.type)}
            warning={notification.type === NotificationType.WARNING}
            error={notification.type === NotificationType.ERROR}
            info={notification.type === NotificationType.INFO}
            list={notification.message_codes.map((message, index) => toElement(t, message, index))}
            onDismiss={onDismiss}
        />
    );
};

export const toElement = (t: TFunction, message: NotificationMessage, index: number): React.ReactNode => {
    if (typeof message === "string") {
        return <span key={index}>{t(message)}</span>;
    }
    const content = t(message.message_code, message.params)
    if (message.link) {
        return <Link key={index} to={message.link}>{content}</Link>
    }
    return <span key={index}>{content}</span>;
}