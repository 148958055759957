import {TIMEOUT_MESSAGE_CODE} from "../../utils/constants";
import {operation, OperationState} from "./common";
import {
    OneDriveSettings,
    RECORD_ONEDRIVE_SETTINGS_RETRIEVE,
    RECORD_ONEDRIVE_SETTINGS_RETRIEVE_ERROR,
    RECORD_ONEDRIVE_SETTINGS_RETRIEVE_SUCCESS
} from "@axys-lab/smart-report-shared";
import {combineReducers, Reducer} from "redux";
import {ActionType, createReducer} from "typesafe-actions";

export type OneDriveSettingsActions = ActionType<typeof RECORD_ONEDRIVE_SETTINGS_RETRIEVE |
    typeof RECORD_ONEDRIVE_SETTINGS_RETRIEVE_SUCCESS |
    typeof RECORD_ONEDRIVE_SETTINGS_RETRIEVE_ERROR>;

export type OneDriveSettingsState = {
    op: OperationState;
    data: OneDriveSettings | null;
}

export const initialDataState = null

const data: Reducer<OneDriveSettings | null, OneDriveSettingsActions> = createReducer<OneDriveSettings | null, OneDriveSettingsActions>(initialDataState)
    .handleAction(RECORD_ONEDRIVE_SETTINGS_RETRIEVE_SUCCESS, (state, action) => action.payload)

export const onedriveSettings = combineReducers({
    op: operation(
        [{
            remote: true,
            sent: RECORD_ONEDRIVE_SETTINGS_RETRIEVE,
            success: RECORD_ONEDRIVE_SETTINGS_RETRIEVE_SUCCESS,
            errors: RECORD_ONEDRIVE_SETTINGS_RETRIEVE_ERROR,
            timeout: {
                trigger: () => RECORD_ONEDRIVE_SETTINGS_RETRIEVE_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }]
    ),
    data
})