import {Button, Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

import "./style.css";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";

export const FileUploadCancelButton = (props: {
    onClick: () => void,
    visible: boolean
}): JSX.Element => {
    const {onClick} = props;

    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    return (
        <Button
            className="upload-cancel"
            animated={true}
            onClick={onClick}
            style={{
                display: props.visible ? "inline" : "none"
            }}
        >
            <Button.Content visible={true}>{t("upload_cancel")}</Button.Content>
            <Button.Content hidden={true}>
                <Icon name="cancel"/>
            </Button.Content>
        </Button>
    );
};