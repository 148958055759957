import {connect} from "react-redux";
import {UserCardMobile} from "../components/user-card-mobile";
import {State} from "../reducers";
import {USER_LOGOUT} from "@axys-lab/smart-report-shared";

export const ConnectedUserCardMobile = connect(
    (state: State) => ({
        username: state.user.token ? state.user.token.value.user.username : "",
        avatar: state.user.token && state.user.token.value.user.avatar ? state.user.token.value.user.avatar.url : ""
    }),
    (dispatch) => ({
        logout: () => dispatch(USER_LOGOUT({}))
    })
)(UserCardMobile);