import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { EmailUpdateForm } from "../views/user-profile/email-update-form";
import { State } from "../reducers";
import {EmailUpdate, USER_EMAIL_UPDATE} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.emailUpdate.status,
    remoteErrors: state.operations.emailUpdate.errors,
    email: state.user.token?.value.user.email || "",
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (request: EmailUpdate) => {
        dispatch(USER_EMAIL_UPDATE(request));
    }
});

export const ConnectedEmailUpdateForm =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EmailUpdateForm);