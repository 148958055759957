import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {State} from "../reducers";
import {RECORD_CREATE, RecordCreation} from "@axys-lab/smart-report-shared";
import {RecordCreateForm} from "../views/record-create/record-create-form";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.recordCreate.status,
    remoteErrors: state.operations.recordCreate.errors
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onCreate: (record: RecordCreation) => dispatch(RECORD_CREATE(record))
});

export const ConnectedRecordCreateForm = connect(mapStateToProps, mapDispatchToProps)(RecordCreateForm);