import {ActivationStrategy, ActivationStrategySchema} from "./activation-strategy";
import {array, object, ObjectSchema, string} from "yup";
import {EnumValues} from "enum-values";
import {Feature} from "./feature";

export type FeatureFlag = {
    feature: Feature
    activationStrategies: ActivationStrategy[]
}

export const FeatureFlagSchema: ObjectSchema<FeatureFlag> = object({
    feature: string()
        .required()
        .oneOf(EnumValues.getValues<Feature>(Feature)),
    activationStrategies: array(ActivationStrategySchema).required()
}).required()