import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Container} from "semantic-ui-react";
import {Accept, FileWithPath, useDropzone} from "react-dropzone";

import "./style.css";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";

export const FileSelector = (props: {
    accept?: Accept,
    disabled: boolean,
    onFileSelected: (file: FileWithPath) => void
}): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    const {accept, disabled, onFileSelected} = props;

    const onDropAccepted = useCallback(
        (acceptedFiles: FileWithPath[]) => {
            onFileSelected(acceptedFiles[0]);
        },
        [onFileSelected]
    );

    const {isDragActive, isDragReject, isDragAccept, getRootProps, getInputProps} = useDropzone({
        accept,
        multiple: false,
        onDropAccepted,
        disabled
    });

    const stateClass = useMemo(
        () => "dropzone" + (
            isDragAccept ?
                " accept" :
                isDragReject ?
                    " reject" :
                    isDragActive ?
                        " active" :
                        ""
        ),
        [
            isDragActive,
            isDragReject,
            isDragAccept
        ]
    );

    return (
        <Container
            textAlign="center"
            text={false}
        >
            <div  {...getRootProps({className: stateClass})}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>{t("upload_drop")}</p> :
                        <p>{t("upload_drag_and_drop")}</p>
                }
            </div>
        </Container>
    );
};
