import {connect} from "react-redux";
import {State} from "../reducers";
import {RecordViewHeader} from "../views/record-view/record-view-header";
import {RECORD_EDIT_NAME} from "../ducks/operations/records/record-view/edit";

export const ConnectedRecordViewHeader =
    connect(
        (state: State) => ({
            record: state.operations.recordView.editOp.data.editing ?
                state.operations.recordView.editOp.data.draft :
                state.operations.recordView.data.record,
            editing: state.operations.recordView.editOp.data.editing
        }),
        (dispatch) => ({
            onEdit: (name: string) => {
                dispatch(RECORD_EDIT_NAME({name}))
            },
        })
    )(RecordViewHeader);