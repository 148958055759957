import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {
    USER_PASSWORD_UPDATE,
    USER_PASSWORD_UPDATE_ERROR,
    USER_PASSWORD_UPDATE_SUCCESS
} from "@axys-lab/smart-report-shared";

export const passwordUpdate = operation(
    [{
        remote: true,
        sent: USER_PASSWORD_UPDATE,
        success: USER_PASSWORD_UPDATE_SUCCESS,
        errors: USER_PASSWORD_UPDATE_ERROR,
        timeout: {
            trigger: () => USER_PASSWORD_UPDATE_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
        }
    }],
    LOCATION_CHANGED,
);