import {object, ObjectSchema, string, StringSchema} from "yup";
import {Avatar, Avatars, NONE} from "../../utils/constants";
import {FirstnameSchema, LastnameSchema, UsernameSchema} from "../../utils/schemas";

type AvatarSchema = StringSchema<Avatar | NONE>

export type UserBasicDetails = {
    username: string;
    firstname: string;
    lastname: string;
    avatar: Avatar | NONE;
}

export const UserBasicDetailsSchema: ObjectSchema<UserBasicDetails> = object({
    username: UsernameSchema(),
    firstname: FirstnameSchema,
    lastname: LastnameSchema,
    avatar: (string() as AvatarSchema)
        .oneOf([...Avatars, NONE], "avatar_unknown")
        .required("avatar_required"),
}).required();
