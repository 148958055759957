import {RecordReference} from "./record-reference";
import {object, ObjectSchema, string} from "yup";
import {RecordIdSchema} from "../../utils";
import {RemoteFile} from "./remote-file";

export type RemoteFileDownload = RecordReference & RemoteFile;

export const RemoteFileDownloadSchema: ObjectSchema<RemoteFileDownload> = object({
    recordId: RecordIdSchema,
    url: string().url().required(),
}).required();
