import {connect} from "react-redux";
import {State} from "../reducers";
import {EmotionRecognitionChart} from "../views/record-view/record-view-panel/emotion-recognition-chart";

export const ConnectedEmotionRecognitionChart = connect(
    (state: State) => ({
        emotionRecognition: state.operations.recordView.data.record?.data.emotion.result,
        status: state.operations.recordView.data.record?.data.emotion.status,
        currentTime: state.operations.recordView.data.currentTime,
        trailLength: 10
    }),
    () => ({})
)(EmotionRecognitionChart);