import {connect} from "react-redux";
import {State} from "../reducers";
import {RIDAPanel} from "../views/record-view/record-view-panel/rida-panel";

export const ConnectedRIDAPanel = connect(
    (state: State) => ({
        speakers: state.operations.recordView.data.record?.data.transcription.result?.transcript.speakers || {},
        recordDate: new Date(state.operations.recordView.data.record?.recordTimestamp || 0),
        rida: state.operations.recordView.data.record?.data.rida.result,
        status: state.operations.recordView.data.record?.data.rida.status
    }),
    () => ({})
)(RIDAPanel);
