import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {operation, OperationState} from "../common";
import {
    RECORD_PROCESS_ERROR,
    RECORD_PROCESS_PROGRESS,
    RECORD_PROCESS_STARTED,
    RECORD_PROCESS_SUCCESS,
    RECORD_SEARCH,
    RECORD_SEARCH_ERROR,
    RECORD_SEARCH_SUCCESS,
    RecordType,
    SearchedRecord
} from "@axys-lab/smart-report-shared";
import {combineReducers, Reducer} from "redux";
import {ActionType, createReducer} from "typesafe-actions";
import {LOCATION_CHANGED} from "../../ui";

export type RecordsSearchActions = ActionType<typeof RECORD_SEARCH |
    typeof RECORD_SEARCH_SUCCESS |
    typeof RECORD_SEARCH_ERROR |
    typeof RECORD_PROCESS_SUCCESS |
    typeof RECORD_PROCESS_STARTED |
    typeof RECORD_PROCESS_PROGRESS |
    typeof RECORD_PROCESS_ERROR |
    typeof LOCATION_CHANGED>;

export type RecordsSearchDataState = {
    records: SearchedRecord[];
    query?: string;
    recordType?: RecordType;
    page: number;
    totalPages: number;
}

export type RecordsSearchState = {
    op: OperationState;
    data: RecordsSearchDataState;
}

export const initialDataState = {
    records: [],
    page: 0,
    totalPages: 0
}

const data: Reducer<RecordsSearchDataState, RecordsSearchActions> = createReducer<RecordsSearchDataState, RecordsSearchActions>(initialDataState)
    .handleAction(RECORD_SEARCH, (state, action) => ({
        page: action.payload.page,
        query: action.payload.query,
        recordType: action.payload.recordType,
        totalPages: 0,
        records: []
    }))
    .handleAction(RECORD_SEARCH_SUCCESS, (state, action) => ({
        ...state,
        page: action.payload.currentPage,
        totalPages: action.payload.pages,
        records: action.payload.founds
    }))
    .handleAction(RECORD_SEARCH_ERROR, (state) => ({
        ...state,
        page: 0,
        totalPages: 0,
        records: []
    }))
    .handleAction([RECORD_PROCESS_STARTED, RECORD_PROCESS_SUCCESS, RECORD_PROCESS_PROGRESS], (state, action) => {
        const index = state.records.findIndex(record => record.recordId === action.payload.recordId)
        if (index >= 0) {
            const records = [...state.records];
            records[index] = {
                recordId: action.payload.recordId,
                recordType: action.payload.type,
                transcription: action.payload.data.transcription.status,
                rida: action.payload.data.rida.status,
                emotion: action.payload.data.emotion.status,
                registration: action.payload.data.audioUpload,
                creationTimestamp: action.payload.creationTimestamp,
                recordTimestamp: action.payload.recordTimestamp,
                recordName: action.payload.name
            }
            return {
                ...state,
                records
            }
        }
        return state;
    })

export const recordsSearch = combineReducers({
    op: operation(
        [{
            remote: true,
            sent: RECORD_SEARCH,
            success: RECORD_SEARCH_SUCCESS,
            errors: RECORD_SEARCH_ERROR,
            timeout: {
                trigger: () => RECORD_SEARCH_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }]
    ),
    data
})