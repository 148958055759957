import {SemanticCOLORS} from "semantic-ui-react";

export const PRIMARY_COLOR: SemanticCOLORS = "teal";

export const JWT_REFRESH_FREQUENCY_IN_MS = 10 * 60 * 1000;

export const TRANSLATIONS_NAMESPACE = "translations";

export const DEFAULT_WEBSOCKET_RESPONSE_TIMEOUT = 10000;

export const TIMEOUT_MESSAGE_CODE = "timeout";

export const SERVER_URL = process.env.REACT_APP_BACKEND_HOST as string;

export const SOCKET_NAMESPACE = "/";