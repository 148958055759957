import "./index.css";
import {Button, Icon, Progress, Segment} from "semantic-ui-react";
import {default as RCSlider} from "rc-slider";
import {createRef, CSSProperties, useEffect, useState} from "react";
import ReactPlayer from "react-player"
import Draggable from "react-draggable";
import {useBoolean} from "react-use";

import "rc-slider/assets/index.css";

export const RecordPlayback = (props: {
    url: string | null,
    startAt: number,
    sendCurrentTime: (currentTime: number) => void,
    style?: CSSProperties
}): JSX.Element => {
    const {url, sendCurrentTime, startAt, style} = props;

    const [playingBeforeSeeking, setPlayingBeforeSeeking] = useBoolean(false);
    const [playing, setPlaying] = useBoolean(false);
    const [seeking, setSeeking] = useBoolean(false);
    const [loaded, setLoaded] = useBoolean(false);
    const [video, setVideo] = useBoolean(false);
    const [displayVideo, setDisplayVideo] = useBoolean(false);
    const [bufferedPercent, setBufferedPercent] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const playerRef = createRef<ReactPlayer>();

    useEffect(() => {
        const player = playerRef.current;
        if (player && startAt < player.getDuration()) {
            setCurrentTime(startAt);
            setLoaded(false);
            setSeeking(true);
            player.seekTo(startAt, "seconds");
        }
    }, [startAt]);

    useEffect(() => {
        if (url) {
            void fetch(url, {
                method: "HEAD",
                credentials: "include"
            }).then(response => {
                setVideo(response.headers.get("content-type")?.startsWith("video/") || false);
            })

        }
    }, [url]);

    const currentTimeFormatted = new Date(currentTime * 1000).toISOString()
    const durationFormatted = new Date(duration * 1000).toISOString()

    return (
        <div
            className="record-playback sticky"
            style={style}
        >
            {url ? (
                <>
                    <div className="record-playback-play">
                        <Button
                            icon={playing ? "pause" : "play"}
                            circular={true}
                            size="huge"
                            disabled={!loaded || seeking}
                            onClick={() => {
                                setPlaying(!playing);
                                setPlayingBeforeSeeking(!playing);
                            }}
                        />
                    </div>
                    <div className="record-playback-timeline">
                        <RCSlider
                            disabled={!loaded}
                            value={currentTime}
                            step={0.1}
                            min={0}
                            max={duration}
                            onBeforeChange={() => {
                                setPlaying(false);
                                setPlayingBeforeSeeking(playing);
                            }}
                            onChange={(time) => {
                                setCurrentTime(time);
                                setLoaded(false);
                                setSeeking(true);
                            }}
                            onAfterChange={(time) => {
                                const player = playerRef.current;
                                if (player) {
                                    player.seekTo(time, "seconds");
                                }
                            }}
                        />
                        <Progress
                            percent={bufferedPercent * 100}
                            color="grey"
                            active={bufferedPercent < 1}
                        />
                    </div>
                    <div className="record-playback-infos">
                        {duration < 3600 ? currentTimeFormatted.substr(14, 5) : currentTimeFormatted.substr(11, 8)}
                        {" / "}
                        {duration < 3600 ? durationFormatted.substr(14, 5) : durationFormatted.substr(11, 8)}
                    </div>
                    {video && (
                        <div className="video-controls">
                            <Button
                                size="small"
                                icon="video"
                                circular={true}
                                toggle={true}
                                active={displayVideo}
                                onClick={() => {
                                    setDisplayVideo(!displayVideo);
                                }}
                            >
                            </Button>
                        </div>
                    )}
                    <Draggable>
                        <Segment
                            className="video-draggable"
                            style={{
                                display: video && displayVideo ? "block" : "none",
                            }}
                        >
                            <div className="controls">
                                <Button
                                    icon={true}
                                    circular={true}
                                    onClick={() => {
                                        setDisplayVideo(false);
                                    }}
                                >
                                    <Icon name="close"/>
                                </Button>
                            </div>
                            <ReactPlayer
                                url={url}
                                pip={true}
                                controls={false}
                                loop={false}
                                playing={playing}
                                progressInterval={100}
                                width={640}
                                height={360}
                                onPlay={() => {
                                    setPlaying(true);
                                }}
                                onPause={() => {
                                    setPlaying(false);
                                }}
                                onReady={() => {
                                    setDisplayVideo(video);
                                    setLoaded(true);
                                    setPlaying(playingBeforeSeeking);
                                }}
                                onDuration={(duration) => {
                                    setDuration(duration);
                                }}
                                onProgress={(data) => {
                                    if (!seeking) {
                                        setCurrentTime(data.playedSeconds);
                                        setBufferedPercent(data.loaded);
                                    } else {
                                        setSeeking(false);
                                    }
                                    sendCurrentTime(data.playedSeconds)
                                }}
                                onEnded={() => {
                                    setPlaying(false);
                                    setPlayingBeforeSeeking(false);
                                    setCurrentTime(duration);
                                }}
                                ref={playerRef}
                            />
                        </Segment>
                    </Draggable>
                </>
            ) : <></>
            }
        </div>
    );
};
