import {object, ObjectSchema} from "yup";
import {SocialProviderSchema, TokenSchema} from "../../utils";
import {UserSocialProvider} from "./user-social-provider";

export type UserSocialLoginPrepare = UserSocialProvider & {
    socialLoginId: string
}

export const UserSocialLoginPrepareSchema: ObjectSchema<UserSocialLoginPrepare> = object({
    socialLoginId: TokenSchema("social_login_id"),
    provider: SocialProviderSchema()
}).required();