import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {Link} from "react-router-dom";
import {Icon, Menu} from "semantic-ui-react";

export const MenuItems = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <>
            <Menu.Item
                as={Link}
                to="/records"
            >
                <Icon name="volume up"/>
                {t("records")}
            </Menu.Item>
            <Menu.Item
                as={Link}
                to="/records/new"
            >
                <Icon name="plus circle"/>
                {t("record_create")}
            </Menu.Item>
        </>
    )

};