import {boolean, number, object, ObjectSchema} from "yup";
import {NumberOfSpeakersSchema, RecordNameSchema, RecordTypeSchema} from "../../utils";
import {RecordType} from "./record-type";


export type RecordCreation = {
    name: string;
    type: RecordType;
    allowUsageinTrainingDataset: boolean;
    recordTimestamp: number;
    numberOfSpeakers?: number;
}

export const RecordCreationSchema: ObjectSchema<RecordCreation> = object({
    name: RecordNameSchema,
    type: RecordTypeSchema,
    allowUsageinTrainingDataset: boolean()
        .required("allow_usage_in_training_dataset_required"),
    recordTimestamp: number()
        .min(1, "record_timestamp_min_1")
        .required("record_timestamp_required"),
    numberOfSpeakers: NumberOfSpeakersSchema
}).required();