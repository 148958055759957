import {TIMEOUT_MESSAGE_CODE} from "../../../../utils/constants";
import {LOCATION_CHANGED} from "../../../ui";
import {operation, OperationState} from "../../common";
import {
    Record,
    RECORD_GET,
    RECORD_GET_ERROR,
    RECORD_GET_SUCCESS,
    RECORD_PROCESS_ERROR,
    RECORD_PROCESS_PROGRESS,
    RECORD_PROCESS_STARTED,
    RECORD_PROCESS_SUCCESS, RECORD_REMOTE_FILE_DOWNLOAD_SUCCESS,
    RECORD_REMOVE,
    RECORD_REMOVE_ERROR,
    RECORD_REMOVE_SUCCESS, RECORD_REPROCESS_TRIGGER,
    RECORD_UPDATE_SUCCESS,
    RECORD_UPLOAD_CANCEL,
    RECORD_UPLOAD_CHUNK,
    RECORD_UPLOAD_CHUNK_REQUEST,
    RECORD_UPLOAD_ERROR,
    RECORD_UPLOAD_START,
    RECORD_UPLOAD_SUCCESS
} from "@axys-lab/smart-report-shared";
import {combineReducers, Reducer} from "redux";
import {ActionType, createAction, createReducer, getType} from "typesafe-actions";
import {registerRemoteMessageChannel} from "../../../../sagas/shared";
import {RecordUploadState, uploadOp} from "./upload";
import {editOp, RecordEditState} from "./edit";
import {downloadRemote} from "./download-remote";

export const CURRENT_TIME = createAction("report/CURRENT-TIME")<{ currentTime: number }>();
export const START_AT = createAction("report/START_AT")<{ currentTime: number }>();

export type RecordViewActions = ActionType<typeof RECORD_GET |
    typeof RECORD_GET_SUCCESS |
    typeof RECORD_GET_ERROR |
    typeof RECORD_PROCESS_STARTED |
    typeof RECORD_PROCESS_SUCCESS |
    typeof RECORD_PROCESS_PROGRESS |
    typeof RECORD_PROCESS_ERROR |
    typeof RECORD_UPDATE_SUCCESS |
    typeof CURRENT_TIME |
    typeof START_AT |
    typeof RECORD_UPLOAD_SUCCESS |
    typeof RECORD_REMOTE_FILE_DOWNLOAD_SUCCESS>;

export type RecordViewDataState = {
    record: Record | null;
    currentTime: number;
    startAt: number;
}

export type RecordViewState = {
    getOp: OperationState;
    removeOp: OperationState;
    uploadOp: RecordUploadState;
    editOp: RecordEditState;
    downloadRemote: OperationState;
    data: RecordViewDataState;
}

export const initialDataState = {
    record: null,
    currentTime: 0,
    startAt: 0,
}

const data: Reducer<RecordViewDataState, RecordViewActions> = createReducer<RecordViewDataState, RecordViewActions>(initialDataState)
    .handleAction([RECORD_GET, RECORD_GET_ERROR], () => ({
        ...initialDataState
    }))
    .handleAction([RECORD_GET_SUCCESS, RECORD_UPLOAD_SUCCESS, RECORD_REMOTE_FILE_DOWNLOAD_SUCCESS, RECORD_UPDATE_SUCCESS], (state, action) => ({
        ...state,
        record: action.payload
    }))
    .handleAction([RECORD_PROCESS_STARTED, RECORD_PROCESS_PROGRESS, RECORD_PROCESS_SUCCESS], (state, action) => {
        if (action.payload.recordId === state?.record?.recordId) {
            return ({
                ...state,
                record: action.payload
            })
        }
        return state;
    })
    .handleAction(CURRENT_TIME, (state, action) => ({
        ...state,
        currentTime: action.payload.currentTime
    }))
    .handleAction(START_AT, (state, action) => ({
        ...state,
        startAt: action.payload.currentTime
    }));

export const recordView = combineReducers({
    getOp: operation(
        [{
            remote: true,
            sent: RECORD_GET,
            success: RECORD_GET_SUCCESS,
            errors: RECORD_GET_ERROR,
            timeout: {
                trigger: () => RECORD_GET_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }],
        LOCATION_CHANGED,
    ),
    removeOp: operation(
        [{
            remote: true,
            sent: RECORD_REMOVE,
            success: RECORD_REMOVE_SUCCESS,
            errors: RECORD_REMOVE_ERROR,
            timeout: {
                trigger: () => RECORD_REMOVE_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }],
        LOCATION_CHANGED,
    ),
    uploadOp,
    editOp,
    downloadRemote,
    data,
})

registerRemoteMessageChannel({
    received: [
        getType(RECORD_PROCESS_STARTED),
        getType(RECORD_PROCESS_PROGRESS),
        getType(RECORD_PROCESS_SUCCESS),
        getType(RECORD_PROCESS_ERROR),
        getType(RECORD_UPLOAD_SUCCESS),
        getType(RECORD_UPLOAD_CHUNK_REQUEST),
        getType(RECORD_UPLOAD_ERROR)
    ],
    sent: [
        getType(RECORD_UPLOAD_CANCEL),
        getType(RECORD_UPLOAD_START),
        getType(RECORD_UPLOAD_CHUNK),
        getType(RECORD_REPROCESS_TRIGGER)
    ],
});