
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "semantic-ui-react";
import { ConnectedEmailValidationResendForm } from "../../containers/connected-email-validation-resend-form";
import { TRANSLATIONS_NAMESPACE } from "../../utils/constants";

type Props = {
    displayResendLink: boolean;
};

export const EmailValidationResendAccordion = (props: Props): JSX.Element => {

    const [ displayResendForm, setDisplayResendForm ] = useState(false);
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {displayResendLink} = props;
    return (
        displayResendLink ? (
            <Accordion>
                <Accordion.Title active={displayResendForm} onClick={() => setDisplayResendForm(!displayResendForm)}>
                    {t("email_validation_not_received")}
                </Accordion.Title>
                <Accordion.Content active={displayResendForm}>
                    <ConnectedEmailValidationResendForm/>
                </Accordion.Content>
            </Accordion>
        ) : <></>
    );
};
