import {connect} from "react-redux";
import {Action} from "redux";
import {Notifications} from "../components/notifications";
import {State} from "../reducers";
import {Notification, NOTIFICATION_DISMISS} from "@axys-lab/smart-report-shared";

export const ConnectedNotifications = connect(
    (state: State) => ({
        notifications: state.ui.notifications,
    }),
    (dispatch: (action: Action) => void) => ({
        onDismiss: (notification: Notification) => dispatch(NOTIFICATION_DISMISS({id: notification.id}))
    })
)(Notifications);