
import { useTranslation } from "react-i18next";
import { Container, Header, Segment } from "semantic-ui-react";
import { TRANSLATIONS_NAMESPACE } from "../../utils/constants";
import {Layout} from "../../components/layout";

export const TermsOfUse = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Layout>
            <div className="terms">
                <Segment inverted={true} vertical={true} padded="very" className="terms-header fancy-header">
                    <Container
                        textAlign="center"
                        text={true}
                    >
                        <Header
                            as="h2"
                            content={t("terms_of_use")}
                            inverted={true}
                            size="huge"
                        />
                    </Container>
                </Segment>
            </div>
        </Layout>
    );
};
