import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {DISPLAY_REGISTER, HIDE_REGISTER, LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {USER_REGISTER, USER_REGISTER_ERROR, USER_REGISTER_SUCCESS} from "@axys-lab/smart-report-shared";

export const registration = operation(
    [{
        remote: true,
        sent: USER_REGISTER,
        success: USER_REGISTER_SUCCESS,
        errors: USER_REGISTER_ERROR,
        timeout: {
            trigger: () => USER_REGISTER_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
        }
    }],
    [LOCATION_CHANGED, HIDE_REGISTER, DISPLAY_REGISTER],
);
