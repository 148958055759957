import { object, ObjectSchema } from "yup";
import {EmailSchema} from "../../utils/schemas";

export type EmailValidationTokenResend = {
    email: string;
}

export const EmailValidationTokenResendSchema: ObjectSchema<EmailValidationTokenResend> = object({
    email: EmailSchema()
}).required();


