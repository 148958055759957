import {lazy, number, object, ObjectSchema, string} from "yup";
import {RecordReference} from "./record-reference";
import {RecordIdSchema} from "../../utils";
import {FileUpload} from "./file-upload";
import {Unknown} from "./file-size";


export type RecordUpload = RecordReference & FileUpload

export const RecordUploadSchema: ObjectSchema<RecordUpload> = object({
    recordId: RecordIdSchema,
    name: string()
        .required()
        .defined()
        .min(3),
    size: lazy(value => typeof value === "number" ?
        number()
            .min(1, `size_min_1`)
            .defined() :
        string()
            .oneOf([Unknown], "size_unknown")
            .defined()
    )
}).required();
