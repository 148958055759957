import {Button, Confirm, Icon} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {useTranslation} from "react-i18next";
import {useBoolean} from "react-use";

export const EditButton = (props: {
    displayText?: boolean;
    editing: boolean
    onEdit: () => void,
    onSave: () => void,
    onCancel: () => void
}): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {displayText, editing, onEdit, onSave, onCancel} = props;
    const [canceling, toggleCanceling] = useBoolean(false);
    const [saving, toggleSaving] = useBoolean(false);
    console.log(displayText);
    return (
        <Button.Group>
            {editing ?
                <>
                    <Button
                        disabled={canceling}
                        icon={true}
                        color="red"
                        labelPosition={displayText ? "left" : undefined}
                        onClick={() => toggleCanceling(true)}
                    >
                        <Icon name="cancel"/>
                        {displayText && t("record_edit_cancel")}
                    </Button>
                    <Confirm
                        open={canceling}
                        header={t("record_edit_cancel")}
                        content={t("record_edit_cancel_confirm")}
                        cancelButton={t("cancel")}
                        confirmButton={t("confirm")}
                        onCancel={() => toggleCanceling(false)}
                        onConfirm={() => {
                            toggleCanceling(false);
                            onCancel();
                        }}
                    />
                    <Button.Or text="-"/>
                    <Button
                        icon={true}
                        color="teal"
                        labelPosition={displayText ? "right" : undefined}
                        onClick={() => toggleSaving(true)}
                    >
                        <Icon name="save outline"/>
                        {displayText && t("record_edit_save")}
                    </Button>
                    <Confirm
                        open={saving}
                        header={t("record_edit_save")}
                        content={t("record_edit_save_confirm")}
                        cancelButton={t("cancel")}
                        confirmButton={t("confirm")}
                        onCancel={() => toggleSaving(false)}
                        onConfirm={() => {
                            toggleSaving(false);
                            onSave();
                        }}
                    />
                </>
                :
                <Button
                    icon={true}
                    color="teal"
                    labelPosition={displayText ? "left" : undefined}
                    onClick={onEdit}
                >
                    <Icon name="edit outline"/>
                    {displayText && t("record_edit")}
                </Button>
            }
        </Button.Group>
    );
}