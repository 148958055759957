import {Button, Confirm, Icon} from "semantic-ui-react";
import {useBoolean, usePrevious} from "react-use";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {useTranslation} from "react-i18next";
import {RemoteCommandStatus} from "../../../../utils/remote-command-status";

import {useEffect} from "react";

export const DeleteButton = (props: {
    displayText?: boolean;
    recordId: string;
    status: RemoteCommandStatus;
    onSubmit: (recordId: string) => void;
}): JSX.Element => {
    const [on, toggle] = useBoolean(false);
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {displayText, recordId, status, onSubmit} = props;
    const previousStatus = usePrevious(status)

    useEffect(() => {
        if (status === RemoteCommandStatus.SUCCESS && previousStatus !== status) {
            toggle(false);
        }
    }, [status])

    return (
        <>
            <Button
                icon={true}
                negative={true}
                labelPosition={displayText ? "left" : undefined}
                onClick={toggle}
            >
                <Icon name="trash alternate outline"/>
                {displayText &&  t("record_remove")}
            </Button>
            <Confirm
                open={on}
                content={t("record_remove_confirm")}
                cancelButton={t("cancel")}
                confirmButton={t("confirm")}
                onCancel={() => toggle(false)}
                onConfirm={() => onSubmit(recordId)}
            />
        </>
    )
}