import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {LoginForm} from "../components/login-form";
import {DISPLAY_REGISTER, HIDE_LOGIN} from "../ducks/ui";
import {State} from "../reducers";
import {
    SocialProvider,
    USER_LOGIN,
    USER_SOCIAL_LOGIN,
    USER_SOCIAL_LOGIN_CANCEL, USER_SOCIAL_LOGIN_PREPARE,
    UserLogin,
    UserSocialLoginAuthorized,
    UserSocialProvider
} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    display: state.ui.displayLogin,
    url: `${(process.env.REACT_APP_BACKEND_HOST as string)}/api/auth`,
    alreadyLoggedIn: !!state.user.token,
    remoteStatus: state.operations.login.status,
    remoteErrors: state.operations.login.errors,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onOpen: (provider: SocialProvider, socialLoginId: string) => dispatch(USER_SOCIAL_LOGIN_PREPARE({provider, socialLoginId})),
    onClose: () => dispatch(HIDE_LOGIN({})),
    displayRegister: () => dispatch(DISPLAY_REGISTER({})),
    onSocialLoginAuthorized: (socialLoginAuthorized: UserSocialLoginAuthorized) =>
        dispatch(USER_SOCIAL_LOGIN(socialLoginAuthorized)),
    onSocialLoginCancel: (userSocialProvider: UserSocialProvider) =>
        dispatch(USER_SOCIAL_LOGIN_CANCEL(userSocialProvider)),
    onLocalLogin: (userLogin: UserLogin) => dispatch(USER_LOGIN(userLogin)),
});

export const ConnectedLoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm);