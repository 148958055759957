import {registerRemoteMessageChannel} from "../sagas/shared";
import {ActionType, createAction, createReducer, getType, PayloadAction} from "typesafe-actions";
import {UserAction} from "./user";
import {
    Notification,
    NOTIFICATION_DISMISS,
    NOTIFICATION_DISPLAY,
    NotificationAction,
    USER_LOGIN_SUCCESS
} from "@axys-lab/smart-report-shared";
import {
    CALL_HISTORY_METHOD,
    LOCATION_CHANGE as LOCATION_CHANGE_TYPE,
    LocationActionPayload,
    LocationChangePayload
} from "connected-react-router";
import {Reducer} from "redux";

export const DISPLAY_LOGIN = createAction("ui/DISPLAY_LOGIN")<Record<string, never>>();
export const HIDE_LOGIN = createAction("ui/HIDE_LOGIN")<Record<string, never>>();
export const DISPLAY_REGISTER = createAction("ui/DISPLAY_REGISTER")<Record<string, never>>();
export const HIDE_REGISTER = createAction("ui/HIDE_REGISTER")<Record<string, never>>();
export const LOCATION_CHANGED = createAction(LOCATION_CHANGE_TYPE)<LocationChangePayload>();
export const CHANGE_LOCATION = createAction(CALL_HISTORY_METHOD)<LocationActionPayload>();

export type UIAction = NotificationAction |
    ActionType<typeof DISPLAY_LOGIN |
        typeof HIDE_LOGIN |
        typeof DISPLAY_REGISTER |
        typeof HIDE_REGISTER |
        typeof LOCATION_CHANGED>

export type UIState = Readonly<{
    displayLogin: boolean;
    displayRegister: boolean;
    notifications: Array<(Notification & { timestamp: number })>;
}>;

const initialState: UIState = {
    displayLogin: false,
    displayRegister: false,
    notifications: []
};

registerRemoteMessageChannel({
    received: [
        getType(NOTIFICATION_DISPLAY),
    ]
});

export const reducer: Reducer<UIState, UIAction, UIState> = createReducer<UIState, UIAction | UserAction>(initialState)
    .handleAction(LOCATION_CHANGED, (state) => ({
        ...state,
        displayRegister: false,
        displayLogin: false
    }))
    .handleAction(DISPLAY_LOGIN, (state) => ({
        ...state,
        displayLogin: true
    }))
    .handleAction(HIDE_LOGIN, (state) => ({
        ...state,
        displayLogin: false
    }))
    .handleAction(DISPLAY_REGISTER, (state) => ({
        ...state,
        displayRegister: true,
        displayLogin: false
    }))
    .handleAction(HIDE_REGISTER, (state) => ({
        ...state,
        displayRegister: false,
    }))
    .handleAction(USER_LOGIN_SUCCESS, (state) => ({
        ...state,
        displayRegister: false,
        displayLogin: false,
    }))
    .handleAction(NOTIFICATION_DISPLAY, (state, action: PayloadAction<string, Notification>) => ({
        ...state,
        notifications: [
            {
                ...action.payload,
                timestamp: Date.now()
            },
            ...state.notifications
        ]
    }))
    .handleAction(NOTIFICATION_DISMISS, (state, action) => ({
        ...state,
        notifications: state.notifications.filter(notif => notif.id !== action.payload.id)
    }));
