export enum NotificationType {
    INFO,
    WARNING,
    ERROR
}

export type NotificationMessage = string | {
    message_code: string
    params?: Record<string, unknown>
    link?: string
}

export type Notification = {
    id: string;
    type: NotificationType;
    message_codes: NotificationMessage[];
}