import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { PasswordUpdateForm } from "../views/user-profile/password-update-form";
import { State } from "../reducers";
import {PasswordUpdate, USER_PASSWORD_UPDATE} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.passwordUpdate.status,
    remoteErrors: state.operations.passwordUpdate.errors,
    creation: state.user.token ? !state.user.token.value.user.providers.local : true,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (request: PasswordUpdate) => {
        dispatch(USER_PASSWORD_UPDATE(request));
    }
});

export const ConnectedPasswordUpdateForm =
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(PasswordUpdateForm);