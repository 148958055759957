import {Button, Container, Icon, Step} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import {OneDriveSettings, RemoteFile} from "@axys-lab/smart-report-shared";
import {MsalProvider, useIsAuthenticated} from "@azure/msal-react";
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {IPublicClientApplication} from "@azure/msal-browser/dist/app/IPublicClientApplication";
import {useBoolean} from "react-use";

export type OneDrivePickerProps = OneDriveSettings & {
    disabled?: boolean
    cancel: () => void;
    pickProvider: () => void;
    downloadFile: (onedriveFile: RemoteFile) => void;
}

export const OneDrivePicker = (props: OneDrivePickerProps): JSX.Element => {
    const {clientId} = props;
    const msalInstance = new PublicClientApplication({
        auth: {
            clientId,
            authority: "https://login.microsoftonline.com/xconsultants.onmicrosoft.com",
            redirectUri: "/",
            postLogoutRedirectUri: "/"
        }
    });
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });


    return (
        <MsalProvider instance={msalInstance}>
            <UnderlyingPicker {...props} msal={msalInstance}/>
        </MsalProvider>
    )
}

export const UnderlyingPicker = (props: OneDrivePickerProps & { msal: IPublicClientApplication }): JSX.Element => {
    const {disabled, msal, redirectUri, clientId, downloadFile, endpointHint, cancel, pickProvider} = props;
    const [on, toggle] = useBoolean(false);
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const isAuthenticated = useIsAuthenticated();

    const login = () => {
        const request = {
            scopes: ["User.Read", "Files.Read.All", "profile", "openid"],
        };
        if (!isAuthenticated) {
            void msal.loginPopup(request)
        } else {
            const account = msal.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            void msal.acquireTokenSilent({
                ...request,
                account: account
            });
        }
    }

    const openPicker = () => {
        window.OneDrive.open({
            clientId,
            action: "download",
            multiSelect: false,
            success: files => {
                if (files && files.value.length) {
                    const file = files.value[0];
                    downloadFile({
                        url: file["@content.downloadUrl"]
                    })
                }
            },
            error: () => cancel(),
            cancel: () => cancel(),
            advanced: {
                endpointHint,
                redirectUri,
                filter: "folder,.wav,.mp3,.m4a,.mp4,.mov"
            }
        })
    }

    return (
        <>
            {!on ? (
                <Button
                    disabled={disabled}
                    color="blue"
                    onClick={() => {
                        toggle(true);
                        pickProvider();
                    }}
                >{t("download_from_onedrive")}</Button>
            ) : (
                <>
                    <Container textAlign="center">
                        <Step.Group vertical>
                            <Step completed={isAuthenticated} active={!isAuthenticated}>
                                <Icon name="id card outline"/>
                                <Step.Content>
                                    <Step.Title>{t("download_from_onedrive_login")}</Step.Title>
                                    <Step.Description>
                                        <Button
                                            disabled={disabled || isAuthenticated}
                                            color="blue"
                                            onClick={() => {
                                                login();
                                            }}
                                        >{t("download_from_onedrive_login_action")}</Button>
                                    </Step.Description>
                                </Step.Content>
                            </Step>
                            <Step active={isAuthenticated} disabled={!isAuthenticated}>
                                <Icon name="folder open outline"/>
                                <Step.Content>
                                    <Step.Title>{t("download_from_onedrive_pick")}</Step.Title>
                                    <Step.Description>
                                        <Button
                                            disabled={disabled || !isAuthenticated}
                                            color="blue"
                                            onClick={() => {
                                                openPicker();
                                            }}
                                        >{t("download_from_onedrive_pick_action")}</Button>
                                    </Step.Description>
                                </Step.Content>
                            </Step>
                        </Step.Group>
                    </Container>
                    <Container style={{marginTop: "15px"}} textAlign="center">
                        <Button
                            color="red"
                            onClick={() => {
                                toggle(false);
                            }}
                        >{t("cancel")}</Button>
                    </Container>
                </>
            )}
        </>
    );
}