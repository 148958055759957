import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {State} from "../reducers";
import {useEffect} from "react";
import {
    OneDriveSettings,
    RECORD_ONEDRIVE_SETTINGS_RETRIEVE,
    RECORD_REMOTE_FILE_DOWNLOAD,
    RemoteFile
} from "@axys-lab/smart-report-shared";
import {RemoteCommandStatus} from "../utils/remote-command-status";
import {CloudFilePicker} from "../components/cloud-file-picker";

export const ConnectedCloudFilePicker = connect(
    (state: State) => {
        return ({
            onedriveSettings: state.operations.onedriveSettings.data,
            errors: [...state.operations.onedriveSettings.op.errors, ...state.operations.recordView.downloadRemote.errors],
            status: state.operations.recordView.downloadRemote.status,
            sessionOpened: state.operations.session.status === RemoteCommandStatus.SUCCESS,
            recordId: state.operations.recordView.data.record?.recordId,
            disabled: state.operations.recordView.uploadOp.started
        });
    },
    (dispatch: Dispatch<Action>) => ({
        getOneDriveSettings: () => dispatch(RECORD_ONEDRIVE_SETTINGS_RETRIEVE({})),
        downloadFileForRecord: (url: string, recordId: string) => {
            dispatch(RECORD_REMOTE_FILE_DOWNLOAD({
                recordId,
                url
            }))
        }
    }),
    (stateProps, dispatchProps) => ({
        ...stateProps,
        ...dispatchProps,
        downloadFile: (file: RemoteFile) => {
            if (stateProps.recordId) {
                dispatchProps.downloadFileForRecord(file.url, stateProps.recordId)
            }
        }
    })
)((props: {
    className?: string,
    getOneDriveSettings: () => void,
    onedriveSettings: OneDriveSettings | null,
    disabled: boolean,
    errors: string[],
    status: RemoteCommandStatus,
    downloadFile: (file: RemoteFile) => void,
    sessionOpened: boolean
}) => {
    const {className, disabled, errors, status, downloadFile, getOneDriveSettings, onedriveSettings, sessionOpened} = props;
    useEffect(() => {
        if (sessionOpened) {
            getOneDriveSettings()
        }
    }, [sessionOpened])
    return (
        <CloudFilePicker
            disabled={disabled}
            status={status}
            errors={errors}
            onedriveSettings={onedriveSettings}
            className={className}
            downloadFile={downloadFile}
        />
    );
});