import * as React from "react";
import {useState} from "react";
import {Button, Dimmer, Dropdown, Icon, Loader, Message, Popup, Table} from "semantic-ui-react";
import {Transcript, TranscriptSegment, TranscriptSpeakers, TranscriptWord} from "@axys-lab/srap-common";
import "./style.css";
import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {RecordProcessStepStatus, stringToColor} from "@axys-lab/smart-report-shared";
import {useBoolean, useHover} from "react-use";

export const TranscriptPanel = (props: {
    status: RecordProcessStepStatus | null | undefined
    transcript: Transcript | null | undefined
    currentTime: number
    startAt: (time: number) => void
    editing: boolean
    onSegmentMerge: (index: number) => void
    onSegmentSplit: (index: number, wordIndex: number) => void
    onSegmentEdit: (index: number, words: string) => void
    onSingleSpeakerChange: (segmentIndex: number, speakerIdOrName: string) => void
    onGlobalSpeakerChange: (previousSpeakerId: string, speakerIdOrName: string) => void
}): JSX.Element => {
    const {
        status,
        editing,
        transcript,
        currentTime,
        startAt,
        onSegmentMerge,
        onSegmentSplit,
        onSegmentEdit,
        onGlobalSpeakerChange,
        onSingleSpeakerChange
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <div className="transcript">
            {status === RecordProcessStepStatus.STARTED ? (
                <Dimmer page={false} active={true} inverted={true}>
                    <Loader inverted={true}>{t("record_transcript_started")}</Loader>
                </Dimmer>
            ) : status === RecordProcessStepStatus.PENDING ? (
                <Dimmer page={false} active={true} inverted={true}>
                    <Loader inverted={true}>{t("record_transcript_pending")}</Loader>
                </Dimmer>
            ) : status === RecordProcessStepStatus.ERROR ? (
                <Message error={true}>
                    {t("record_transcript_error")}
                </Message>
            ) : status === RecordProcessStepStatus.NEVER || !transcript || !transcript.segments ? (
                <Message info={true}>
                    {t("record_no_transcript")}
                </Message>
            ) : (
                <Table basic="very">
                    <Table.Body>
                        {transcript.segments.filter(segment => segment.words.length).map((segment, segmentIndex, array) => (
                            <SegmentTableRow
                                key={`segment_${segmentIndex}`}
                                segmentIndex={segmentIndex}
                                speakers={transcript.speakers}
                                segment={segment}
                                previousSegment={array[segmentIndex - 1]}
                                currentTime={currentTime}
                                startAt={startAt}
                                globalEditing={editing}
                                onMerge={onSegmentMerge}
                                onSplit={onSegmentSplit}
                                onEdit={onSegmentEdit}
                                onGlobalSpeakerChange={onGlobalSpeakerChange}
                                onSingleSpeakerChange={onSingleSpeakerChange}
                            />
                        ))}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
};

export const SegmentTableRow = (props: {
    segmentIndex: number
    speakers: TranscriptSpeakers,
    segment: TranscriptSegment,
    previousSegment?: TranscriptSegment,
    currentTime: number,
    startAt: (time: number) => void
    globalEditing: boolean,
    onMerge: (segmentIndex: number) => void
    onSplit: (segmentIndex: number, wordIndex: number) => void
    onEdit: (segmentIndex: number, words: string) => void
    onSingleSpeakerChange: (segmentIndex: number, speakerIdOrName: string) => void
    onGlobalSpeakerChange: (previousSpeakerId: string, speakerIdOrName: string) => void
}): JSX.Element => {
    const {
        speakers,
        segment,
        segmentIndex,
        previousSegment,
        currentTime,
        startAt,
        globalEditing,
        onMerge,
        onSplit,
        onEdit,
        onSingleSpeakerChange,
        onGlobalSpeakerChange
    } = props;
    if (!segment.words.length) {
        return (<></>);
    }
    return (
        <>
            <Table.Row
                className={`transcript-segment ${previousSegment && previousSegment.speaker !== segment.speaker ? "transcript-segment-speaker-change" : ""} ${globalEditing ? "editing" : ""}`}>
                <Table.Cell width={globalEditing ? 2 : 1}>
                    {globalEditing ? (
                        <EditSpeakerDropDown
                            segment={segment}
                            speakers={speakers}
                            onGlobalChange={onGlobalSpeakerChange}
                            onSingleChange={onSingleSpeakerChange}
                        />
                    ) : previousSegment?.speaker !== segment.speaker ? (
                            <SpeakerName speakerKey={segment.speaker} speakers={speakers}/>
                        ) :
                        <></>
                    }
                </Table.Cell>
                <Table.Cell>
                    <TextSegment
                        segment={segment}
                        segmentIndex={segmentIndex}
                        currentTime={currentTime}
                        globalEditing={globalEditing}
                        onMerge={onMerge}
                        onSplit={onSplit}
                        onEdit={onEdit}
                    />
                </Table.Cell>
                <Table.Cell>
                    {new Date(segment.startTime * 1000).toISOString().substr(11, 8)}
                    {" "}
                    <Icon
                        circular={true}
                        name="wait"
                        onClick={() => startAt(segment.startTime)}/>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

export const SpeakerName = (props: {
    speakers: TranscriptSpeakers
    speakerKey: string,
}): JSX.Element => {
    const {speakerKey, speakers} = props;
    return (
        <strong
            style={{color: stringToColor(speakerKey)}}>{speakers[speakerKey].name}</strong>
    )
};

export const EditSpeakerDropDown = (props: {
    speakers: TranscriptSpeakers
    segment: TranscriptSegment,
    onSingleChange: (segmentIndex: number, speakerIdOrName: string) => void
    onGlobalChange: (previousSpeakerId: string, speakerIdOrName: string) => void
}): JSX.Element => {
    const {segment, speakers, onGlobalChange, onSingleChange} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const [state, setState] = useState<{ confirming: boolean, speaker: string | null }>({
        confirming: false,
        speaker: null
    })
    return (
        <>
            <Popup
                wide
                open={state.confirming}
                header={
                    <div className="speaker-edit-cancel">
                        <Button
                            icon={true}
                            basic={true}
                            circular={true}
                            onClick={() => {
                                setState({
                                    confirming: false,
                                    speaker: null
                                })
                            }}
                        >
                            <Icon name="cancel"/>
                        </Button>
                    </div>
                }
                trigger={
                    <Dropdown
                        options={Object.keys(speakers).map(speaker => ({
                            text: (<SpeakerName speakerKey={speaker} speakers={speakers}/>),
                            value: speaker,
                            key: speaker

                        }))}
                        search
                        selection
                        fluid
                        allowAdditions
                        disabled={state.confirming}
                        additionLabel={<i style={{color: "red"}}>{t("record_edit_speaker") + " : "}</i>}
                        value={segment.speaker}
                        onChange={(e, data) => {
                            setState({
                                confirming: true,
                                speaker: data.value as string
                            })
                        }}
                    />
                }
                content={
                    <>
                        <Button
                            icon={true}
                            labelPosition="left"
                            onClick={() => {
                                if (state.speaker) {
                                    onSingleChange(segment.index, state.speaker);
                                }
                                setState({
                                    confirming: false,
                                    speaker: null
                                })
                            }}
                        >
                            <Icon name="user outline"/>
                            {t("record_edit_speaker_single")}
                        </Button>
                        <br/>
                        <Button
                            icon={true}
                            labelPosition="left"
                            onClick={() => {
                                if (state.speaker) {
                                    onGlobalChange(segment.speaker, state.speaker);
                                }
                                setState({
                                    confirming: false,
                                    speaker: null
                                })
                            }}
                        >
                            <Icon name="list"/>
                            {t("record_edit_speaker_all")}
                        </Button>
                    </>
                }
            />

        </>
    );
};

export const TextSegment = (props: {
    segmentIndex: number,
    segment: TranscriptSegment,
    currentTime: number,
    globalEditing: boolean,
    onMerge: (segmentIndex: number) => void
    onSplit: (segmentIndex: number, wordIndex: number) => void,
    onEdit: (segmentIndex: number, words: string) => void
}): JSX.Element => {
    const {segment, segmentIndex, currentTime, onMerge, onSplit, onEdit, globalEditing} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const [editing, toggleEditing] = useBoolean(false);
    const [splitting, toggleSplitting] = useBoolean(false);
    const textElement = (hovered: boolean) => (
        <div>
            {editing ? (
                <>
                    <EditableText
                        segment={segment}
                        onEdit={(words) => {
                            onEdit(segmentIndex, words);
                            toggleEditing(false);
                        }}
                    />
                </>
            ) : splitting ? (
                <>
                    <SplittingText
                        segment={segment}
                        onSplit={(wordIndex) => {
                            onSplit(segmentIndex, wordIndex);
                            toggleSplitting(false);
                        }}
                    />
                </>
            ) : (
                <>
                    <NonEditableText segment={segment} currentTime={currentTime}/>
                    {globalEditing && hovered ? (
                        <div className="segment-edit-buttons">
                            <Button
                                icon
                                labelPosition="left"
                                size="mini"
                                onClick={() => toggleEditing(true)}
                            >
                                <Icon name="edit outline"/>
                                {t("record_edit_segment")}
                            </Button>
                            <Button
                                icon
                                labelPosition="left"
                                size="mini"
                                onClick={() => onMerge(segmentIndex)}
                            >
                                <Icon name="object group outline"/>
                                {t("record_edit_merge_segment")}
                            </Button>
                            <Button
                                icon
                                labelPosition="left"
                                size="mini"
                                onClick={() => toggleSplitting(true)}
                            >
                                <Icon name="object ungroup outline"/>
                                {t("record_edit_split_segment")}
                            </Button>
                        </div>
                    ) : (<></>)}
                </>
            )}
        </div>
    );
    return useHover(textElement)[0];
};

export const NonEditableText = (props: {
    segment: TranscriptSegment,
    currentTime: number
}): JSX.Element => {
    const {segment, currentTime} = props;
    if (segment.words[0].startTime > currentTime ||
        segment.words[segment.words.length - 1].endTime < currentTime) {
        const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
        return (
            <>{segment.words.map((word, index) => {
                const confidence = 0.4 + 0.6 * word.confidence;
                if (confidence < 0.5) {
                    return (
                        <Popup
                            key={`${segment.index}.${index}`}
                            content={t("record_word_confidence", {confidence: `${Math.round(confidence * 100)}%`})}
                            trigger={
                                <span>
                                    <span className="word-low-confidence">
                                        {word.value}
                                    </span>
                                    {" "}
                                </span>
                            }
                        />
                    );
                }
                return (
                    <React.Fragment key={`${segment.index}.${index}`}>{word.value}{" "}</React.Fragment>
                )
            })}</>
        );
    }

    return (
        <>
            {segment.words.map((word, index) => (
                <React.Fragment key={`word_${index}`}>
                                            <span
                                                className={currentTime >= word.startTime && currentTime <= word.endTime ? "highlight" : ""}
                                            >
                                                {word.value}
                                            </span>
                    {/* tslint:disable-next-line:jsx-self-close*/}
                    <span> </span>
                </React.Fragment>
            ))}
        </>
    );
};

export const SplittingText = (props: {
    segment: TranscriptSegment,
    onSplit: (wordIndex: number) => void
}): JSX.Element => {
    const {segment, onSplit} = props;
    return (
        <>
            {segment.words.map((word, index) => (
                <SplittingWord word={word} key={`word_${index}`} onClick={() => onSplit(index)}/>
            ))}
        </>
    );
};

export const SplittingWord = (props: {
                                  word: TranscriptWord,
                                  onClick: () => void
                              }
): JSX.Element => {
    const {word, onClick} = props;
    const element = useHover((hovered: boolean) => (
        <span style={{cursor: hovered ? "pointer" : "auto"}} onClick={onClick}>
            {word.value}
            {hovered ? <Icon name="i cursor"/> : <></>}
        </span>
    ))[0]
    return (
        <React.Fragment>
            {element}
            {/* tslint:disable-next-line:jsx-self-close*/}
            <span> </span>
        </React.Fragment>
    )
};

export const EditableText = (props: {
    segment: TranscriptSegment,
    onEdit: (words: string) => void
}): JSX.Element => {
    const {segment, onEdit} = props;
    return (
        <p
            contentEditable={true}
            suppressContentEditableWarning={true}
            className="segment-edit-text"
            onBlur={(e) => {
                onEdit(e.target.innerText);
            }}
            onKeyDown={(e) => {
                if (e.key.toLowerCase() == "enter") {
                    e.preventDefault();
                    onEdit((e.target as HTMLParagraphElement).innerText);
                    return false;
                }
                return true;
            }}
        >
            {segment.words.map(word => word.value).join(" ")}
        </p>
    );
};
