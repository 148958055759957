import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {LastLocationProvider} from "react-router-last-location";

import {history} from "./reducers";
import {configureStore} from "./stores/configureStore";
import {AppContainer} from "./containers/app-container";

import "./app.css";
import {Routes} from "./routes";

const store = configureStore();

export const App = (): JSX.Element => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <LastLocationProvider>
                    <AppContainer>
                        <Routes/>
                    </AppContainer>
                </LastLocationProvider>
            </ConnectedRouter>
        </Provider>
    );
};