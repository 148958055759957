import {RecordReference} from "./record-reference";
import {FileChunkRequest} from "./file-chunk-request";
import {Unknown} from "./file-size";

export function isRecordChunkRequest(obj: any): obj is RecordChunkRequest {
    return typeof obj.recordId === "string" && typeof obj.offset === "number" &&
        (typeof obj.progress === "number" || typeof obj.progress === typeof Unknown)
}

export type RecordChunkRequest = RecordReference & FileChunkRequest
