import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {PasswordResetForm} from "../views/lost-password/password-reset-form";
import {State} from "../reducers";
import {PasswordReset, USER_PASSWORD_RESET} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.passwordReset.status,
    remoteErrors: state.operations.passwordReset.errors
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (passwordReset: PasswordReset) => dispatch(USER_PASSWORD_RESET(passwordReset))
});

export const ConnectedPasswordResetForm = connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);