import { object, ObjectSchema } from "yup";
import {EmailSchema} from "../../utils/schemas";

export type PasswordLost = {
    email: string;
}

export const PasswordLostSchema: ObjectSchema<PasswordLost> = object({
    email: EmailSchema()
}).required();


