import {
    Record as Recording,
    RECORD_UPDATE,
    RECORD_UPDATE_ERROR,
    RECORD_UPDATE_SUCCESS,
    RecordType
} from "@axys-lab/smart-report-shared";
import {ActionType, createAction, createReducer} from "typesafe-actions";
import {combineReducers} from "redux";
import {LOCATION_CHANGED} from "../../../ui";
import deepcopy from "deepcopy";
import {TranscriptSegment, TranscriptWord} from "@axys-lab/srap-common";
import * as diff from "diff";
import {initialOperationState, operation, OperationState} from "../../common";
import {TIMEOUT_MESSAGE_CODE} from "../../../../utils/constants";

export const RECORD_EDIT_START = createAction("record/EDIT/start")<Recording>();
export const RECORD_EDIT_CANCEL = createAction("record/EDIT/cancel")<Record<string, never>>();
export const RECORD_EDIT_SEGMENT_MERGE = createAction("record/EDIT/segment/merge")<{ segmentIndex: number }>();
export const RECORD_EDIT_SEGMENT_SPLIT = createAction("record/EDIT/segment/split")<{ segmentIndex: number, wordIndex: number }>();
export const RECORD_EDIT_SEGMENT_WORDS = createAction("record/EDIT/segment/words")<{ segmentIndex: number, words: string }>();
export const RECORD_EDIT_SPEAKER_SINGLE = createAction("record/EDIT/speaker/single")<{ segmentIndex: number, speakerIdOrName: string }>();
export const RECORD_EDIT_SPEAKER_GLOBAL = createAction("record/EDIT/speaker/global")<{ previousSpeakerId: string, speakerIdOrName: string }>();
export const RECORD_EDIT_NAME = createAction("record/EDIT/name")<{ name: string }>();
export const RECORD_EDIT_DETAILS = createAction("record/EDIT/details")<{ allowUsageinTrainingDataset: boolean, recordType: RecordType }>();

export type RecordEditActions = ActionType<typeof RECORD_EDIT_START |
    typeof RECORD_EDIT_CANCEL |
    typeof LOCATION_CHANGED |
    typeof RECORD_EDIT_SEGMENT_MERGE |
    typeof RECORD_EDIT_SEGMENT_SPLIT |
    typeof RECORD_EDIT_SEGMENT_WORDS |
    typeof RECORD_EDIT_SPEAKER_SINGLE |
    typeof RECORD_EDIT_SPEAKER_GLOBAL |
    typeof RECORD_UPDATE_SUCCESS |
    typeof RECORD_UPDATE_ERROR |
    typeof RECORD_EDIT_NAME |
    typeof RECORD_EDIT_DETAILS>;

export type RecordEditState = {
    op: OperationState;
    data: RecordEditDataState
}

export type RecordEditDataState = {
    editing: boolean;
    draft: Recording | null,
}

export const initialEditDataState: RecordEditDataState = {
    editing: false,
    draft: null
}

export const initialEditState: RecordEditState = {
    op: initialOperationState,
    data: initialEditDataState
};

export const editOp = combineReducers({
    op: operation(
        [{
            remote: true,
            sent: RECORD_UPDATE,
            success: RECORD_UPDATE_SUCCESS,
            errors: RECORD_UPDATE_ERROR,
            timeout: {
                trigger: () => RECORD_UPDATE_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }],
        LOCATION_CHANGED,
    ),
    data: createReducer<RecordEditDataState, RecordEditActions>(initialEditDataState)
        .handleAction([LOCATION_CHANGED], () => {
                return ({
                    ...initialEditDataState
                })
            }
        )
        .handleAction(RECORD_EDIT_START, (state, action) => ({
            editing: true,
            draft: action.payload
        }))
        .handleAction(RECORD_EDIT_CANCEL, () => ({
            ...initialEditDataState
        }))
        .handleAction([RECORD_UPDATE_SUCCESS, RECORD_UPDATE_ERROR], () => ({
            ...initialEditDataState
        }))
        .handleAction(RECORD_EDIT_SEGMENT_MERGE, (state, action) => {
            if (state.draft?.data.transcription.result?.transcript.segments) {
                const newState = deepcopy(state);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const segments = newState.draft!.data.transcription.result!.transcript.segments
                const segment = segments[action.payload.segmentIndex];
                const nextSegment = segments[action.payload.segmentIndex + 1];
                if (segment && nextSegment) {
                    segment.endTime = nextSegment.endTime
                    segment.words.push(...nextSegment.words);
                    segments.splice(action.payload.segmentIndex + 1, 1)
                    return newState
                }
            }
            return state;
        })
        .handleAction(RECORD_EDIT_SEGMENT_SPLIT, (state, action) => {
            if (state.draft?.data.transcription.result?.transcript.segments) {
                const newState = deepcopy(state);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const segments = newState.draft!.data.transcription.result!.transcript.segments
                const segment = segments[action.payload.segmentIndex];
                if (segment) {
                    if (action.payload.wordIndex < segment.words.length - 1) {
                        const first = segment.words.slice(0, action.payload.wordIndex + 1);
                        const second = segment.words.slice(action.payload.wordIndex + 1);
                        segment.words = first;
                        segment.endTime = first[first.length - 1].endTime;

                        const newSegment: TranscriptSegment = {
                            index: action.payload.segmentIndex + 1,
                            speaker: segment.speaker,
                            source: segment.source,
                            startTime: second[0].startTime,
                            endTime: second[second.length - 1].endTime,
                            words: second
                        }
                        segments.splice(action.payload.segmentIndex + 1, 0, newSegment);
                        for (let i = action.payload.segmentIndex + 2; i < segments.length; ++i) {
                            segments[i].index = i;
                        }
                        return newState
                    }
                }
            }
            return state;
        })
        .handleAction(RECORD_EDIT_SEGMENT_WORDS, (state, action) => {
            if (state.draft?.data.transcription.result?.transcript.segments) {
                const newState = deepcopy(state);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const segments = newState.draft!.data.transcription.result!.transcript.segments
                const segment = segments[action.payload.segmentIndex];
                if (segment) {
                    const changes = diff.diffArrays(
                        segment.words.map(word => word.value),
                        action.payload.words.split(/\s+/)
                    )
                    let currentIndex = 0;
                    const removed: TranscriptWord[] = [];
                    for (const change of changes) {
                        if (change.removed) {
                            removed.push(...segment.words.splice(currentIndex, change.count))
                        } else if (change.added) {
                            for (const word of change.value) {
                                const previous = removed.shift();
                                if (previous) {
                                    previous.value = word;
                                    segment.words.splice(currentIndex, 0, previous)
                                } else {
                                    const before = segment.words[currentIndex - 1]
                                    const after = segment.words[currentIndex];
                                    segment.words.splice(currentIndex, 0, {
                                        value: word,
                                        startTime: before?.endTime || segment.startTime,
                                        endTime: after?.startTime || segment.endTime,
                                        confidence: 1
                                    })
                                }
                                currentIndex += 1;
                            }
                        } else {
                            currentIndex += change.count || 0;
                        }
                    }
                    return newState;
                }
            }
            return state;
        })
        .handleAction(RECORD_EDIT_SPEAKER_SINGLE, (state, action) => {
            if (state.draft?.data.transcription.result?.transcript.segments) {
                const newState = deepcopy(state);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const duration = state.draft?.data.transcription.result?.transcript.duration;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const segments = newState.draft!.data.transcription.result!.transcript.segments
                const segment = segments[action.payload.segmentIndex];
                if (segment) {
                    const previousSpeaker = segment.speaker;
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const speakers = newState.draft!.data.transcription.result!.transcript.speakers;
                    const speakingTimePercent = (segment.endTime - segment.startTime) / duration;

                    if (speakers[action.payload.speakerIdOrName]) {
                        segment.speaker = action.payload.speakerIdOrName;
                        speakers[action.payload.speakerIdOrName].speakingTimePercent += speakingTimePercent;
                    } else {
                        const id = Math.random().toString(36).substr(2, 9);
                        speakers[id] = {
                            name: action.payload.speakerIdOrName,
                            gender: speakers[previousSpeaker].gender,
                            speakingTimePercent: speakingTimePercent
                        };
                        segment.speaker = id;
                    }
                    speakers[previousSpeaker].speakingTimePercent -= speakingTimePercent;
                    if (!segments.find(segment => segment.speaker === previousSpeaker)) {
                        delete speakers[previousSpeaker];
                    }
                    return newState;
                }
            }
            return state;
        })
        .handleAction(RECORD_EDIT_SPEAKER_GLOBAL, (state, action) => {
            if (state.draft?.data.transcription.result?.transcript.segments) {
                const newState = deepcopy(state);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const speakers = newState.draft!.data.transcription.result!.transcript.speakers;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const segments = newState.draft!.data.transcription.result!.transcript.segments

                if (speakers[action.payload.speakerIdOrName]) {
                    speakers[action.payload.speakerIdOrName].speakingTimePercent += speakers[action.payload.previousSpeakerId].speakingTimePercent;
                    segments.filter(segment => segment.speaker === action.payload.previousSpeakerId)
                        .forEach(segment => segment.speaker = action.payload.speakerIdOrName)
                    delete speakers[action.payload.previousSpeakerId];
                } else {
                    speakers[action.payload.previousSpeakerId].name = action.payload.speakerIdOrName;
                }
                return newState;
            }
            return state;
        })
        .handleAction(RECORD_EDIT_NAME, (state, action) => {
            if (!state.draft) {
                return state;
            }
            return {
                ...state,
                draft: {
                    ...(state.draft),
                    name: action.payload.name
                }
            }
        })
        .handleAction(RECORD_EDIT_DETAILS, (state, action) => {
            if (!state.draft) {
                return state;
            }
            return {
                ...state,
                draft: {
                    ...(state.draft),
                    type: action.payload.recordType,
                    allowUsageinTrainingDataset: action.payload.allowUsageinTrainingDataset
                }
            }
        })
})