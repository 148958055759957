import {Loader, Message} from "semantic-ui-react";

import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {RecordProcessStepStatus} from "@axys-lab/smart-report-shared";
import {EmotionRecognition} from "@axys-lab/emotion-shared";
import {CartesianGrid, Cell, Label, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis} from "recharts";

import "./style.css";
import {useCallback, useState} from "react";
import backgroundImage from "./matrice-emotions.png";

export const EmotionRecognitionChart = (props: {
    status: RecordProcessStepStatus | null | undefined
    emotionRecognition: EmotionRecognition | null | undefined
    currentTime: number
    trailLength: number
    height: string | number
    width: string | number
}): JSX.Element => {
    const {
        status,
        emotionRecognition,
        currentTime,
        trailLength,
        height,
        width
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    if (status === RecordProcessStepStatus.NEVER && !emotionRecognition) {
        return (<></>);
    }

    const nearestPointTrail = findNearestDataPointTrail(
        currentTime,
        emotionRecognition || undefined,
        trailLength
    );

    const [gridSize, setGridSize] = useState<{ width: number, height: number } | undefined>()

    const responsiveContainerRef = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            const grid = node.querySelector(".recharts-cartesian-grid")
            if (grid) {
                const {height, width} = grid.getBoundingClientRect();
                if (height !== gridSize?.height ||
                    width !== gridSize?.width
                ) {
                    setGridSize({height, width})
                }
            }
        }
    }, [gridSize]);

    return (
        <>
            {status === RecordProcessStepStatus.STARTED ? (
                <Loader active={true} inline={true} inverted={false}>{t("record_emotion_started")}</Loader>
            ) : status === RecordProcessStepStatus.PENDING ? (
                <Loader active={true} inline={true} inverted={false}>{t("record_emotion_pending")}</Loader>
            ) : status === RecordProcessStepStatus.ERROR ? (
                <Message error={true}>
                    {t("record_emotion_error")}
                </Message>
            ) : nearestPointTrail.length ? (
                <ResponsiveContainer
                    ref={responsiveContainerRef}
                    className="emotion-chart"
                    width={width}
                    height={height}
                >
                    <ScatterChart
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid
                            strokeOpacity={0}
                        />

                        <image
                            style={{
                                transformOrigin: "center",
                                transform: "rotate(-1deg)"
                            }}
                            href={backgroundImage}
                            height={gridSize?.height || 0}
                            width={gridSize?.width || 0}
                            preserveAspectRatio="none"
                            x={38}
                            y={20}
                        />

                        <XAxis
                            axisLine={false}
                            tickLine={false}
                            tick={false}
                            domain={[-0.5, 0.5]}
                            type="number"
                            dataKey="valence"
                            name={t("record_emotion_valence")}
                            interval="preserveStartEnd"
                            style={{
                                fontSize: '10px'
                            }}
                            // tickFormatter={(value) =>
                            //     value === -0.5 ? t("record_emotion_valence_low") :
                            //         value === 0.5 ? t("record_emotion_valence_high") :
                            //             ""
                            // }
                            dy={10}
                        >
                            <Label
                                style={{
                                    textAnchor: "middle",
                                }}
                                dy={15}
                                value={`${t("record_emotion_valence")}`}
                            />
                        </XAxis>
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            tick={false}
                            width={20}
                            domain={[-0.5, 0.5]}
                            type="number"
                            dataKey="arousal"
                            name={t("record_emotion_arousal")}
                            interval="preserveStartEnd"
                            style={{
                                fontSize: '10px'
                            }}
                            // tickFormatter={(value) =>
                            //     value === -0.5 ? t("record_emotion_arousal_low") :
                            //         value === 0.5 ? t("record_emotion_arousal_high") :
                            //             ""
                            // }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            angle={-90}
                            textAnchor="middle"
                            dx={-15}
                        >
                            <Label
                                style={{
                                    textAnchor: "middle",
                                }}
                                dx={-25}
                                angle={270}
                                value={`${t("record_emotion_arousal")}`}
                            />
                        </YAxis>
                        <Scatter isAnimationActive={true} animationDuration={0.2} fill="#8884d8"
                                 data={nearestPointTrail} line={true}>
                            {nearestPointTrail.map((point, index) => (
                                <Cell key={`cell-${index}`}
                                      fill={`rgba(255,0,0,${1.0 - index * (1.0 / trailLength)})`}/>
                            ))}
                        </Scatter>
                    </ScatterChart>
                </ResponsiveContainer>
            ) : (
                <></>
            )}
        </>
    );
};

const findNearestDataPointTrail = (time: number, emotionRecognition: EmotionRecognition | undefined, trailLength: number) => {
    const defaultPoint = {arousal: 0, valence: 0};
    if (!emotionRecognition?.timings?.length) {
        return [defaultPoint];
    }
    let selected = undefined
    let lowerBoundInc = 0;
    let upperboundExc = emotionRecognition?.timings?.length;

    do {
        const index = Math.floor((lowerBoundInc + upperboundExc) / 2);
        const point = emotionRecognition.timings[index];
        if (point.time > time) {
            upperboundExc = index
        } else {
            selected = index;
            lowerBoundInc = index
        }
    } while (lowerBoundInc < upperboundExc - 1)

    if (selected !== undefined) {
        if (selected >= trailLength - 1) {
            return emotionRecognition.timings.slice(selected - (trailLength - 1), selected + 1).reverse();
        } else {
            return [
                defaultPoint,
                ...emotionRecognition.timings.slice(0, selected + 1)
            ].reverse()
        }

    }

    return [defaultPoint];
}