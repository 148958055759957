import {applyMiddleware, compose, createStore, Store} from "redux";
import createSagaMiddleware from "redux-saga";
import {history, rootReducer, State} from "../reducers";
import {rootSaga} from "../sagas";
import {routerMiddleware} from "connected-react-router";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R;
    }
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState?: State): Store {
    const sagaMiddleware = createSagaMiddleware();
    const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)));
    const store = initialState ?
        createStore(rootReducer, initialState, enhancers) :
        createStore<State, any>(rootReducer, enhancers);

    sagaMiddleware.run(rootSaga);

    return store;
}
