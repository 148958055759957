import {Container, Progress} from "semantic-ui-react";
import {FileUploadProgress, Unknown} from "@axys-lab/smart-report-shared";

import "./style.css";

export const FileUploadProgressBar = (props: { progress: FileUploadProgress, visible: boolean }): JSX.Element => (
    <Container
        className="upload-progress"
        style={!props.visible ? {
            display: "none"
        } : {}}
        textAlign="center"
        text={false}
    >
        <Progress percent={props.progress !== Unknown ? props.progress * 100 : 100} indicating={true}/>
    </Container>
);