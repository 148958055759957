import {Notification} from "@axys-lab/smart-report-shared";
import {animated, useTransition} from "react-spring";
import {Notification as NotificationComponent} from "./notification";

import "./style.css";

type Props = {
    notifications: Notification[]
    onDismiss: (notification: Notification) => void
};

export const Notifications = (p: Props): JSX.Element => {
    const {notifications, onDismiss} = p;
    const transitions = useTransition(
        notifications,
        {
            from: {opacity: 0, height: 0},
            enter: {opacity: 1, height: 'auto'},
            leave: {opacity: 0, height: 0}
        }
    );

    return (
        <div className="notifications">
            {
                transitions((style, item) => (
                    <animated.div style={{...style, overflow: "hidden"}}>
                        <NotificationComponent
                            notification={item}
                            onDismiss={() => {
                                onDismiss(item);
                            }}
                        />
                    </animated.div>
                ))
            }
        </div>
    );
};
