import {FormikProps} from "formik";
import {useTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router";
import {Link} from "react-router-dom";
import {Button, Container, Form, Segment} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../../utils/form";
import {RemoteCommandStatus} from "../../../utils/remote-command-status";

import "./style.css";
import {UserLogin, UserLoginSchema} from "@axys-lab/smart-report-shared";

type Props = RemoteCommandProps & {
    onSubmit: (userLogin: UserLogin) => void;
};

type FormProps = Props
    & RouteComponentProps<{ token: string }>
    & InjectedRemoteFormProps
    & FormikProps<UserLogin>;

export const LocalLogin: React.ComponentType<RemoteCommandProps & Props> =
    withRemoteForm<Props, UserLogin>(
        {
            validationSchema: UserLoginSchema,
            mapPropsToValues: () => ({
                email: "",
                password: ""
            }),
            handleSubmit: (values, {props}) => {
                props.onSubmit(values);
            },
            displayName: "LocalLoginForm",
        },
        {
            dontResetFormOnSuccess: true
        }
    )(
        withRouter((props: FormProps) => {
                const {
                    remoteStatus,
                    touched,
                    errors,
                } = props;

                const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

                const touchedErrors = (Object.keys(errors) as Array<keyof UserLogin>)
                    .filter(key => touched[key] === true)
                    .map(key => errors[key]);

                const isInError = touchedErrors.length > 0;

                return (
                    <Form
                        size="large"
                        {...remoteFormProps(props)}
                        error={isInError}
                    >
                        {
                            !(remoteStatus === RemoteCommandStatus.SUCCESS) &&
                            <div>
                                <Segment stacked={false}>
                                    <Form.Input
                                        id="email"
                                        fluid={true}
                                        icon="user"
                                        iconPosition="left"
                                        placeholder={t("email_address")}
                                        required={true}
                                        {...remoteFormFieldProps(
                                            props,
                                            "email"
                                        )}
                                    />
                                    <Form.Input
                                        id="password"
                                        fluid={true}
                                        icon="lock"
                                        iconPosition="left"
                                        placeholder={t("password")}
                                        type="password"
                                        required={true}
                                        {...remoteFormFieldProps(
                                            props,
                                            "password"
                                        )}
                                    />

                                    <Button
                                        color={PRIMARY_COLOR}
                                        fluid={true}
                                        size="large"
                                        type="submit"
                                        {...remoteFormSubmitProps(props)}
                                    >
                                        {t("login")}
                                    </Button>
                                    <Container className="lost-password-link" fluid={true}>
                                        <Link to="/lost-password">{t("lost_password_link")}</Link>
                                    </Container>
                                </Segment>
                            </div>
                        }
                    </Form>
                );
            }
        )
    );