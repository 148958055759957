export enum Feature {
    SOCIAL_LOGIN_MICROSOFT= "social-login-microsoft",
    SOCIAL_LOGIN_GOOGLE = "social-login-google",
    LOCAL_ACCOUNT = "local-account",
    REGISTER = "register",
    RIDA = "rida",
    EMOTION_RECOGNITION = "emotion-recognition",
    EXPORT = "export",
    EDIT = "edit",
    UPLOAD_ONEDRIVE = "upload-onedrive"
}
