import {FormikProps} from "formik";

import {useTranslation} from "react-i18next";
import {Button, Form, Segment} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {EmailValidationTokenResend, EmailValidationTokenResendSchema} from "@axys-lab/smart-report-shared";

type Props = RemoteCommandProps & {
    email?: string;
    onSubmit: (tokenResend: EmailValidationTokenResend) => void
};

export const EmailValidationResendForm = withRemoteForm<Props, EmailValidationTokenResend>(
    {
        validationSchema: EmailValidationTokenResendSchema,
        mapPropsToValues: (props) => ({
            email: props.email || ""
        }),
        handleSubmit: (values, {props}) => {
            props.onSubmit(values);
        },
        displayName: "EmailValidationResendForm",
    },
    {
        dontResetFormOnSuccess: true
    }
)((props: Props
    & InjectedRemoteFormProps
    & FormikProps<EmailValidationTokenResend>
) => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {remoteStatus} = props;

    return (
        <Form
            size="large"
            {...remoteFormProps(props)}
        >
            <Segment stacked={false}>
                <RemoteFormMessages
                    {...props}
                    errorHeaderMessageKey="email_validation_resend_error"
                    successHeaderMessageKey="email_validation_resend_success"
                    successDetailsMessageKey="email_validation_resend_success_details"
                    keepSuccessMessageWhenDirty={true}
                />
                {
                    !(remoteStatus === RemoteCommandStatus.SUCCESS) &&
                    <div>
                        <Form.Input
                            id="email"
                            fluid={true}
                            icon="envelope outline"
                            iconPosition="left"
                            placeholder={t("email_address")}
                            required={true}
                            {...remoteFormFieldProps(
                                props,
                                "email"
                            )}
                        />
                        <Button
                            color={PRIMARY_COLOR}
                            fluid={true}
                            size="large"
                            type="submit"
                            {...remoteFormSubmitProps(props, {
                                allowCleanSubmit: true
                            })}
                        >
                            {t("email_validation_resend_submit")}
                        </Button>
                    </div>
                }
            </Segment>
        </Form>
    );
});