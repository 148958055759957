import {FormikProps} from "formik";
import {useTranslation} from "react-i18next";
import {Button, Form, Header, Modal, Segment} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {
    FieldType,
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {UserRegistration, UserRegistrationSchema} from "@axys-lab/smart-report-shared";

import "./style.css";

type FormProps = RemoteCommandProps & {
    onRegister: (userRegistration: UserRegistration) => void;
};

const EnhancedForm = withRemoteForm<FormProps, UserRegistration>({
    validationSchema: UserRegistrationSchema,
    mapPropsToValues: () => ({
        email: "",
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        passwordConfirmation: "",
        useGravatar: true
    }),
    handleSubmit: (values, {props}) => {
        props.onRegister(values);
    },
    displayName: "RegisterForm",
})((props: FormProps
    & InjectedRemoteFormProps
    & FormikProps<UserRegistration>
) => {
    const {
        remoteStatus,
    } = props;

    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    return (
        <Form
            size="large"
            {...remoteFormProps(props)}
        >
            <Segment stacked={false}>
                <RemoteFormMessages
                    {...props}
                    errorHeaderMessageKey="user_registration_error"
                    successHeaderMessageKey="user_registration_success"
                    successDetailsMessageKey="user_registration_success_details"
                />
                {
                    !(remoteStatus === RemoteCommandStatus.SUCCESS) &&
                    <div>
                        <Form.Group widths="equal">
                            <Form.Input
                                id="email"
                                fluid={true}
                                label={t("email_address")}
                                icon="envelope outline"
                                iconPosition="left"
                                placeholder={t("email_address")}
                                type="email"
                                required={true}
                                {...remoteFormFieldProps(
                                    props,
                                    "email"
                                )}
                            />
                            <Form.Input
                                id="username"
                                fluid={true}
                                label={t("username")}
                                icon="user"
                                iconPosition="left"
                                placeholder={t("username")}
                                required={true}
                                {...remoteFormFieldProps(
                                    props,
                                    "username"
                                )}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                id="firstname"
                                fluid={true}
                                label={t("firstname")}
                                icon="id card outline"
                                iconPosition="left"
                                placeholder={t("firstname")}
                                required={true}
                                {...remoteFormFieldProps(
                                    props,
                                    "firstname"
                                )}
                            />
                            <Form.Input
                                id="lastname"
                                fluid={true}
                                label={t("lastname")}
                                icon="id card outline"
                                iconPosition="left"
                                placeholder={t("lastname")}
                                required={true}
                                {...remoteFormFieldProps(
                                    props,
                                    "lastname"
                                )}
                            />
                        </Form.Group>
                        < Form.Group widths="equal">
                            <Form.Input
                                id="password"
                                fluid={true}
                                label={t("password")}
                                icon="lock"
                                iconPosition="left"
                                placeholder={t("password")}
                                type="password"
                                required={true}
                                {...remoteFormFieldProps(
                                    props,
                                    "password"
                                )}
                            />
                            <Form.Input
                                id="passwordConfirmation"
                                fluid={true}
                                label={t("password_confirmation")}
                                icon="lock"
                                iconPosition="left"
                                placeholder={t("password_confirmation")}
                                type="password"
                                required={true}
                                {...remoteFormFieldProps(
                                    props,
                                    "passwordConfirmation"
                                )}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Checkbox
                                id="useGravatar"
                                label={t("use_gravatar")}
                                {...remoteFormFieldProps(
                                    props,
                                    "useGravatar",
                                    FieldType.CHECKBOX
                                )}
                            />
                        </Form.Group>
                        <Button
                            color={PRIMARY_COLOR}
                            fluid={true}
                            size="large"
                            type="submit"
                            {...remoteFormSubmitProps(props)}
                        >
                            {t("register")}
                        </Button>
                    </div>
                }
            </Segment>
        </Form>
    );
});

export const RegisterForm = (props: FormProps & {
    display: boolean;
    onClose: () => void;
}): JSX.Element => {
    const {display, onClose} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Modal
            open={display}
            onClose={onClose}
            closeIcon={true}
            basic={false}
            dimmer="blurring"
            size="tiny"
        >
            <Segment className="register-form" textAlign="center" style={{width: "100%"}} basic={true}>
                <Header as="h2" color={PRIMARY_COLOR} textAlign="center">
                    {t("register_header")}
                </Header>
                <EnhancedForm {...props}/>
            </Segment>
        </Modal>
    );
};
