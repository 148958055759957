import {Trans, useTranslation,} from "react-i18next";
import {Header, Image, Message, Modal, Segment} from "semantic-ui-react";

import logo from "../../media/picto-axys-invert.png";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {FormMessages} from "../../utils/form";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {SocialProviderColorSchemes} from "../../utils/social-providers-icons";
import {SocialLogin} from "./social-login";
import "./style.css";
import {
    Feature,
    SocialProvider,
    SocialProviders,
    UserLogin,
    UserSocialLoginAuthorized,
    UserSocialProvider
} from "@axys-lab/smart-report-shared";
import {LocalLogin} from "./local-login";
import {useFeaturesEnabled} from "../../containers/features-provider";

export const LoginForm = (props: {
    display: boolean;
    onClose: () => void;
    displayRegister: () => void;
    url: string;
    alreadyLoggedIn: boolean;
    onOpen: (provider: SocialProvider, socialLoginId: string) => void,
    onSocialLoginAuthorized: (socialLoginAuthorized: UserSocialLoginAuthorized) => void;
    onSocialLoginCancel: (userSocialProvider: UserSocialProvider) => void;
    onLocalLogin: (userLogin: UserLogin) => void;
    remoteErrors: string[];
    remoteStatus: RemoteCommandStatus;
}): JSX.Element => {
    const {
        display,
        onClose,
        displayRegister,
        onLocalLogin,
        remoteErrors,
        remoteStatus,
        ...socialLoginProps
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    const errorMessages = remoteErrors && remoteErrors.length > 0 ?
        [{
            key: "login-error",
            error: true,
            header: "user_login_error",
            list: remoteErrors
        }] :
        [];

    return (
        <Modal
            open={display}
            onClose={onClose}
            closeIcon={true}
            basic={false}
            dimmer="blurring"
            size="tiny"
        >
            <Segment className="login-form" textAlign="center" style={{width: "100%"}} basic={true}>
                <Header as="h2" color={PRIMARY_COLOR} textAlign="center">
                    <Image src={logo}/>
                    {t("login_header")}
                </Header>
                <FormMessages
                    messages={errorMessages}
                />
                {useFeaturesEnabled(...Object.values(SocialProviders)) ? (
                    <SocialLoginPanel {...socialLoginProps} remoteErrors={remoteErrors} remoteStatus={remoteStatus}/>
                ) : <></>}
                {useFeaturesEnabled(Feature.LOCAL_ACCOUNT) ? (
                    <>
                        <LocalLogin
                            remoteStatus={remoteStatus}
                            remoteErrors={remoteErrors}
                            onSubmit={onLocalLogin}
                        />
                        {useFeaturesEnabled(Feature.REGISTER) ? (
                            <Message>
                                <Trans i18nKey="register_link">Want to create a new account without using your social
                                    network account ? <a href="#signup" onClick={(e) => {
                                        e.preventDefault();
                                        displayRegister();
                                    }}>Sign Up</a></Trans>
                            </Message>
                        ) : (<></>)}
                    </>
                ) : (<></>)}
            </Segment>
        </Modal>
    );
};

export const SocialLoginPanel = (props: {
    url: string
    alreadyLoggedIn: boolean
    remoteStatus: RemoteCommandStatus,
    remoteErrors: string[],
    onOpen: (provider: SocialProvider, socialLoginId: string) => void,
    onSocialLoginAuthorized: (socialLoginAuthorized: UserSocialLoginAuthorized) => void;
    onSocialLoginCancel: (userSocialProvider: UserSocialProvider) => void;
}): JSX.Element => {
    const {url, alreadyLoggedIn, remoteErrors, remoteStatus, onOpen, onSocialLoginAuthorized, onSocialLoginCancel} = props;
    return (
        <Message>
            {Object.keys(SocialProviders)
                .filter(provider => useFeaturesEnabled(SocialProviders[provider as SocialProvider]))
                .map(provider => (
                    <SocialLogin
                        key={provider}
                        provider={provider as SocialProvider}
                        url={`${url}/${provider}`}
                        colorScheme={SocialProviderColorSchemes[provider as SocialProvider]}
                        buttonText={`login_with_${provider}`}
                        disabled={alreadyLoggedIn || remoteStatus === RemoteCommandStatus.PENDING}
                        forcedClose={alreadyLoggedIn || (remoteErrors && remoteErrors.length > 0)}
                        onOpen={onOpen}
                        onAuthorized={onSocialLoginAuthorized}
                        onUnexpectedClose={() => onSocialLoginCancel({provider: provider as SocialProvider})}
                    />
                ))}
        </Message>
    )
};