import {ActionType} from "typesafe-actions";

import * as UserActions from "./users";
import * as NotificationActions from "./notifications";
import * as RecordsActions from "./records";
import * as FeaturesActions from "./features";

export * from "./users";
export type UserAction = ActionType<typeof UserActions>;

export * from "./notifications";
export type NotificationAction = ActionType<typeof NotificationActions>;

export * from "./records";
export type RecordAction = ActionType<typeof RecordsActions>;

export * from "./features";
export type FeatureAction = ActionType<typeof FeaturesActions>;

export * from "./utils";