import {all, put} from "redux-saga/effects";
import {usersSaga} from "./users";
import {websocketSaga} from "./websocket";
import {WEBSOCKET_START} from "../ducks/operations/sockets";
import {recordsSaga} from "./records";
import {remoteMessageChannels} from "./shared";
import {SERVER_URL, SOCKET_NAMESPACE} from "../utils/constants";
import {notificationsSaga} from "./notifications";
import {featuresSaga} from "./features";

function* start() {
    yield put(WEBSOCKET_START({
        namespace: SOCKET_NAMESPACE
    }));
}

export function* rootSaga(): Generator {
    yield all([
        websocketSaga({
            serverUrl: SERVER_URL,
            namespace: SOCKET_NAMESPACE,
            channels: remoteMessageChannels
        }),
        usersSaga(),
        recordsSaga(),
        notificationsSaga(),
        featuresSaga(),
        start()
    ]);
}
