import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {EmailValidationResendForm} from "../views/email-validation/email-validation-resend-form";
import {State} from "../reducers";
import {EmailValidationTokenResend, USER_EMAIL_VALIDATION_TOKEN_RESEND} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.emailValidationResend.status,
    remoteErrors: state.operations.emailValidationResend.errors,
    email: state.user.token ? state.user.token.value.user.email : ""
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (tokenResend: EmailValidationTokenResend) =>
        dispatch(USER_EMAIL_VALIDATION_TOKEN_RESEND(tokenResend))
});

export const ConnectedEmailValidationResendForm =
    connect(mapStateToProps, mapDispatchToProps)(EmailValidationResendForm);