import {boolean, object, ObjectSchema} from "yup";
import {RecordIdSchema, RecordNameSchema, RecordTypeSchema} from "../../utils";
import {Transcript, TranscriptSchema} from "@axys-lab/srap-common";
import {RecordType} from "./record-type";

export type RecordUpdate = {
    recordId: string,
    name: string,
    recordType: RecordType,
    allowUsageinTrainingDataset: boolean,
    transcript?: Transcript
}

export const RecordUpdateSchema: ObjectSchema<RecordUpdate> = object({
    recordId: RecordIdSchema,
    name: RecordNameSchema,
    recordType: RecordTypeSchema,
    allowUsageinTrainingDataset: boolean()
        .required("allow_usage_in_training_dataset_required"),
    transcript: TranscriptSchema.optional()
}).required();
