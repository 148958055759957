import {SocialProvider} from "@axys-lab/smart-report-shared";
import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react/dist/commonjs/generic";

export type SocialProviderColorScheme = {
    icon: SemanticICONS;
    color: SemanticCOLORS | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube"
}

export const SocialProviderColorSchemes: { [key in SocialProvider]: SocialProviderColorScheme } = {
    "microsoft": {
        icon: "microsoft",
        color: "teal"
    },
    "google": {
        icon: "google",
        color: "google plus"
    }
};