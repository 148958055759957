import { object, ObjectSchema } from "yup";
import {EmailSchema, PasswordSchema} from "../../utils/schemas";

export type UserLogin = {
    email: string;
    password: string;
}

export const UserLoginSchema: ObjectSchema<UserLogin> = object({
    email: EmailSchema(),
    password: PasswordSchema()
}).required();


