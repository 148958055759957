import {number, ref, Schema, string, StringSchema} from "yup";
import {SocialProvider, SocialProviders} from "./constants";
import {EnumValues} from "enum-values";
import {RecordType} from "../interfaces/dtos/record-type";

export const PasswordSchema = (fieldPrefix = "password"): StringSchema<string> => string()
    .required(`${fieldPrefix}_required`)
    .min(5, `${fieldPrefix}_min_5`);

export const PasswordConfirmationSchema = (fieldPrefix = "password_confirmation", passwordField: string): StringSchema<string> => string()
    .required(`${fieldPrefix}_required`)
    .oneOf([ref(passwordField), ""], `${fieldPrefix}_equals`);

export const TokenSchema = (fieldPrefix = "token"): StringSchema<string> => string()
    .required(`${fieldPrefix}_required`)
    .min(36, `${fieldPrefix}_36`)
    .max(36, `${fieldPrefix}_36`);

export const EmailSchema = (fieldPrefix = "email"): StringSchema<string> => string()
    .required(`${fieldPrefix}_required`)
    .email(`${fieldPrefix}_format`);

export const UsernameSchema = (fieldPrefix = "username"): StringSchema<string> => string()
    .matches(
        /^[a-zA-Z0-9_\-@.]+$/,
        {
            message: `${fieldPrefix}_alphanumeric`,
            excludeEmptyString: true,
        },
    )
    .required(`${fieldPrefix}_required`)
    .trim()
    .lowercase()
    .min(3, `${fieldPrefix}_min_3`);

export const SocialProviderSchema = (fieldPrefix = "provider"): Schema<SocialProvider> => string()
    .required(`${fieldPrefix}_required`)
    .oneOf(Object.keys(SocialProviders), `${fieldPrefix}_unknown`) as Schema<SocialProvider>;

export const LastnameSchema = string()
    .required("lastname_required")
    .trim()
    .min(2, "lastname_min_2");

export const FirstnameSchema = string()
    .required("firstname_required")
    .trim()
    .min(2, "firstname_min_2");

export const RecordIdSchema = string()
    .required("record_id_required")
    .trim()
    .min(24, "record_id_length")
    .max(24, "record_id_length")

export const RecordNameSchema = string()
    .required("name_required")
    .matches(
        /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_\-' ]+$/,
        {
            message: "name_alphanumeric",
            excludeEmptyString: true,
        },
    )
    .trim()
    .min(3, "name_min_3")

export type RecordTypeSchema = StringSchema<RecordType>
export const RecordTypeSchema = (string() as RecordTypeSchema)
    .oneOf(EnumValues.getValues<RecordType>(RecordType), "type_unknown")
    .required("type_required")

export const NumberOfSpeakersSchema = number()
    .min(1, "number_of_speakers_min_1")
    .max(20, "number_of_speakers_max_20")