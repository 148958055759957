import {useTranslation} from "react-i18next";
import {Button, Icon, SemanticCOLORS, SemanticSIZES} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";

import "./style.css";

export const LoginButton = (props: {
    onClick: () => void;
    size: SemanticSIZES;
    color?: SemanticCOLORS;
}): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {color, size, onClick} = props;
    return (
        <Button
            className="login-button"
            color={color || PRIMARY_COLOR}
            size={size}
            onClick={onClick}
        >
            <Icon
                name="user circle"
            />
            {t("get_started")}
        </Button>
    );
};
