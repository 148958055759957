import {Divider, Grid} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {useFeaturesEnabled} from "../../../containers/features-provider";
import {Feature} from "@axys-lab/smart-report-shared";
import {ConnectedCloudFilePicker} from "../../../containers/connected-cloud-file-picker";
import {ConnectedRecordUploader} from "../../../containers/connected-record-uploader";


export const RecordFileSelector = (): JSX.Element => {

    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    const Uploader = () => <ConnectedRecordUploader className="record-uploader"/>

    if (!useFeaturesEnabled(Feature.UPLOAD_ONEDRIVE)) {
        return <Uploader/>
    }

    return (
        <>
            <Grid columns={2} relaxed="very" textAlign="center" verticalAlign="middle">
                <Grid.Column>
                    <Uploader/>
                </Grid.Column>
                <Grid.Column>
                    <ConnectedCloudFilePicker className="record-uploader"/>
                </Grid.Column>
            </Grid>
            <Divider vertical={true}>{t("or")}</Divider>
        </>
    )
};