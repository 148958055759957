
import { ForceLoginDisplay } from "../../containers/force-login-display";
import { Home } from "../home";

import "./style.css";

export const Login = (): JSX.Element => (
    <ForceLoginDisplay>
        <Home/>
    </ForceLoginDisplay>
);
