import "./style.css";
import {Dimmer, Header, List, Loader, Message, Table} from "semantic-ui-react";
import {Rida} from "@axys-lab/rida-shared";

import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {TranscriptSpeakers} from "@axys-lab/srap-common";
import {RecordProcessStepStatus} from "@axys-lab/smart-report-shared";

export const noInfosLabel = (message: string): JSX.Element => (
    <span style={{color: "gray", fontStyle: "italic"}}>{message}</span>
)

export const extractSpeakerNames = (fromRida: string[], fromTranscript: TranscriptSpeakers): string[] => {
    return Array.from(new Set(fromRida.map(speaker => {
        const extracted = fromTranscript[speaker];
        if (extracted) {
            return extracted.name
        }
        return speaker;
    })))
}

export const RIDAPanel = (props: {
    status: RecordProcessStepStatus | null | undefined
    speakers: TranscriptSpeakers
    recordDate: Date
    rida: Rida | null | undefined
}): JSX.Element => {
    const {status, speakers, recordDate, rida} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    return (
        <div className="rida">
            {status === RecordProcessStepStatus.STARTED ? (
                <Dimmer page={false} active={true} inverted={true}>
                    <Loader inverted={true}>{t("record_rida_started")}</Loader>
                </Dimmer>
            ) : status === RecordProcessStepStatus.PENDING ? (
                <Dimmer page={false} active={true} inverted={true}>
                    <Loader inverted={true}>{t("record_rida_pending")}</Loader>
                </Dimmer>
            ) : status === RecordProcessStepStatus.ERROR ? (
                <Message error={true}>
                    {t("record_rida_error")}
                </Message>
            ) : status === RecordProcessStepStatus.NEVER || !rida ? (
                <Message info={true}>
                    {t("record_no_rida")}
                </Message>
            ) : (
                <>
                    <div className="rida-section">
                        <Header as="h2">{t("record_rida_general_infos")}</Header>
                        <Table definition={true}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{t("record_rida_meeting_subject")}</Table.Cell>
                                    <Table.Cell style={{width: "90%"}}>
                                        {rida.meetingSubject || noInfosLabel(t("record_rida_no_meeting_subject"))}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>{t("record_rida_attendees")}</Table.Cell>
                                    <Table.Cell>
                                        {Array.isArray(rida.attendees) && rida.attendees.length ? (
                                            <List>
                                                {extractSpeakerNames(rida.attendees, speakers).map((attendee, index) => (
                                                    <List.Item key={index}>
                                                        <List.Icon name="user"/>
                                                        <List.Content>{attendee}</List.Content>
                                                    </List.Item>
                                                ))}
                                            </List>
                                        ) : noInfosLabel(t("record_rida_no_attendees"))
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>{t("record_meeting_date")}</Table.Cell>
                                    <Table.Cell>{recordDate?.toLocaleString() || ""}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    <div className="rida-section">
                        <Header as="h2">{t("record_rida_information_report")}</Header>
                        <Table definition={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell
                                        style={{width: "100%"}}>{t("record_rida_information")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    Array.isArray(rida.informations) && rida.informations.length ? (
                                        rida.informations.map((info, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                                                <Table.Cell>{info}</Table.Cell>
                                            </Table.Row>
                                        ))
                                    ) : (
                                        <Table.Row>
                                            <Table.Cell/>
                                            <Table.Cell>{noInfosLabel(t("record_rida_no_information"))}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                    <div className="rida-section">
                        <Header as="h2">{t("record_rida_decisions_report")}</Header>
                        <Table definition={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell
                                        style={{width: "50%"}}>{t("record_rida_decisions")}</Table.HeaderCell>
                                    <Table.HeaderCell
                                        style={{width: "25%"}}>{t("record_rida_accountable_persons")}</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: "25%"}}>{t("record_rida_dates")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    Array.isArray(rida.decisions) && rida.decisions.length ? (
                                        rida.decisions.map((decision, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                                                <Table.Cell>{decision.decision}</Table.Cell>
                                                <Table.Cell>
                                                    {Array.isArray(decision.accountablePersons) && decision.accountablePersons.length ? (
                                                        <List>
                                                            {extractSpeakerNames(decision.accountablePersons, speakers).map((person, index) => (
                                                                <List.Item key={index}>
                                                                    <List.Icon name="user"/>
                                                                    <List.Content>{person}</List.Content>
                                                                </List.Item>
                                                            ))}
                                                        </List>
                                                    ) : noInfosLabel(t("record_rida_no_accountable_persons"))
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {Array.isArray(decision.dates) && decision.dates.length ? (
                                                        <List>
                                                            {decision.dates.map((date, index) => (
                                                                <List.Item key={index}>
                                                                    <List.Icon name="clock outline"/>
                                                                    <List.Content>{date}</List.Content>
                                                                </List.Item>
                                                            ))}
                                                        </List>
                                                    ) : noInfosLabel(t("record_rida_no_dates"))
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    ) : (
                                        <Table.Row>
                                            <Table.Cell/>
                                            <Table.Cell
                                                colSpan="2">{noInfosLabel(t("record_rida_no_decisions"))}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                    <div className="rida-section">
                        <Header as="h2">{t("record_rida_actions_report")}</Header>
                        <Table definition={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell
                                        style={{width: "50%"}}>{t("record_rida_actions")}</Table.HeaderCell>
                                    <Table.HeaderCell
                                        style={{width: "25%"}}>{t("record_rida_accountable_persons")}</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: "25%"}}>{t("record_rida_dates")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    Array.isArray(rida.actions) && rida.actions.length ? (
                                        rida.actions.map((action, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                                                <Table.Cell>{action.action}</Table.Cell>
                                                <Table.Cell>
                                                    {Array.isArray(action.accountablePersons) && action.accountablePersons.length ? (
                                                        <List>
                                                            {extractSpeakerNames(action.accountablePersons, speakers).map((person, index) => (
                                                                <List.Item key={index}>
                                                                    <List.Icon name="user"/>
                                                                    <List.Content>{person}</List.Content>
                                                                </List.Item>
                                                            ))}
                                                        </List>
                                                    ) : noInfosLabel(t("record_rida_no_accountable_persons"))
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {Array.isArray(action.dates) && action.dates.length ? (
                                                        <List>
                                                            {action.dates.map((date, index) => (
                                                                <List.Item key={index}>
                                                                    <List.Icon name="clock outline"/>
                                                                    <List.Content>{date}</List.Content>
                                                                </List.Item>
                                                            ))}
                                                        </List>
                                                    ) : noInfosLabel(t("record_rida_no_dates"))
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    ) : (
                                        <Table.Row colSpan="3">
                                            <Table.Cell/>
                                            <Table.Cell
                                                colSpan="2">{noInfosLabel(t("record_rida_no_actions"))}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </>
            )}
        </div>
    );
};
