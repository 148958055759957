import {RecordTable} from "./records-table";
import {Message, Pagination} from "semantic-ui-react";
import {SearchedRecord} from "@axys-lab/smart-report-shared";

import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import {RemoteCommandStatus} from "../../../utils/remote-command-status";
import {Loader} from "../../../components/loader";

import "./style.css";

export const RecordsPaginatedTable = (props: {
    status: RemoteCommandStatus,
    records: SearchedRecord[],
    page: number,
    totalPages: number,
    errors?: string[],
    onPageChange: (page: number) => void;
}): JSX.Element => {
    const {status, records, errors, page, totalPages, onPageChange} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        status === RemoteCommandStatus.PENDING ? (
            <Loader display={true} message={t("records_loading")}/>
        ) : (
            <>
                {errors && errors.length ? (<Message
                    negative={true}
                    list={errors.map(error => t(error))}
                />) : (<></>)}
                <RecordTable records={records}/>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={page}
                    siblingRange={1}
                    totalPages={totalPages}
                    disabled={totalPages < 2}
                    onPageChange={(e, data) => {
                        onPageChange(Number(data.activePage))
                    }}
                />
            </>
        )
    )
}