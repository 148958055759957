import {connect} from "react-redux";
import {State} from "../reducers";
import {ExportButton} from "../views/record-view/record-view-panel/actions-bar/export-button";
import {SERVER_URL} from "../utils/constants";

export const ConnectedRecordExportButton = connect(
    (state: State) => ({
        exportUrl: state.operations.recordView.data.record?.recordId ?
            `${SERVER_URL}/api/records/${state.operations.recordView.data.record.recordId}/doc` : null,
        display: !!state.operations.recordView.data.record?.data.transcription || !!state.operations.recordView.data.record?.data.rida
    }),
    () => ({})
)((props: {
    exportUrl: string | null,
    displayText: boolean,
    display: boolean,
}) => (
    props.display ? (
        <ExportButton {...props} />
    ) : (
        <></>
    )
));