import {useTranslation,} from "react-i18next";
import {Dropdown, Table} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {EnumValues} from "enum-values";
import {RecordType} from "@axys-lab/smart-report-shared";

export const RecordViewDetailsMenu = (props: {
    recordType: RecordType;
    allowUsageinTrainingDataset: boolean;
    editing: boolean,
    onEdit: (allowUsageinTrainingDataset: boolean, recordType: RecordType) => void
}): JSX.Element => {
    const {
        editing,
        onEdit,
        recordType,
        allowUsageinTrainingDataset
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    return (
        <Table definition={true}>
            <Table.Body>
                {recordType !== null && (
                    <Table.Row>
                        <Table.Cell width={8}>{t("record_type")}</Table.Cell>
                        <Table.Cell>
                            {!editing ? t(recordType) :
                                <Dropdown
                                    inline
                                    options={
                                        EnumValues.getValues<RecordType>(RecordType)
                                            .map(recordType => ({
                                                key: recordType,
                                                text: t(recordType),
                                                value: recordType,
                                            }))
                                    }
                                    defaultValue={recordType}
                                    onChange={(e, {value}) => {
                                        onEdit(
                                            allowUsageinTrainingDataset,
                                            value as RecordType,
                                        )
                                    }}
                                />
                            }
                        </Table.Cell>
                    </Table.Row>
                )}
                {/*{allowUsageinTrainingDataset !== null && (*/}
                {/*    <Table.Row>*/}
                {/*        <Table.Cell width={8}>{t("record_allow_usage_in_training_dataset_short")}</Table.Cell>*/}
                {/*        <Table.Cell>*/}
                {/*            {!editing ? t(allowUsageinTrainingDataset ? "yes" : "no") :*/}
                {/*                <Checkbox*/}
                {/*                    onChange={(e, {checked}) => {*/}
                {/*                        onEdit(*/}
                {/*                            checked || false,*/}
                {/*                            recordType*/}
                {/*                        )*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            }*/}
                {/*        </Table.Cell>*/}
                {/*    </Table.Row>*/}
                {/*)}*/}
            </Table.Body>
        </Table>
    );
};