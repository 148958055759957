import {FormikProps} from "formik";
import {useTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Form, Segment} from "semantic-ui-react";
import {
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {useMount} from "react-use";
import {EmailValidationToken, EmailValidationTokenSchema} from "@axys-lab/smart-report-shared";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";

type Props = RemoteCommandProps & {
    onSubmit: (emailValidation: EmailValidationToken) => void
};

type FormProps = Props
    & RouteComponentProps<{ token: string }>
    & InjectedRemoteFormProps
    & FormikProps<EmailValidationToken>;

export const EmailValidationForm =
    withRemoteForm<Props, EmailValidationToken>(
        {
            validationSchema: EmailValidationTokenSchema,
            mapPropsToValues: () => ({
                token: ""
            }),
            handleSubmit: (values, {props}) => {
                props.onSubmit(values);
            },
            displayName: "EmailValidationForm",
        }
    )(
        withRouter((props: FormProps) => {

                useMount(
                    () => {
                        const {match, setValues, submitForm} = props;
                        if (match.params.token) {
                            void setValues({
                                token: match.params.token
                            });
                            setTimeout(() => {
                                void submitForm().finally()
                            }, 500);
                        }
                    },
                );

                const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
                const {remoteStatus} = props;

                return (
                    <Form
                        size="large"
                        {...remoteFormProps(props)}
                    >
                        <Segment stacked={false}>
                            <RemoteFormMessages
                                {...props}
                                errorHeaderMessageKey="email_validation_error"
                                successHeaderMessageKey="email_validation_success"
                                successDetailsMessageKey="email_validation_success_details"
                            />
                            {
                                !(remoteStatus === RemoteCommandStatus.SUCCESS) &&
                                <div>
                                    <Form.Input
                                        id="token"
                                        fluid={true}
                                        icon="user secret"
                                        iconPosition="left"
                                        placeholder={t("email_validation_token")}
                                        required={true}
                                        {...remoteFormFieldProps(
                                            props,
                                            "token"
                                        )}
                                    />
                                    <Button
                                        color={PRIMARY_COLOR}
                                        fluid={true}
                                        size="large"
                                        type="submit"
                                        {...remoteFormSubmitProps(props)}
                                    >
                                        {t("email_validation_token_submit")}
                                    </Button>
                                </div>
                            }
                        </Segment>
                    </Form>
                );
            }
        )
    );