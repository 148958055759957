import "./style.css";
import {Card} from "semantic-ui-react";

import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {RecordProcessStepStatus} from "@axys-lab/smart-report-shared";
import {EmotionRecognition} from "@axys-lab/emotion-shared";
import {ConnectedEmotionRecognitionChart} from "../../../../containers/connected-emotion-recognition-chart";


export const EmotionRecognitionMenuBox = (props: {
    status: RecordProcessStepStatus | null | undefined
    emotionRecognition: EmotionRecognition | null | undefined
}): JSX.Element => {
    const {
        status,
        emotionRecognition,
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    if (status === RecordProcessStepStatus.NEVER && !emotionRecognition) {
        return (<></>);
    }

    return (
        <Card className="emotion-recognition-card">
            <Card.Content>
                <Card.Header>{t("record_emotion")}</Card.Header>
                <Card.Meta>
                    <ConnectedEmotionRecognitionChart
                        height={222}
                        width="100%"
                    />
                </Card.Meta>
            </Card.Content>
        </Card>
    );
};
