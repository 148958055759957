import "./style.css";
import {Dimmer, Header, Loader, Message} from "semantic-ui-react";

import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../../utils/constants";
import {RecordProcessStepStatus} from "@axys-lab/smart-report-shared";
import {EmotionRecognition} from "@axys-lab/emotion-shared";
import {ConnectedEmotionRecognitionChart} from "../../../../containers/connected-emotion-recognition-chart";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

export const EmotionRecognitionPanel = (props: {
    status: RecordProcessStepStatus | null | undefined
    emotionRecognition: EmotionRecognition | null | undefined
    startAt: (time: number) => void
    currentTime: number
}): JSX.Element => {
    const {status, emotionRecognition, startAt, currentTime} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    return (
        <div className="emotion">
            {status === RecordProcessStepStatus.STARTED ? (
                <Dimmer page={false} active={true} inverted={true}>
                    <Loader inverted={true}>{t("record_emotion_started")}</Loader>
                </Dimmer>
            ) : status === RecordProcessStepStatus.PENDING ? (
                <Dimmer page={false} active={true} inverted={true}>
                    <Loader inverted={true}>{t("record_emotion_pending")}</Loader>
                </Dimmer>
            ) : status === RecordProcessStepStatus.ERROR ? (
                <Message error={true}>
                    {t("record_emotion_error")}
                </Message>
            ) : status === RecordProcessStepStatus.NEVER || !emotionRecognition ? (
                <Message info={true}>
                    {t("record_no_emotion")}
                </Message>
            ) : (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    padding: '20px'
                }}>
                    <div>
                        <Header as="h2" style={{textAlign: 'center'}}>{t("record_emotion_real_time_evaluation")}</Header>
                        <ConnectedEmotionRecognitionChart
                            height={500}
                            width={500}
                        />
                    </div>
                    <div style={{flex: 1}}>
                        <Header as="h2" style={{textAlign: 'center'}}>{t("record_emotion_time_chart")}</Header>
                        <ResponsiveContainer
                            className="valence-line-chart"
                            width="100%"
                            height={500}
                        >
                            <LineChart data={emotionRecognition.timings}>
                                <CartesianGrid/>

                                <XAxis
                                    domain={[-0.5, 0.5]}
                                    type="number"
                                    dataKey="time"
                                    name={t("record_emotion_time")}
                                    interval="preserveStartEnd"
                                />
                                <YAxis
                                    domain={[-0.5, 0.5]}
                                    type="number"
                                    interval="preserveStartEnd"
                                />
                                <Tooltip/>
                                <Legend/>
                                <ReferenceLine x={currentTime} stroke="red"/>
                                <Line
                                    type="monotone"
                                    dataKey="arousal"
                                    stroke="#ff6e00"
                                    activeDot={{
                                        // @ts-expect-error TODO fix typing
                                        onClick: (props, event) => startAt(event.payload.time)
                                    }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="valence"
                                    stroke="#668cff"
                                    activeDot={{
                                        // @ts-expect-error TODO fix typing
                                        onClick: (props, event) => startAt(event.payload.time)
                                    }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            )}
        </div>
    );
};
