import {boolean, object, ObjectSchema} from "yup";
import {
    EmailSchema,
    FirstnameSchema,
    LastnameSchema,
    PasswordConfirmationSchema,
    PasswordSchema,
    UsernameSchema
} from "../../utils";

export type UserRegistration = {
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    useGravatar: boolean;
}

export const UserRegistrationSchema: ObjectSchema<UserRegistration> = object({
    username: UsernameSchema(),
    firstname: FirstnameSchema,
    lastname: LastnameSchema,
    email: EmailSchema(),
    password: PasswordSchema(),
    passwordConfirmation: PasswordConfirmationSchema("password_confirmation", "password"),
    useGravatar: boolean().defined()
}).required();


