import {connect} from "react-redux";
import {State} from "../reducers";
import {EditButton} from "../views/record-view/record-view-panel/actions-bar/edit-button";
import {RECORD_EDIT_CANCEL, RECORD_EDIT_START} from "../ducks/operations/records/record-view/edit";
import {Record, RECORD_UPDATE} from "@axys-lab/smart-report-shared";

export const ConnectedRecordEditButton = connect(
    (state: State) => ({
        display: !!state.operations.recordView.data.record && !state.operations.recordView.uploadOp.started,
        editing: state.operations.recordView.editOp.data.editing,
        originalRecord: state.operations.recordView.data.record,
        draftRecord: state.operations.recordView.editOp.data.draft
    }),
    (dispatch) => ({
        onEdit: (record: Record | null) => {
            if (record) {
                dispatch(RECORD_EDIT_START(record))
            }
        },
        onSave: (record: Record | null) => {
            if (record) {
                dispatch(RECORD_UPDATE({
                    recordId: record.recordId,
                    name: record.name,
                    recordType: record.type,
                    allowUsageinTrainingDataset: record.allowUsageinTrainingDataset,
                    transcript: record.data.transcription.result?.transcript
                }));
            }
        },
        onCancel: () => {
            dispatch(RECORD_EDIT_CANCEL({}))
        }
    }),
    (stateProps, dispatchProps, ownProps: { displayText: boolean }) => ({
        displayText: ownProps.displayText,
        display: stateProps.display,
        editing: stateProps.editing,
        onSave: () => dispatchProps.onSave(stateProps.draftRecord),
        onCancel: dispatchProps.onCancel,
        onEdit: () => dispatchProps.onEdit(stateProps.originalRecord)
    })
)((props: {
    displayText: boolean,
    display: boolean
    editing: boolean
    onEdit: () => void,
    onSave: () => void,
    onCancel: () => void,
}) => (
    props.display ? (
        <EditButton {...props} />
    ) : (
        <></>
    )
));