import {connect} from "react-redux";
import {State} from "../reducers";
import {EmotionRecognitionMenuBox} from "../views/record-view/record-view-panel/emotion-recognition-menu-box";

export const ConnectedEmotionRecognitionMenuBox = connect(
    (state: State) => ({
        emotionRecognition: state.operations.recordView.data.record?.data.emotion.result,
        status: state.operations.recordView.data.record?.data.emotion.status,
    }),
    () => ({})
)(EmotionRecognitionMenuBox);