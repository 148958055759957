import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {RECORD_CREATE, RECORD_CREATE_ERROR, RECORD_CREATE_SUCCESS} from "@axys-lab/smart-report-shared";

export const recordCreate = operation(
    [{
        remote: true,
        sent: RECORD_CREATE,
        success: RECORD_CREATE_SUCCESS,
        errors: RECORD_CREATE_ERROR,
        timeout: {
            trigger: () => RECORD_CREATE_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
        }
    }],
    LOCATION_CHANGED,
);