import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import {Button, Icon, Popup, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {Feature, RecordProcessStepStatus, SearchedRecord} from "@axys-lab/smart-report-shared";
import {useFeaturesEnabled} from "../../../containers/features-provider";


export const recordStatusCellQualifier = (status: RecordProcessStepStatus): {
    positive?: boolean,
    negative?: boolean,
    warning?: boolean,
    disabled?: boolean
} => {
    switch (status) {
        case RecordProcessStepStatus.DONE:
            return {positive: true};
        case RecordProcessStepStatus.STARTED:
            return {warning: true};
        case RecordProcessStepStatus.ERROR:
            return {negative: true};
        case RecordProcessStepStatus.PENDING:
            return {warning: true};
        case RecordProcessStepStatus.NEVER:
        default:
            return {disabled: true};
    }
}

export const recordStatusCellIcon = (status: RecordProcessStepStatus): JSX.Element => {
    switch (status) {
        case RecordProcessStepStatus.DONE:
            return <Icon name="checkmark"/>;
        case RecordProcessStepStatus.NEVER:
            return <Icon name="x"/>;
        case RecordProcessStepStatus.STARTED:
            return <Icon name="hourglass half" loading={true}/>;
        case RecordProcessStepStatus.PENDING:
            return <Icon name="clock"/>;
        case RecordProcessStepStatus.ERROR:
            return <Icon name="warning circle"/>;
        default:
            return <Icon name="question"/>;
    }
}

export const RecordStatusCell = (props: { status: RecordProcessStepStatus }): JSX.Element => {
    const {status} = props;
    return (
        <Table.Cell textAlign="center"
                    {...recordStatusCellQualifier(status)}
        >
            {recordStatusCellIcon(status)}
        </Table.Cell>
    );
};

export const RecordRow = (props: SearchedRecord): JSX.Element => {
    const {
        recordId,
        creationTimestamp,
        recordTimestamp,
        recordName,
        registration,
        transcription,
        rida,
        emotion,
        recordType
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Table.Row>
            <Table.Cell>{recordName}</Table.Cell>
            <Table.Cell textAlign="center">{t(recordType)}</Table.Cell>
            <Table.Cell textAlign="center">{new Date(creationTimestamp).toLocaleString()}</Table.Cell>
            <Table.Cell textAlign="center">{new Date(recordTimestamp).toLocaleString()}</Table.Cell>
            <RecordStatusCell status={registration}/>
            <RecordStatusCell status={transcription}/>
            {useFeaturesEnabled(Feature.RIDA) ? (
                <RecordStatusCell status={rida}/>
            ) : (<></>)}
            {useFeaturesEnabled(Feature.EMOTION_RECOGNITION) ? (
                <RecordStatusCell status={emotion}/>
            ) : (<></>)}
            <Table.Cell textAlign="center">
                <Button as={Link} to={`/records/${recordId}`} labelPosition="left" icon={true} color={PRIMARY_COLOR}
                        compact={true}>
                    <Icon name="folder open outline"/>
                    {t("record_view")}
                </Button>
            </Table.Cell>
        </Table.Row>
    );
}

export const RecordTable = (props: {
    records: SearchedRecord[]
}): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {records} = props;
    return (
        <Table striped={true} selectable={true} className="records-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t("record_name")}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{t("record_type")}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{t("record_creation_date")}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{t("record_date")}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <Popup content={t("record_registration")}
                               trigger={<Icon size="big" name="file audio outline"/>}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <Popup content={t("record_transcription")}
                               trigger={<Icon size="big" name="file alternate outline"/>}
                        />
                    </Table.HeaderCell>
                    {useFeaturesEnabled(Feature.RIDA) ? (
                        <Table.HeaderCell textAlign="center">
                            <Popup content={t("record_rida")}
                                   trigger={<Icon size="big" name="tasks"/>}
                            />
                        </Table.HeaderCell>
                    ) : (<></>)}
                    {useFeaturesEnabled(Feature.EMOTION_RECOGNITION) ? (
                        <Table.HeaderCell textAlign="center">
                            <Popup content={t("record_emotion")}
                                   trigger={<Icon size="big" name="heartbeat"/>}
                            />
                        </Table.HeaderCell>
                    ) : (<></>)}
                    <Table.HeaderCell/>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {records.map(record => (
                    <RecordRow key={record.recordId} {...record} />
                ))}
            </Table.Body>
        </Table>
    );
}