import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {SocialProvidersForm} from "../views/user-profile/social-providers-form";
import {State} from "../reducers";
import {
    Feature,
    SocialProvider,
    SocialProviders,
    USER_SOCIAL_LINK,
    USER_SOCIAL_LINK_CANCEL,
    USER_SOCIAL_LINK_PREPARE,
    USER_SOCIAL_LINK_SUCCESS,
    USER_SOCIAL_UNLINK,
    UserSocialLoginAuthorized,
    UserSocialLoginSuccess,
    UserSocialProvider
} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.socialAccountLink.status,
    remoteErrors: state.operations.socialAccountLink.errors,
    providers: Object.keys(SocialProviders).reduce(
        (accumulator, value) => {
            return {
                ...accumulator,
                [value]: {
                    linked: state.user.token && state.user.token.value.user.providers[value as SocialProvider]?.activated,
                    enabled: state.operations.features.features.includes(SocialProviders[value as SocialProvider])
                }
            };
        },
        {} as {[key in SocialProvider]?: {
            enabled: boolean
            linked: boolean
        }}
    ),
    localAccount: {
        created: state.user.token && state.user.token.value.user.providers.local?.activated || false,
        enabled: state.operations.features.features.includes(Feature.LOCAL_ACCOUNT)
    },
    url: `${(process.env.REACT_APP_BACKEND_HOST as string)}/api/auth`,
    token: state.user.token ? state.user.token.serialized : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSocialLinkPrepare: (provider: SocialProvider, socialLoginId: string) => dispatch(USER_SOCIAL_LINK_PREPARE({
        provider,
        socialLoginId
    })),
    onSocialLinkAuthorized: (socialLinkAuthorized: UserSocialLoginAuthorized) =>
        dispatch(USER_SOCIAL_LINK(socialLinkAuthorized)),
    onSocialLinkSuccess: (socialLoginSuccess: UserSocialLoginSuccess) =>
        dispatch(USER_SOCIAL_LINK_SUCCESS(socialLoginSuccess)),
    onSocialUnlink: (provider: UserSocialProvider) =>
        dispatch(USER_SOCIAL_UNLINK(provider)),
    onSocialLinkCancel: (provider: UserSocialProvider) =>
        dispatch(USER_SOCIAL_LINK_CANCEL(provider))
});

export const ConnectedSocialProvidersForm =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SocialProvidersForm);