import * as jwt from "jsonwebtoken";
import {Jwt, LoggedUser, SerializedJWT} from "../interfaces";

export const ISSUER = "smart-report";
export const ALGORITHM = "HS512";

export type RawToken = LoggedUser & {
    exp: number;
    iat: number;
}

export const parseRaw = (token: RawToken): Jwt => ({
    expireAt: new Date(token.exp * 1000),
    issuedAt: new Date(token.iat * 1000),
    user: {
        username: token.username,
        email: token.email,
        userId: token.userId,
        avatar: token.avatar,
        shouldValidateEmail: token.shouldValidateEmail,
        providers: token.providers,
        firstName: token.firstName,
        lastName: token.lastName,
        roles: token.roles
    }
});

export const generateJWT = (user: LoggedUser, secret: string, expiresIn: number): Promise<SerializedJWT> => {
    return new Promise<SerializedJWT>((resolve, reject) => {
        jwt.sign(user, secret, {
            issuer: ISSUER,
            algorithm: ALGORITHM,
            expiresIn
        }, (err, encoded) => {
            if (err) {
                reject(err);
            } else if (!encoded) {
                reject("invalid token");
            } else {
                resolve(encoded)
            }
        });
    })
};

export const decodeJWT = (token: SerializedJWT): Jwt => {
    const decoded = jwt.decode(token, {
        json: true
    }) as RawToken;
    return parseRaw(decoded);
};

export const decodeAndValidateJWT = (token: SerializedJWT, secret: string): Promise<Jwt> => {
    return new Promise<Jwt>((resolve, reject) => {
        jwt.verify(token, secret, {
            issuer: ISSUER,
            algorithms: [ALGORITHM],
        }, (err, decoded) => {
            if (err) {
                reject(err);
            } else {
                resolve(parseRaw(decoded as RawToken))
            }
        });
    });
};
