
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps, withRouter } from "react-router";
import { State } from "../reducers";

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps>;
    loggedPath: string;
    logged: boolean;
}

type Props = PrivateRouteProps & RouteComponentProps;

const RenderLoggedRedirect = (props: Props) => {
    return (
        <Redirect
            exact={true}
            to={{
                pathname: props.loggedPath,
                state: {
                    from: props.location
                }
            }}
        />
    );
};

const UnderlyingVisitorRoute = (props: Props) => {
    const {component: Component, logged, ...rest} = props;
    return (
        <Route
            {...rest}
            render={p =>
                logged ? (
                    <RenderLoggedRedirect {...props} {...p} />
                ) : (
                    <Component  {...p} />
                )
            }
        />
    );
};

const mapStateToProps = (state: State) => ({
    logged: !!state.user.token
});

export const VisitorRoute = withRouter(connect(mapStateToProps)(UnderlyingVisitorRoute));