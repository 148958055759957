import {connect} from "react-redux";
import {Action} from "redux";
import {State} from "../reducers";
import {SERVER_URL} from "../utils/constants";
import {CURRENT_TIME} from "../ducks/operations/records/record-view";
import {RecordProcessStepStatus} from "@axys-lab/smart-report-shared";
import {RecordPlayback} from "../views/record-view/record-view-panel/record-playback";

export const ConnectedRecordPlayback = connect(
    (state: State) => ({
        url: state.operations.recordView.data.record?.data.audioUpload === RecordProcessStepStatus.DONE ?
            `${SERVER_URL}/api/records/${state.operations.recordView.data.record.recordId}/media` : null,
        startAt: state.operations.recordView.data.startAt,
    }),
    (dispatch: (action: Action) => void) => ({
        sendCurrentTime: (currentTime: number) => {
            dispatch(CURRENT_TIME({currentTime}));
        }
    })
)(RecordPlayback);