import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, Image } from "semantic-ui-react";
import { TRANSLATIONS_NAMESPACE } from "../../utils/constants";

import "./style.css";

export const UserCard = (props: {
    avatar: string;
    username: string;
    logout: () => void;
}): JSX.Element => {
    const {avatar, username, logout} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Card className="user-profile-card">
            <Card.Content>
                {
                    avatar &&
                    <Image circular={true} floated="right" size="mini" src={avatar}/>
                }
                <Card.Header>{username}</Card.Header>
                {/*<Card.Meta>Friends of Toto</Card.Meta>*/}
                <Card.Meta>
                    <Link to="/me">{t("user_profile")}</Link> - <a href="#logout" onClick={(e) => {
                        e.preventDefault();
                        logout();
                }}>{t("logout")}</a>
                </Card.Meta>
            </Card.Content>
        </Card>
    );
};
