import {Layout} from "../../components/layout";
import {InnerLayout} from "../../components/inner-layout";
import {useParams} from "react-router-dom";
import {ConnectedRecordViewPanel} from "../../containers/connected-record-view-panel";
import {ConnectedRecordViewDetailsMenu} from "../../containers/connected-record-view-details-menu";
import {ConnectedRecordViewHeader} from "../../containers/connected-record-view-header";

import "./index.css";
import {useFeaturesEnabled} from "../../containers/features-provider";
import {Feature} from "@axys-lab/smart-report-shared";
import {ConnectedEmotionRecognitionMenuBox} from "../../containers/connected-emotion-recognition-menu-box";

export const ViewRecord = (): JSX.Element => {
    const {id} = useParams<{ id: string }>();
    return (
        <Layout>
            <InnerLayout
                header={
                    <ConnectedRecordViewHeader recordId={id}/>
                }
                menuBox={
                    <>
                        <ConnectedRecordViewDetailsMenu recordId={id}/>
                        {/*<ConnectedRecordViewSettingsMenu recordId={id}/>*/}
                        {useFeaturesEnabled(Feature.EMOTION_RECOGNITION) ? (
                            <ConnectedEmotionRecognitionMenuBox/>
                        ) : (<></>)}
                    </>
                }
            >
                <div className="record-view">
                    <ConnectedRecordViewPanel recordId={id}/>
                </div>
            </InnerLayout>
        </Layout>
    );
};
