import { Dimmer, Loader as UnderlyingLoader } from "semantic-ui-react";

import "./style.css";

export const Loader = (props: {
    page?: boolean
    display: boolean,
    message: string
}): JSX.Element => {
    const {page, display, message} = props;
    return (
        <Dimmer active={display} inverted={true} blurring="true" page={page === undefined ? true : page}>
            <UnderlyingLoader size="huge">
                {message}
            </UnderlyingLoader>
        </Dimmer>
    );
};
