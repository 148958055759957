import {ConnectedRecordDeleteButton} from "../../../../containers/connected-record-delete-button";
import {ConnectedRecordExportButton} from "../../../../containers/connected-record-export-button";
import {useFeaturesEnabled} from "../../../../containers/features-provider";
import {ConnectedRecordEditButton} from "../../../../containers/connected-record-edit-button";
import {Feature} from "@axys-lab/smart-report-shared";
import "./style.css";
import {useWindowSize} from "react-use";

export const ActionsBar = (): JSX.Element => {
    const {width} = useWindowSize();
    const displayText = width > 1420;
    return (
        <div className="record-view-action-bar">
            {useFeaturesEnabled(Feature.EXPORT) ? (
                <ConnectedRecordExportButton displayText={displayText} />
            ) : (<></>)}
            {useFeaturesEnabled(Feature.EDIT) ? (
                <ConnectedRecordEditButton displayText={displayText} />
            ) : (<></>)}
            <ConnectedRecordDeleteButton displayText={displayText} />
        </div>
    )
};