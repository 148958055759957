
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Confirm, Grid, Header, Label, Message, Segment} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {FormMessages, RemoteCommandProps} from "../../utils/form";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {SocialProviderColorSchemes} from "../../utils/social-providers-icons";
import {
    SerializedJWT,
    SocialProvider,
    UserSocialLoginAuthorized,
    UserSocialProvider
} from "@axys-lab/smart-report-shared";
import {SocialLogin} from "../../components/login-form/social-login";

type SharedProps = RemoteCommandProps & {
    onSocialLinkPrepare: (provider: SocialProvider, socialLoginId: string) => void,
    onSocialLinkAuthorized: (socialLoginAuthorized: UserSocialLoginAuthorized) => void;
    onSocialLinkCancel: (provider: UserSocialProvider) => void;
    onSocialUnlink: (provider: UserSocialProvider) => void;
    url: string;
    token?: SerializedJWT;
};

export const SocialProviderSegment = (props: SharedProps & {
    provider: SocialProvider;
    linked: boolean;
    onlyProvider: boolean;
}): JSX.Element => {
    const {
        remoteStatus,
        remoteErrors,
        provider,
        linked,
        onSocialLinkAuthorized,
        onSocialLinkPrepare,
        onSocialUnlink,
        onSocialLinkCancel,
        url,
        onlyProvider
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    const [unlinkConfirmationVisible, setUnlinkConfirmationVisible] = useState(false);

    return (
        <Segment
            key={provider}
            attached={true}
            loading={remoteStatus === RemoteCommandStatus.PENDING}
            className="social-provider-link"
        >
            <Grid>
                <Grid.Column width={8} verticalAlign="middle">
                    <strong>{t(provider)}:</strong>
                    <Label
                        horizontal={true}
                        color={linked ? "green" : "grey"}
                    >
                        {t(linked ? "linked" : "not_linked")}
                    </Label>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    {
                        linked ? (
                            <>
                                <Button
                                    onClick={() => setUnlinkConfirmationVisible(true)}
                                    disabled={
                                        remoteStatus === RemoteCommandStatus.PENDING ||
                                        onlyProvider
                                    }
                                >
                                    {t("unlink")}
                                </Button>
                                <Confirm
                                    open={unlinkConfirmationVisible}
                                    content={t("unlink_confirm")}
                                    onCancel={() => setUnlinkConfirmationVisible(false)}
                                    onConfirm={() => {
                                        setUnlinkConfirmationVisible(false);
                                        onSocialUnlink({provider});
                                    }}
                                />
                            </>
                        ) : (
                            <SocialLogin
                                key={provider}
                                provider={provider}
                                url={`${String(url)}/${String(provider)}`}
                                colorScheme={SocialProviderColorSchemes[provider]}
                                buttonText={`link_with_${String(provider)}`}
                                disabled={remoteStatus === RemoteCommandStatus.PENDING}
                                forcedClose={remoteErrors && remoteErrors.length > 0}
                                onOpen={onSocialLinkPrepare}
                                onAuthorized={onSocialLinkAuthorized}
                                onUnexpectedClose={() => {
                                    onSocialLinkCancel({provider})
                                }}
                            />
                        )
                    }
                </Grid.Column>
            </Grid>

        </Segment>
    );
};

export const SocialProvidersForm = (props: SharedProps & {
    localAccount: {
        enabled: boolean
        created: boolean
    }
    remoteErrors: string[]
    providers: {
        [key in SocialProvider]?: {
            enabled: boolean
            linked: boolean
        }
    },
}): JSX.Element => {
    const {remoteErrors} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const errorMessages = remoteErrors && remoteErrors.length > 0 ?
        [{
            key: "social-linking-error",
            error: true,
            header: "social_account_link_error",
            list: remoteErrors
        }] :
        [];
    const {localAccount, providers, ...sharedProps} = props;
    const enabledProviders = Object.keys(providers)
        .filter(provider => providers[provider as SocialProvider]?.enabled);
    const linkedEnabledProviders = enabledProviders.filter(provider => providers[provider as SocialProvider]?.linked)
    return (
        <Segment.Group stacked={true}>
            <Header as="h2" attached="top">
                {t("social_account_link")}
            </Header>
            <FormMessages
                messages={errorMessages}
                attached={true}
            />
            <Message attached={true} info={true}>
                {t("social_account_link_description")}
            </Message>

            {
                enabledProviders
                    .map(key => {
                        const provider: SocialProvider = key as SocialProvider;
                        return (
                            <SocialProviderSegment
                                {...sharedProps}
                                provider={provider}
                                linked={providers[provider]?.linked || false}
                                key={provider}
                                onlyProvider={linkedEnabledProviders.length === 1 && (!localAccount.enabled || !localAccount.created)}
                            />
                        )
                    })
            }

        </Segment.Group>
    );
};