import {connect} from "react-redux";
import {Action} from "redux";
import {State} from "../reducers";
import {RECORD_REMOVE} from "@axys-lab/smart-report-shared";
import {DeleteButton} from "../views/record-view/record-view-panel/actions-bar/delete-button";
import {RemoteCommandStatus} from "../utils/remote-command-status";

export const ConnectedRecordDeleteButton = connect(
    (state: State) => ({
        recordId: state.operations.recordView.data.record?.recordId || "",
        status: state.operations.recordView.removeOp.status,
        display: !!state.operations.recordView.data.record && !state.operations.recordView.uploadOp.started
    }),
    (dispatch: (action: Action) => void) => ({
        onSubmit: (recordId: string) => {
            dispatch(RECORD_REMOVE({
                recordId
            }));
        }
    })
)((props: {
    recordId: string,
    status: RemoteCommandStatus,
    displayText: boolean,
    display: boolean,
    onSubmit: (recordId: string) => void
}) => (
    props.display ? (
        <DeleteButton {...props} />
    ) : (
        <></>
    )
));