import {number, object, ObjectSchema, string} from "yup";
import {RecordType} from "./record-type";
import {RecordTypeSchema} from "../../utils";

export type RecordsSearch = {
    query?: string;
    recordType?: RecordType;
    page: number;
}

export const RecordsSearchSchema: ObjectSchema<RecordsSearch> = object({
    query: string().trim(),
    recordType: RecordTypeSchema.optional(),
    page: number()
        .min(1)
        .required("page_required")
}).required();
