import {ActivationStrategyName} from "./activation-strategy-name";
import {array, Lazy, lazy, object, ObjectSchema, string} from "yup";

export type ActivationStrategy<N extends ActivationStrategyName = ActivationStrategyName> = {
    name: N;
}

export type ActivationStrategyWithParams<P = unknown, N extends ActivationStrategyName = ActivationStrategyName> =
    ActivationStrategy<N>
    & {
    parameters: P;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OffStrategy extends ActivationStrategy<ActivationStrategyName.OFF> {
}

export const OffStrategySchema: ObjectSchema<OffStrategy> = object({
    name: string<ActivationStrategyName.OFF>().defined()
}).required();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OnStrategy extends ActivationStrategy<ActivationStrategyName.ON> {
}

export const OnStrategySchema: ObjectSchema<OnStrategy> = object({
    name: string<ActivationStrategyName.ON>().defined(),
}).required();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RoleStrategy extends ActivationStrategyWithParams<{ role: string[] }, ActivationStrategyName.ROLE> {
}

export const RoleStrategySchema: ObjectSchema<RoleStrategy> = object({
    name: string<ActivationStrategyName.ROLE>().defined(),
    parameters: object({
        role: array(string().required()).required()
    }).required()
}).required()

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserStrategy extends ActivationStrategyWithParams<{ userId: string[] }, ActivationStrategyName.USER> {
}

export const UserStrategySchema: ObjectSchema<UserStrategy> = object({
    name: string<ActivationStrategyName.USER>().defined(),
    parameters: object({
        userId: array(string().required()).required()
    }).required()
}).required()


export const ActivationStrategySchema: Lazy<ActivationStrategy> = lazy((value: ActivationStrategy | null) => {
    if (!value?.name) {
        return object({
            name: string<ActivationStrategyName>().defined(),
            parameters: string()
        }).required();
    }
    switch (value.name) {
        case ActivationStrategyName.ON:
            return OnStrategySchema;
        case ActivationStrategyName.OFF:
            return OffStrategySchema;
        case ActivationStrategyName.ROLE:
            return RoleStrategySchema;
        case ActivationStrategyName.USER:
            return UserStrategySchema;
    }
})