import {object, ObjectSchema, string} from "yup";
import {SocialProviderSchema} from "../../utils";
import {UserSocialProvider} from "./user-social-provider";

export type UserSocialLoginAuthorized = UserSocialProvider & {
    code: string;
    state: string;
    cookies: Record<string, unknown>
}

export const UserSocialLoginAuthorizedSchema: ObjectSchema<UserSocialLoginAuthorized> = object({
    code: string()
        .required("code_required"),
    state: string()
        .required("state"),
    cookies: object<Record<string, unknown>>().required("cookies"),
    provider: SocialProviderSchema()
}).required();