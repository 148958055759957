import {Feature, Record, RecordProcessStep, RecordProcessStepStatus, RecordType} from "@axys-lab/smart-report-shared";
import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {RemoteCommandStatus} from "../../../utils/remote-command-status";
import {Header, Icon, Menu, Message, Popup, Segment, Tab, Visibility} from "semantic-ui-react";
import {Loader as InternalLoader} from "../../../components/loader";

import "./index.css";
import {ConnectedTranscriptPanel} from "../../../containers/connected-transcript-panel";
import {ActionsBar} from "./actions-bar";
import {ConnectedRecordDeleteButton} from "../../../containers/connected-record-delete-button";
import {ConnectedRecordPlayback} from "../../../containers/connected-record-playback";
import {useEffect} from "react";
import {useNumber} from "react-use";
import {ConnectedRIDAPanel} from "../../../containers/connected-rida-panel";
import {useFeaturesEnabled} from "../../../containers/features-provider";
import {ConnectedReprocessButton} from "../../../containers/connected-reprocess-button";
import {RecordFileSelector} from "./record-file-selector";
import {ConnectedEmotionRecognitionPanel} from "../../../containers/connected-emotion-recognition-panel";

export const RecordViewPanel = (props: {
    record: Record | null,
    status: RemoteCommandStatus,
    errors: string[],
}): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {record, status, errors} = props;
    const [playbackBottom, playbackBottomActions] = useNumber(0);

    const setPlaybackPosition = () => {
        const footerSize = (document.querySelector(".layout-footer") as HTMLElement)?.offsetHeight;
        const pixelsLeft = document.documentElement.scrollHeight - (window.scrollY + window.innerHeight);
        if (pixelsLeft <= footerSize) {
            playbackBottomActions.set((footerSize + 5) - pixelsLeft);
        } else {
            playbackBottomActions.set(0);
        }
    }

    useEffect(() => {
        setPlaybackPosition()
    }, [record]);

    return (
        <Visibility onUpdate={setPlaybackPosition} className="record-view-panel">
            <>
                {status === RemoteCommandStatus.PENDING ? (
                    <InternalLoader display={true} message={t("record_loading")}/>
                ) : status === RemoteCommandStatus.ERROR ? (
                    <ErrorPanel errors={errors}/>
                ) : !record ? (
                    <ErrorPanel errors={["record_not_found"]}/>
                ) : record.data.audioUpload === RecordProcessStepStatus.NEVER ? (
                    <UploadPanel/>
                ) : (
                    <ContentPanel record={record}/>
                )}
            </>
            {record?.data.audioUpload === RecordProcessStepStatus.DONE ? (
                <ConnectedRecordPlayback style={
                    playbackBottom > 0 ? {
                        bottom: playbackBottom
                    } : {}
                }/>
            ) : (<></>)
            }
        </Visibility>
    );
};

export const ErrorPanel = (props: { errors: string[] }): JSX.Element => {
    const {errors} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Segment className="record-view-error">
            <Message
                negative={true}
                list={errors.map(error => t(error))}
            />
        </Segment>
    )
};

export const UploadPanel = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <>
            <Segment basic={true} className="record-view-upload-header">
                <Header as="h3" textAlign="center">{t("record_upload_audio")}</Header>
                <div className="record-pre-remove">
                    <ConnectedRecordDeleteButton displayText={true}/>
                </div>
            </Segment>
            <Segment className="record-view-upload">
                <RecordFileSelector/>
            </Segment>
        </>
    )
};

export const ContentPanel = (props: { record: Record }): JSX.Element => {
    const {record} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    const panes = []

    const transcriptionOverallConfidence = record.data.transcription.result?.overallConfidence ?
        0.4 + 0.6 * record.data.transcription.result?.overallConfidence :
        undefined;

    panes.push({
        menuItem: (
            <Menu.Item key='transcription'>
                {t("record_transcription")}
                {transcriptionOverallConfidence ? (
                    <Popup
                        content={t("record_overall_confidence", {confidence: `${transcriptionOverallConfidence * 100}%`})}
                        trigger={
                            transcriptionOverallConfidence >= 0.70 ?
                                <Icon name="thermometer full" color="green" size="large"/> :
                                transcriptionOverallConfidence >= 0.50 ?
                                    <Icon name="thermometer three quarters" color="yellow" size="large"/> :
                                    transcriptionOverallConfidence >= 0.30 ?
                                        <Icon name="thermometer half" color="orange" size="large"/> :
                                        transcriptionOverallConfidence >= 0.10 ?
                                            <Icon name="thermometer quarter" color="red" size="large"/> :
                                            <Icon name="thermometer empty" size="large"/>
                        }
                    />
                ) : <></>}
                {record.data.transcription.status !== RecordProcessStepStatus.PENDING &&
                record.data.transcription.status !== RecordProcessStepStatus.STARTED &&
                record.data.rida.status !== RecordProcessStepStatus.STARTED &&
                record.data.rida.status !== RecordProcessStepStatus.PENDING ? (
                    <ConnectedReprocessButton processStep={RecordProcessStep.ASR}/>
                ) : <></>}
            </Menu.Item>
        ),
        // eslint-disable-next-line react/display-name
        render: () => (
            <Tab.Pane>
                <ConnectedTranscriptPanel/>
            </Tab.Pane>
        )
    });

    if (useFeaturesEnabled(Feature.RIDA)) {
        panes.push({
            menuItem: (
                <Menu.Item key="rida" disabled={record.type !== RecordType.MEETING}>
                    {t("record_rida")}
                    {record.type === RecordType.MEETING &&
                    record.data.transcription.result?.transcript &&
                    record.data.rida.status !== RecordProcessStepStatus.PENDING &&
                    record.data.rida.status !== RecordProcessStepStatus.STARTED &&
                    record.data.transcription.status === RecordProcessStepStatus.DONE ? (
                        <ConnectedReprocessButton processStep={RecordProcessStep.RIDA}/>
                    ) : <></>}
                </Menu.Item>
            ),
            // eslint-disable-next-line react/display-name
            render: () => (
                <Tab.Pane>
                    <ConnectedRIDAPanel/>
                </Tab.Pane>
            )
        })
    }

    if (useFeaturesEnabled(Feature.EMOTION_RECOGNITION)) {
        panes.push({
            menuItem: (
                <Menu.Item key="emotion">
                    {t("record_emotion")}
                    {record.data.emotion.status !== RecordProcessStepStatus.PENDING &&
                    record.data.emotion.status !== RecordProcessStepStatus.STARTED ? (
                        <ConnectedReprocessButton processStep={RecordProcessStep.EMOTION_RECOGNITION}/>
                    ) : <></>}
                </Menu.Item>
            ),
            // eslint-disable-next-line react/display-name
            render: () => (
                <Tab.Pane>
                    <ConnectedEmotionRecognitionPanel/>
                </Tab.Pane>
            )
        })
    }

    panes.push({
        key: "actions-bar",
        menuItem: (
            <ActionsBar key="actions-bar"/>
        )
    })

    return (
        <>
            <Tab
                className="record-view-details"
                panes={panes}
            />
        </>
    )
};