import {Confirm, Icon, Popup} from "semantic-ui-react";
import {RecordProcessStep} from "@axys-lab/smart-report-shared";
import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {useBoolean} from "react-use";

export const ReprocessButton = (props: {
    processStep: RecordProcessStep,
    recordId: string,
    onSubmit: (recordId: string, processStep: RecordProcessStep) => void
}): JSX.Element => {
    const [on, toggle] = useBoolean(false);
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {recordId, processStep, onSubmit} = props;
    return (
        <>
        <Popup
            content={t("reprocess")}
            trigger={
                <Icon
                    bordered={true}
                    circular={true}
                    style={{cursor: "pointer", marginLeft: 10}}
                    name="redo"
                    onClick={() => toggle(true)}
                />
            }
        />
            <Confirm
                open={on}
                content={t("reprocess_confirm")}
                cancelButton={t("cancel")}
                confirmButton={t("confirm")}
                onCancel={() => toggle(false)}
                onConfirm={() => {
                    onSubmit(recordId, processStep);
                    toggle(false);
                }}
            />
        </>
    )
}