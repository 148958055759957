import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {
    USER_PASSWORD_LOST_REQUEST,
    USER_PASSWORD_RESET,
    USER_PASSWORD_RESET_ERROR,
    USER_PASSWORD_RESET_SUCCESS
} from "@axys-lab/smart-report-shared";

export const passwordReset = operation(
    [{
        remote: true,
        sent: USER_PASSWORD_RESET,
        success: USER_PASSWORD_RESET_SUCCESS,
        errors: USER_PASSWORD_RESET_ERROR,
        timeout: {
            trigger: () => USER_PASSWORD_RESET_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
        }
    }],
    [LOCATION_CHANGED, USER_PASSWORD_LOST_REQUEST],
);
