import {ReactNode, useState} from "react";
import {Icon, Segment} from "semantic-ui-react";
import {DesktopInnerLayout} from "./desktop-inner-layout";
import {useWindowSize} from "react-use";
import "./style.css";
import {MobileInnerLayout} from "./mobile-inner-layout";

export const InnerLayoutSideBarTrigger = (props: { onClick: () => void }): JSX.Element => (
    <a
        href="#sidebar-menu"
        className="layout-inner-sidebar-trigger"
        onClick={(e) => {
            e.preventDefault();
            props.onClick();
        }}
    >
        <Icon name="bars" inverted={true} size="big"/>
    </a>
);

export const InnerLayout = (props: { header: ReactNode, children?: ReactNode, menuBox?: ReactNode }): JSX.Element => {
    const [displayBar, setDisplayBar] = useState(false);
    const {width} = useWindowSize();
    return (
        <div className="layout-inner">
            {
                props.header &&
                <Segment inverted={true} vertical={true} padded="very" className="layout-inner-header fancy-header">
                    {width >= 767 ? (<></>) : (
                        <InnerLayoutSideBarTrigger onClick={() => setDisplayBar(!displayBar)}/>
                    )}
                    {props.header}
                </Segment>
            }
            {width >= 767 ? (
                <DesktopInnerLayout menuBox={props.menuBox}>
                    {props.children}
                </DesktopInnerLayout>
            ) : (
                <MobileInnerLayout displayBar={displayBar}>
                    <div className="mobile-layout-menubox">
                        {props.menuBox}
                    </div>
                    {props.children}
                </MobileInnerLayout>
            )}
        </div>
    );
};
