import {Container, List, Segment} from "semantic-ui-react";
import axysLogo from "./images/logo-axys-blanc.png"
import "./style.css";

export const Footer = (): JSX.Element => {
    return (
        <Segment className="layout-footer" inverted={true} vertical={true}>
            <Container
                textAlign="center"
            >
                <List horizontal={true} inverted={true} divided={true}>
                    <List.Item>
                        <a href="https://www.axys-consultants.com"><img src={axysLogo}/></a>
                    </List.Item>
                </List>
            </Container>
        </Segment>
    );
};
