
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps, withRouter } from "react-router";
import { State } from "../reducers";

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps> | React.ComponentType;
    loginPath: string;
    validatePath: string;
    logged: boolean;
    shouldValidateEmail: boolean;
}

type Props = PrivateRouteProps & RouteComponentProps;

const RenderLogin = (props: Props) => {
    return (
        <Redirect
            exact={true}
            to={{
                pathname: props.loginPath,
                state: {
                    from: props.location
                }
            }}
        />
    );
};

const RenderValidateEmail = (props: Props) => {
    return (
        <Redirect
            exact={true}
            to={{
                pathname: props.validatePath,
                state: {
                    from: props.location
                }
            }}
        />
    );
};

const UnderlyingRestrictedRoute = (props: Props) => {
    const {component: Component, logged, shouldValidateEmail, ...rest} = props;
    return (
        <Route
            {...rest}
            render={p =>
                logged && !shouldValidateEmail ? (
                    <Component  {...p} />
                ) : !logged ? (
                    <RenderLogin {...props} {...p} />
                ) : (
                    <RenderValidateEmail {...props} {...p} />
                )
            }
        />
    );
};

const mapStateToProps = (state: State) => ({
    logged: !!state.user.token,
    shouldValidateEmail: !!state.user.token && state.user.token.value.user.shouldValidateEmail
});

export const RestrictedRoute = withRouter(connect(mapStateToProps)(UnderlyingRestrictedRoute));