import {ReactNode, useRef} from "react";
import {Menu, Sticky, Ref} from "semantic-ui-react";
import {ConnectedUserCard} from "../../../containers/connected-user-card";
import "./style.css";
import {MenuItems} from "../menu-items";

export const DesktopInnerLayout = (props: {
    children?: ReactNode
    menuBox?: ReactNode
}): JSX.Element => {
    const contextRef = useRef(null);
    return (
        <Ref innerRef={contextRef}>
            <div
                className="layout-inner-desktop"
            >
                <div className="layout-inner-desktop-menu">
                    <Sticky context={contextRef} offset={10}>
                        <ConnectedUserCard/>
                        <Menu
                            vertical={true}
                            size="large"
                        >
                            <MenuItems/>
                        </Menu>
                        {props.menuBox}
                    </Sticky>
                </div>
                <div className="layout-inner-desktop-content">
                    {props.children}
                </div>
            </div>
        </Ref>
    );
};