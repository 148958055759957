
import { useTranslation } from "react-i18next";
import { Container, Header, Segment } from "semantic-ui-react";
import { ConnectedEmailValidationForm } from "../../containers/connected-email-validation-form";
import { ConnectedEmailValidationResendAccordion } from "../../containers/connected-email-validation-resend-accordion";
import { TRANSLATIONS_NAMESPACE } from "../../utils/constants";
import {Layout} from "../../components/layout";

export const EmailValidation = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Layout>
            <div className="email-validation">
                <Segment inverted={true} vertical={true} padded="very" className="about-header">
                    <Container
                        textAlign="center"
                        text={true}
                    >
                        <Header
                            as="h2"
                            content={t("email_validation")}
                            inverted={true}
                            size="huge"
                        />
                    </Container>
                </Segment>
                <Segment inverted={false} vertical={true} padded="very">
                    <Container
                        textAlign="center"
                        text={true}
                    >
                        <Header
                            as="h3"
                            content={t("enter_email_validation_token")}
                            inverted={false}
                            size="large"
                        />
                        <ConnectedEmailValidationForm/>
                        <ConnectedEmailValidationResendAccordion/>
                    </Container>
                </Segment>
            </div>
        </Layout>
    );
};
