import {connect} from "react-redux";
import {Action} from "redux";
import {State} from "../reducers";
import {TranscriptPanel} from "../views/record-view/record-view-panel/transcript-panel";
import {START_AT} from "../ducks/operations/records/record-view";
import {
    RECORD_EDIT_SEGMENT_MERGE,
    RECORD_EDIT_SEGMENT_SPLIT,
    RECORD_EDIT_SEGMENT_WORDS,
    RECORD_EDIT_SPEAKER_GLOBAL,
    RECORD_EDIT_SPEAKER_SINGLE
} from "../ducks/operations/records/record-view/edit";

export const ConnectedTranscriptPanel = connect(
    (state: State) => ({
        currentTime: state.operations.recordView.data.currentTime,
        transcript: state.operations.recordView.editOp.data.editing ?
            state.operations.recordView.editOp.data.draft?.data?.transcription?.result?.transcript :
            state.operations.recordView.data.record?.data?.transcription?.result?.transcript,
        status: state.operations.recordView.data.record?.data?.transcription?.status,
        editing: state.operations.recordView.editOp.data.editing
    }),
    (dispatch: (action: Action) => void) => ({
        startAt: (currentTime: number) => {
            dispatch(START_AT({currentTime}));
        },
        onSegmentMerge: (segmentIndex: number) => {
            dispatch(RECORD_EDIT_SEGMENT_MERGE({segmentIndex}));
        },
        onSegmentSplit: (segmentIndex: number, wordIndex: number) => {
            dispatch(RECORD_EDIT_SEGMENT_SPLIT({segmentIndex, wordIndex}));
        },
        onSegmentEdit: (segmentIndex: number, words: string) => {
            dispatch(RECORD_EDIT_SEGMENT_WORDS({segmentIndex, words}))
        },
        onSingleSpeakerChange: (segmentIndex: number, speakerIdOrName: string) => {
            dispatch(RECORD_EDIT_SPEAKER_SINGLE({segmentIndex, speakerIdOrName}))
        },
        onGlobalSpeakerChange: (previousSpeakerId: string, speakerIdOrName: string) => {
            dispatch(RECORD_EDIT_SPEAKER_GLOBAL({previousSpeakerId, speakerIdOrName}))
        }
    })
)(TranscriptPanel);