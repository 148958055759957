import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {State} from "../reducers";
import {FileWithPath} from "react-dropzone";
import {RECORD_UPLOAD_CANCEL} from "@axys-lab/smart-report-shared";
import {RECORD_UPLOAD_SELECTED} from "../ducks/operations/records/record-view/upload";
import {FileUploader} from "../components/file-uploader";
import {RemoteCommandStatus} from "../utils/remote-command-status";

export const ConnectedRecordUploader = connect(
    (state: State,) => ({
        accept: {
            "audio/*": [".wav", ".mp3", ".m4a"],
            "video/mp4": [],
            "video/mpeg": []
        },
        progress: state.operations.recordView.uploadOp.progress,
        errors: state.operations.recordView.uploadOp.errors,
        started: state.operations.recordView.uploadOp.started,
        recordId: state.operations.recordView.data.record?.recordId || "",
        remoteFileDownload: state.operations.recordView.downloadRemote.status === RemoteCommandStatus.PENDING
    }), (dispatch: Dispatch<Action>) => ({
        cancel: (recordId: string) => dispatch(RECORD_UPLOAD_CANCEL({recordId: recordId})),
        upload: (recordId: string, file: FileWithPath) => dispatch(RECORD_UPLOAD_SELECTED({file, recordId: recordId}))
    }), (stateProps, dispatchProps) => ({
        accept: stateProps.accept,
        progress: stateProps.progress,
        errors: stateProps.errors,
        started: stateProps.started,
        disabled: stateProps.remoteFileDownload,
        cancel: () => dispatchProps.cancel(stateProps.recordId),
        upload: (file: FileWithPath) => dispatchProps.upload(stateProps.recordId, file)
    })
)(FileUploader);