import {OperationState} from "./common";
import {sockets, SocketsState} from "./sockets";
import {basicDetailsUpdate} from "./user/basic-details-update";
import {emailUpdate} from "./user/email-update";
import {emailValidation} from "./user/email-validation";
import {emailValidationResend} from "./user/email-validation-resend";
import {login} from "./user/login";
import {passwordLost} from "./user/password-lost";
import {passwordReset} from "./user/password-reset";
import {passwordUpdate} from "./user/password-update";
import {registration} from "./user/registration";
import {session} from "./user/session";
import {socialAccountLink} from "./user/social-account-link";
import {combineReducers} from "redux";
import {recordCreate} from "./records/record-create";
import {recordView, RecordViewState} from "./records/record-view";
import {recordsSearch, RecordsSearchState} from "./records/records-search";
import {features, FeaturesState} from "./features";
import {onedriveSettings, OneDriveSettingsState} from "./onedrive-settings";

export type OperationsState = Readonly<{
    basicDetailsUpdate: OperationState;
    emailUpdate: OperationState;
    emailValidation: OperationState;
    emailValidationResend: OperationState;
    login: OperationState;
    passwordLost: OperationState;
    passwordReset: OperationState;
    passwordUpdate: OperationState;
    registration: OperationState;
    socialAccountLink: OperationState;
    session: OperationState;
    recordCreate: OperationState;
    recordView: RecordViewState;
    recordsSearch: RecordsSearchState;
    features: FeaturesState;
    onedriveSettings: OneDriveSettingsState;
    sockets: SocketsState;
}>;

export const reducer = combineReducers({
    basicDetailsUpdate,
    emailValidation,
    emailValidationResend,
    emailUpdate,
    login,
    passwordLost,
    passwordReset,
    passwordUpdate,
    registration,
    socialAccountLink,
    session,
    recordCreate,
    recordView,
    recordsSearch,
    features,
    onedriveSettings,
    sockets,
});