import {Layout} from "../../components/layout";
import {HomeFeatures} from "./home-features";
import {HomeHeader} from "./home-header";

import "./style.css";

export const Home = (): JSX.Element => (
    <Layout hideLogo={true}>
        <div className="home">
            <HomeHeader/>
            <HomeFeatures/>
        </div>
    </Layout>
);
