import {Feature} from "../interfaces/dtos/feature";

export type LOCAL = "local";
export const LOCAL: LOCAL = "local";

export type SocialProvider = "microsoft" | "google";
export type Provider = LOCAL | SocialProvider;

export const MICROSOFT: SocialProvider = "microsoft";
export const GOOGLE: SocialProvider = "google";

export const SocialProviders: { [key in SocialProvider]: Feature } = {
    microsoft: Feature.SOCIAL_LOGIN_MICROSOFT,
    google: Feature.SOCIAL_LOGIN_GOOGLE
};

export type GRAVATAR = "gravatar";
export const GRAVATAR: GRAVATAR = "gravatar";

export type Avatar = GRAVATAR | SocialProvider;
export const Avatars: Avatar[] = [...Object.keys(SocialProviders) as SocialProvider[], GRAVATAR];

export type NONE = "none";
export const NONE: NONE = "none";