import {createAction} from "typesafe-actions";
import {ErrorResponse} from "@axys-lab/microservices-common-shared";
import {
    Record as Recording,
    RecordChunk,
    RecordChunkRequest,
    RecordCreation,
    RecordReference,
    RecordsSearch,
    RecordsSearchResponse,
    RecordUpdate,
    RecordUpload
} from "../dtos";
import {RecordReprocess} from "../dtos/record-reprocess";
import {OneDriveSettings} from "../dtos/onedrive-settings";
import {RemoteFileDownload} from "../dtos/remote-file-download";

export const RECORD_CREATE = createAction("record/CREATE")<RecordCreation>();
export const RECORD_CREATE_SUCCESS = createAction("record/CREATE/success")<RecordReference>();
export const RECORD_CREATE_ERROR = createAction("record/CREATE/error")<ErrorResponse>();

export const RECORD_GET = createAction("record/GET")<RecordReference>();
export const RECORD_GET_SUCCESS = createAction("record/GET/success")<Recording>();
export const RECORD_GET_ERROR = createAction("record/GET/error")<ErrorResponse>();

export const RECORD_UPLOAD_START = createAction("record/UPLOAD/start")<RecordUpload>();
export const RECORD_UPLOAD_CANCEL = createAction("record/UPLOAD/cancel")<RecordReference>();
export const RECORD_UPLOAD_CHUNK_REQUEST = createAction("record/UPLOAD/chunk-request")<RecordChunkRequest>();
export const RECORD_UPLOAD_CHUNK = createAction("record/UPLOAD/chunk")<RecordChunk>();
export const RECORD_UPLOAD_SUCCESS = createAction("record/UPLOAD/success")<Recording>();
export const RECORD_UPLOAD_ERROR = createAction("record/UPLOAD/error")<ErrorResponse>();

export const RECORD_SEARCH = createAction("record/SEARCH")<RecordsSearch>();
export const RECORD_SEARCH_SUCCESS = createAction("record/SEARCH/success")<RecordsSearchResponse>();
export const RECORD_SEARCH_ERROR = createAction("record/SEARCH/error")<ErrorResponse>();

export const RECORD_PROCESS = createAction("record/PROCESS")<RecordReference>();
export const RECORD_PROCESS_STARTED = createAction("record/PROCESS/started")<Recording>();
export const RECORD_PROCESS_PROGRESS = createAction("record/PROCESS/progress")<Recording>();
export const RECORD_PROCESS_SUCCESS = createAction("record/PROCESS/success")<Recording>();
export const RECORD_PROCESS_ERROR = createAction("record/PROCESS/error")<ErrorResponse>();

export const RECORD_UPDATE = createAction("record/UPDATE")<RecordUpdate>();
export const RECORD_UPDATE_SUCCESS = createAction("record/UPDATE/success")<Recording>();
export const RECORD_UPDATE_ERROR = createAction("record/UPDATE/error")<ErrorResponse>();

export const RECORD_REMOVE = createAction("record/REMOVE")<RecordReference>();
export const RECORD_REMOVE_SUCCESS = createAction("record/REMOVE/success")<RecordReference>();
export const RECORD_REMOVE_ERROR = createAction("record/REMOVE/error")<ErrorResponse>();

export const RECORD_REPROCESS_TRIGGER = createAction("record/REPROCESS/trigger")<RecordReprocess>();

export const RECORD_ONEDRIVE_SETTINGS_RETRIEVE = createAction("record/RETRIEVE/onedrive/settings")<Record<never, never>>();
export const RECORD_ONEDRIVE_SETTINGS_RETRIEVE_SUCCESS = createAction("record/RETRIEVE/onedrive/settings/success")<OneDriveSettings>();
export const RECORD_ONEDRIVE_SETTINGS_RETRIEVE_ERROR = createAction("record/RETRIEVE/onedrive/settings/error")<ErrorResponse>();

export const RECORD_REMOTE_FILE_DOWNLOAD = createAction("record/DOWNLOAD/remote-file")<RemoteFileDownload>();
export const RECORD_REMOTE_FILE_DOWNLOAD_SUCCESS = createAction("record/DOWNLOAD/remote-file/success")<Recording>();
export const RECORD_REMOTE_FILE_DOWNLOAD_ERROR = createAction("record/DOWNLOAD/remote-file/error")<ErrorResponse>();
