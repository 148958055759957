import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {TRANSLATIONS_NAMESPACE} from "./constants";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";

export const i18n = i18next
    .use(initReactI18next)
    .use(HttpApi)
    .use(I18nextBrowserLanguageDetector)
    .init({
        fallbackLng: "fr",
        preload: ["fr"],

        // have a common namespace used around the full app
        ns: [TRANSLATIONS_NAMESPACE],
        defaultNS: TRANSLATIONS_NAMESPACE,

        debug: false, /*process.env.NODE_ENV !== "production",*/

        interpolation: {
            escapeValue: false, // not needed for react!!
        },
    });

export default i18n;