import {RecordCreation, RecordCreationSchema, RecordType} from "@axys-lab/smart-report-shared";
import ReactDatePicker, {registerLocale} from "react-datepicker";
import {FormikProps} from "formik";

import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Segment} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {
    FieldType,
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../../utils/form";
import {RemoteCommandStatus} from "../../../utils/remote-command-status";
import {fr} from "date-fns/locale/fr";
import {enUS} from "date-fns/locale/en-US";
import {EnumValues} from "enum-values";

registerLocale("fr", fr)
registerLocale("fr-FR", fr)
registerLocale("en", enUS)
registerLocale("en-US", enUS)
registerLocale("en-GB", enUS)

type FormProps = RemoteCommandProps & {
    onCreate: (recordCreation: RecordCreation) => void;
};

type FormValueType = Omit<RecordCreation, "numberOfSpeakers"> & {
    numberOfSpeakers: number | ""
}

export const RecordCreateForm = withRemoteForm<FormProps, FormValueType>({
    validationSchema: RecordCreationSchema,
    mapPropsToValues: () => ({
        name: "",
        recordTimestamp: Date.now(),
        type: RecordType.MEETING,
        allowUsageinTrainingDataset: false,
        numberOfSpeakers: ""
    }) as FormValueType,
    handleSubmit: (values, {props}) => {
        props.onCreate({
            name: values.name,
            type: values.type,
            allowUsageinTrainingDataset: values.allowUsageinTrainingDataset,
            recordTimestamp: values.recordTimestamp,
            numberOfSpeakers: values.numberOfSpeakers ? values.numberOfSpeakers : undefined
        });
    },
    displayName: "CreateRecordForm",
})((props: FormProps
    & InjectedRemoteFormProps
    & FormikProps<FormValueType>
) => {
    const {
        values,
        setFieldValue,
        remoteStatus,
    } = props;

    const {t, i18n} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Form
            size="large"
            {...remoteFormProps(props)}
        >
            <Segment stacked={false}>
                <RemoteFormMessages
                    {...props}
                    errorHeaderMessageKey="record_creation_error"
                    successHeaderMessageKey="record_creation_success"
                    successDetailsMessageKey="record_creation_success_details"
                />
                {
                    !(remoteStatus === RemoteCommandStatus.SUCCESS) &&
                    <div>
                        <Form.Input
                            id="name"
                            fluid={true}
                            label={t("record_name")}
                            icon="volume up"
                            iconPosition="left"
                            placeholder={t("record_name")}
                            required={true}
                            {...remoteFormFieldProps(
                                props,
                                "name"
                            )}
                        />

                        <Form.Field required={true}>
                            <label>{t("record_type")}</label>
                        </Form.Field>

                        {
                            EnumValues.getValues<RecordType>(RecordType).map(recordType => (
                                <Form.Field
                                    key={recordType}
                                >
                                    <Checkbox
                                        name="record_type"
                                        radio={true}
                                        label={t(recordType)}
                                        {...remoteFormFieldProps(
                                            props,
                                            "type",
                                            FieldType.RADIO,
                                            recordType
                                        )}
                                    />
                                </Form.Field>
                            ))
                        }

                        <Form.Field required={true}>
                            <label>{t("record_date")}</label>
                        </Form.Field>
                        <Form.Field>
                            <ReactDatePicker
                                locale={i18n.language}
                                showTimeSelect={true}
                                selected={new Date(values.recordTimestamp)}
                                dateFormat="d MMMM yyyy HH:mm"
                                onChange={(date: Date) => setFieldValue("recordTimestamp", date.getTime())}
                            />
                        </Form.Field>

                        {
                            values.type === RecordType.MEETING ? (
                                <Form.Input
                                    id="numberOfSpeakers"
                                    fluid={true}
                                    label={t("number_of_speakers")}
                                    icon="users"
                                    iconPosition="left"
                                    placeholder={t("number_of_speakers")}
                                    required={false}
                                    type="number"
                                    {...remoteFormFieldProps(
                                        props,
                                        "numberOfSpeakers"
                                    )}
                                />
                            ) : (<></>)
                        }

                        {/*<Message>*/}
                        {/*    {t("record_allow_usage_in_training_dataset_explanation")}*/}
                        {/*</Message>*/}
                        {/*<Form.Field>*/}
                        {/*    <Checkbox*/}
                        {/*        id="allowUsageinTrainingDataset"*/}
                        {/*        name="record_allow_usage_in_training_dataset"*/}
                        {/*        radio={false}*/}
                        {/*        label={t("record_allow_usage_in_training_dataset")}*/}
                        {/*        {...remoteFormFieldProps(*/}
                        {/*            props,*/}
                        {/*            "allowUsageinTrainingDataset",*/}
                        {/*            FieldType.CHECKBOX,*/}
                        {/*        )}*/}
                        {/*    />*/}
                        {/*</Form.Field>*/}

                        <Button
                            color={PRIMARY_COLOR}
                            fluid={true}
                            size="large"
                            type="submit"
                            {...remoteFormSubmitProps(props)}
                        >
                            {t("create")}
                        </Button>

                    </div>
                }
            </Segment>
        </Form>
    );
});
