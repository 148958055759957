import {Avatar, Avatars, GOOGLE, LOCAL, MICROSOFT, Provider} from "../../utils";
import {array, boolean, object, ObjectSchema, string} from "yup";

export type LoggedUserProvider = {
    activated: boolean;
    username?: string;
}

export type LoggedUser = {
    userId: string;
    username: string;
    email: string;
    avatar?: {
        url: string;
        type: Avatar
    }
    providers: {
        [provider in Provider]?: LoggedUserProvider;
    }
    roles: string[];
    shouldValidateEmail: boolean;
    firstName: string;
    lastName: string;
}

export const LoggedUserProviderSchema: ObjectSchema<LoggedUserProvider> = object({
    activated: boolean().required(),
    username: string().default(undefined).optional()
})

export const LoggedUserSchema: ObjectSchema<LoggedUser> = object({
    userId: string().required(),
    username: string().required(),
    email: string().required(),
    avatar: object({
        url: string().required(),
        type: string().oneOf(Avatars).required().defined()
    }).default(undefined).optional(),
    providers: object({
        [LOCAL]: LoggedUserProviderSchema.default(undefined).optional(),
        [MICROSOFT]: LoggedUserProviderSchema.default(undefined).optional(),
        [GOOGLE]: LoggedUserProviderSchema.default(undefined).optional(),
    }).required(),
    roles: array(string().required()).required(),
    shouldValidateEmail: boolean().required(),
    firstName: string().required(),
    lastName: string().required(),
}).required()

