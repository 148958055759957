import {Button, Dropdown, DropdownProps, Form, Input, InputOnChangeData, Menu} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import {throttle} from "../../../utils/throttle";
import {ChangeEvent, SyntheticEvent, useCallback, useState} from "react";

import "./style.css";
import {RemoteCommandStatus} from "../../../utils/remote-command-status";
import {RecordType} from "@axys-lab/smart-report-shared";
import {EnumValues} from "enum-values";

type State = { query: string, recordType: RecordType | null };

export const RecordsSearchMenu = (props: {
    status: RemoteCommandStatus,
    query: string | undefined,
    recordType: RecordType | undefined,
    onSearch: (query?: string, recordType?: RecordType) => void
}): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const [state, setState] = useState<State>({query: props.query || "", recordType: props.recordType || null})
    const {query, recordType} = state;
    const onSearch = useCallback(throttle(props.onSearch, 1000), [props.onSearch]);
    const {status} = props;
    const updateQuery = useCallback((event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setState({
            ...state,
            query: data.value || ""
        })
    }, [state]);
    const updateType = useCallback((event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setState({
            ...state,
            recordType: (data.value as RecordType) || null
        })
    }, [state]);

    return (
        <form className="records-search-menu" onSubmit={(e) => {
            onSearch(query || undefined, recordType || undefined);
            e.preventDefault();
        }}>
            <Menu vertical={true}>
                <Menu.Item header={true}>
                    {t("record_search")}
                </Menu.Item>
                <Menu.Item>
                    <Form.Field>
                        <Input
                            placeholder={`${t("record_query")}...`}
                            value={query}
                            onChange={updateQuery}
                            disabled={status === RemoteCommandStatus.PENDING}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder={t("record_type")}
                            selection={true}
                            defaultValue={recordType || undefined}
                            clearable={true}
                            options={EnumValues.getValues<RecordType>(RecordType).map(recordType => ({
                                key: recordType,
                                text: t(recordType),
                                value: recordType
                            }))}
                            onChange={updateType}
                            disabled={status === RemoteCommandStatus.PENDING}
                        />
                    </Form.Field>
                    <Form.Field className="actions">
                        <Button
                            icon="search"
                            type="submit"
                            disabled={status === RemoteCommandStatus.PENDING}
                            content={t("search")}
                        />
                    </Form.Field>
                </Menu.Item>
            </Menu>
        </form>
    );
}