
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Accordion, Container, Header, Segment} from "semantic-ui-react";
import {ConnectedPasswordLostForm} from "../../containers/connected-password-lost-form";
import {ConnectedPasswordResetForm} from "../../containers/connected-password-reset-form";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {Layout} from "../../components/layout";

type Props = RouteComponentProps<{ token?: string }>;

export const LostPassword = (props: Props): JSX.Element => {
    const {match} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const displayPasswordLostForm = !match.params.token;
    const [displayPasswordResetForm, setDisplayPasswordResetForm] = useState(!!match.params.token);
    return (
        <Layout>
            <div className="lost-password">
                <Segment inverted={true} vertical={true} padded="very" className="lost-password-header">
                    <Container
                        textAlign="center"
                        text={true}
                    >
                        <Header
                            as="h2"
                            content={t("lost_password")}
                            inverted={true}
                            size="huge"
                        />
                    </Container>
                </Segment>
                <Segment inverted={false} vertical={true} padded="very">
                    <Container
                        textAlign="center"
                        text={true}
                    >

                        {
                            displayPasswordLostForm &&
                            <div>
                                <Header
                                    as="h3"
                                    content={t("lost_password_send_reset_token")}
                                    inverted={false}
                                    size="large"
                                />
                                <ConnectedPasswordLostForm/>
                            </div>
                        }
                        {
                            displayPasswordLostForm ? (
                                <Accordion>
                                    <Accordion.Title
                                        active={displayPasswordResetForm}
                                        onClick={() => setDisplayPasswordResetForm(!displayPasswordResetForm)}>
                                        {t("lost_password_token_received")}
                                    </Accordion.Title>
                                    <Accordion.Content active={displayPasswordResetForm}>
                                        <ConnectedPasswordResetForm/>
                                    </Accordion.Content>
                                </Accordion>
                            ) : (
                                <ConnectedPasswordResetForm/>
                            )
                        }

                    </Container>
                </Segment>
            </div>
        </Layout>
    );
};