import {ConnectedLoginForm} from "./containers/connected-login-form";
import {useFeaturesEnabled} from "./containers/features-provider";
import {Feature} from "@axys-lab/smart-report-shared";
import {ConnectedRegisterForm} from "./containers/connected-register-form";
import {VisitorRoute} from "./containers/visitor-route";
import {Home} from "./views/home";
import {Login} from "./views/login";
import {LostPassword} from "./views/lost-password";
import {Route, RouteComponentProps, RouteProps, Switch} from "react-router-dom";
import {About} from "./views/about";
import {ContactUs} from "./views/contact-us";
import {TermsOfUse} from "./views/terms-of-use";
import {EmailValidation} from "./views/email-validation";
import {UserProfile} from "./views/user-profile";
import {Records} from "./views/records";
import {CreateRecord} from "./views/record-create";
import {ViewRecord} from "./views/record-view";
import {RestrictedRoute} from "./containers/restricted-route";

const PrivateRoute = (props: RouteProps & {
    component: React.ComponentType<RouteComponentProps> | React.ComponentType;
}) => (
    <RestrictedRoute loginPath="/login" validatePath="/email-validation" {...props} />
);

export const Routes = () => {
    return (
        <>
            <ConnectedLoginForm/>
            {useFeaturesEnabled(Feature.LOCAL_ACCOUNT) && useFeaturesEnabled(Feature.REGISTER) ? (
                <ConnectedRegisterForm/>
            ) : (<></>)}
            <VisitorRoute exact={true} path="/" component={Home} loggedPath="/records"/>
            <VisitorRoute path="/login" component={Login} loggedPath="/records"/>
            {useFeaturesEnabled(Feature.LOCAL_ACCOUNT) ? (
                <VisitorRoute path="/lost-password/:token?" component={LostPassword} loggedPath="/records"/>
            ) : (<></>)}
            <Route path="/about" component={About}/>
            <Route path="/contact" component={ContactUs}/>
            <Route path="/terms" component={TermsOfUse}/>
            <Route path="/email-validation/:token?" component={EmailValidation}/>
            <PrivateRoute path="/me" component={UserProfile}/>
            <Switch>
                <PrivateRoute path="/records" exact={true} component={Records}/>
                <PrivateRoute path="/records/new" exact={true} component={CreateRecord}/>
                <PrivateRoute path="/records/:id" exact={true} component={ViewRecord}/>
            </Switch>
        </>
    )
}