import {connect} from "react-redux";
import {Action, Dispatch} from "redux";

import {RegisterForm} from "../components/register-form";
import {HIDE_REGISTER} from "../ducks/ui";
import {State} from "../reducers";
import {USER_REGISTER, UserRegistration} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    display: state.ui.displayRegister,
    remoteStatus: state.operations.registration.status,
    remoteErrors: state.operations.registration.errors
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onClose: () => dispatch(HIDE_REGISTER({})),
    onRegister: (user: UserRegistration) => dispatch(USER_REGISTER(user))
});

export const ConnectedRegisterForm = connect(mapStateToProps, mapDispatchToProps)(RegisterForm);