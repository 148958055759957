import {
    RECORD_REMOTE_FILE_DOWNLOAD,
    RECORD_REMOTE_FILE_DOWNLOAD_ERROR,
    RECORD_REMOTE_FILE_DOWNLOAD_SUCCESS
} from "@axys-lab/smart-report-shared";
import {LOCATION_CHANGED} from "../../../ui";
import {operation} from "../../common";
import {TIMEOUT_MESSAGE_CODE} from "../../../../utils/constants";

export const downloadRemote = operation(
    [{
        remote: true,
        sent: RECORD_REMOTE_FILE_DOWNLOAD,
        success: RECORD_REMOTE_FILE_DOWNLOAD_SUCCESS,
        errors: RECORD_REMOTE_FILE_DOWNLOAD_ERROR,
        timeout: {
            ms: 60000,
            trigger: () => RECORD_REMOTE_FILE_DOWNLOAD_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
        }
    }],
    LOCATION_CHANGED,
);