import * as React from "react";
import {ReactNode} from "react";
import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {DISPLAY_LOGIN} from "../ducks/ui";

type OwnProps = { children: ReactNode };
type DispatchProps = { displayLogin: () => void };
type Props = OwnProps & DispatchProps;

class UnderlyingForceLoginDisplay extends React.Component<Props> {

    public componentDidMount(): void {
        this.props.displayLogin();
    }

    public render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    displayLogin: () => dispatch(DISPLAY_LOGIN({})),
});

export const ForceLoginDisplay =
    connect(mapStateToProps, mapDispatchToProps)(UnderlyingForceLoginDisplay);