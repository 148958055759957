import {createAction} from "typesafe-actions";
import {
    EmailUpdate,
    EmailValidationToken,
    EmailValidationTokenResend,
    PasswordLost,
    PasswordReset,
    PasswordUpdate,
    UserBasicDetails,
    UserLogin,
    UserRegistration,
    UserSocialLoginAuthorized,
    UserSocialLoginSuccess,
    UserSocialProvider,
    UserToken
} from "../dtos";
import {ErrorResponse} from "@axys-lab/microservices-common-shared";
import {UserSocialLoginPrepare} from "../dtos/user-social-login-prepare";

export const USER_REGISTER = createAction("user/REGISTER")<UserRegistration>();
export const USER_REGISTER_SUCCESS = createAction("user/REGISTER/success")<Record<string, never>>();
export const USER_REGISTER_ERROR = createAction("user/REGISTER/error")<ErrorResponse>();

export const USER_EMAIL_VALIDATE = createAction("user/email/VALIDATE")<EmailValidationToken>();
export const USER_EMAIL_VALIDATE_SUCCESS = createAction("user/email/VALIDATE/success")<Record<string, never>>();
export const USER_EMAIL_VALIDATE_ERROR = createAction("user/email/VALIDATE/error")<ErrorResponse>();

export const USER_EMAIL_VALIDATION_TOKEN_RESEND = createAction("user/email/validation-token/RESEND")<EmailValidationTokenResend>();
export const USER_EMAIL_VALIDATION_TOKEN_RESEND_SUCCESS = createAction("user/email/validation-token/RESEND/success")<Record<string, never>>();
export const USER_EMAIL_VALIDATION_TOKEN_RESEND_ERROR = createAction("user/email/validation-token/RESEND/error")<ErrorResponse>();

export const USER_PASSWORD_LOST_REQUEST = createAction("user/password/reset-token/REQUEST")<PasswordLost>();
export const USER_PASSWORD_LOST_REQUEST_SUCCESS = createAction("user/password/reset-token/REQUEST/success")<Record<string, never>>();
export const USER_PASSWORD_LOST_REQUEST_ERROR = createAction("user/password/reset-token/REQUEST/error")<ErrorResponse>();

export const USER_PASSWORD_RESET = createAction("user/password/RESET")<PasswordReset>();
export const USER_PASSWORD_RESET_SUCCESS = createAction("user/password/RESET/success")<Record<string, never>>();
export const USER_PASSWORD_RESET_ERROR = createAction("user/password/RESET/error")<ErrorResponse>();

export const USER_SOCIAL_LOGIN_PREPARE = createAction("user/social/LOGIN/prepare")<UserSocialLoginPrepare>();
export const USER_SOCIAL_LOGIN = createAction("user/social/LOGIN")<UserSocialLoginAuthorized>();
export const USER_SOCIAL_LOGIN_SUCCESS = createAction("user/social/LOGIN/success")<UserSocialLoginSuccess>();
export const USER_SOCIAL_LOGIN_ERROR = createAction("user/social/LOGIN/error")<ErrorResponse>();
export const USER_SOCIAL_LOGIN_CANCEL = createAction("user/social/LOGIN/cancel")<UserSocialProvider>();

export const USER_LOGIN = createAction("user/LOGIN")<UserLogin>();
export const USER_LOGIN_SUCCESS = createAction("user/LOGIN/success")<UserToken>();
export const USER_LOGIN_ERROR = createAction("user/LOGIN/error")<ErrorResponse>();
export const USER_LOGOUT = createAction("user/LOGOUT")<Record<string, never>>();

export const USER_SESSION_OPEN = createAction("user/session/OPEN")<UserToken>();
export const USER_SESSION_OPEN_SUCCESS = createAction("user/session/OPEN/success")<Record<string, never>>();
export const USER_SESSION_OPEN_ERROR = createAction("user/session/OPEN/error")<ErrorResponse>();

export const USER_AUTHENTICATION_REFRESH = createAction("user/authentication/REFRESH")<UserToken>();
export const USER_AUTHENTICATION_REFRESH_SUCCESS = createAction("user/authentication/REFRESH/success")<UserToken>();
export const USER_AUTHENTICATION_REFRESH_ERROR = createAction("user/authentication/REFRESH/error")<ErrorResponse>();

export const USER_BASIC_DETAILS_UPDATE = createAction("user/basic-details/UPDATE")<UserBasicDetails>();
export const USER_BASIC_DETAILS_UPDATE_SUCCESS = createAction("user/basic-details/UPDATE/success")<UserToken>();
export const USER_BASIC_DETAILS_UPDATE_ERROR = createAction("user/basic-details/UPDATE/error")<ErrorResponse>();

export const USER_PASSWORD_UPDATE = createAction("user/password/UPDATE")<PasswordUpdate>();
export const USER_PASSWORD_UPDATE_SUCCESS = createAction("user/password/UPDATE/success")<UserToken>();
export const USER_PASSWORD_UPDATE_ERROR = createAction("user/password/UPDATE/error")<ErrorResponse>();

export const USER_EMAIL_UPDATE = createAction("user/email/UPDATE")<EmailUpdate>();
export const USER_EMAIL_UPDATE_SUCCESS = createAction("user/email/UPDATE/success")<UserToken>();
export const USER_EMAIL_UPDATE_ERROR = createAction("user/email/UPDATE/error")<ErrorResponse>();

export const USER_SOCIAL_LINK_PREPARE = createAction("user/social/LINK/prepare")<UserSocialLoginPrepare>();
export const USER_SOCIAL_LINK = createAction("user/social/LINK")<UserSocialLoginAuthorized>();
export const USER_SOCIAL_LINK_CANCEL = createAction("user/social/LINK /cancel")<UserSocialProvider>();
export const USER_SOCIAL_LINK_SUCCESS = createAction("user/social/LINK/success")<UserSocialLoginSuccess>();
export const USER_SOCIAL_LINK_ERROR = createAction("user/social/LINK/error")<ErrorResponse>();

export const USER_SOCIAL_UNLINK = createAction("user/social/UNLINK")<UserSocialProvider>();
export const USER_SOCIAL_UNLINK_SUCCESS = createAction("user/social/UNLINK/success")<UserToken>();
export const USER_SOCIAL_UNLINK_ERROR = createAction("user/social/UNLINK/error")<ErrorResponse>();
