import {ConnectedWebSocket, WebSocket} from "../../sagas/websocket";
import {ActionType, createReducer, createAction} from "typesafe-actions";

export const WEBSOCKET_START = createAction("websocket/START")<WebSocket>();
export const WEBSOCKET_STOP = createAction("websocket/STOP")<WebSocket>();
export const WEBSOCKET_STARTED = createAction("websocket/STARTED")<WebSocket>();
export const WEBSOCKET_STOPPED = createAction("websocket/STOPPED")<WebSocket>();
export const WEBSOCKET_DISCONNECTED = createAction("websocket/DISCONNECTED")<WebSocket>();
export const WEBSOCKET_TIMEOUT = createAction("websocket/TIMEOUT")<WebSocket>();
export const WEBSOCKET_CONNECTED = createAction("websocket/CONNECTED")<ConnectedWebSocket>();
export const WEBSOCKET_RECONNECTED = createAction("websocket/RECONNECTED")<ConnectedWebSocket>();

export type WebSocketAction = ActionType<typeof WEBSOCKET_START |
    typeof WEBSOCKET_STOP |
    typeof WEBSOCKET_STARTED |
    typeof WEBSOCKET_STOPPED |
    typeof WEBSOCKET_DISCONNECTED |
    typeof WEBSOCKET_TIMEOUT |
    typeof WEBSOCKET_CONNECTED |
    typeof WEBSOCKET_RECONNECTED>


export type SocketsState = {
    [namespace: string]: string | null | undefined;
};

export const initialSocketsState: SocketsState = {};

export const sockets = createReducer<SocketsState, WebSocketAction>(initialSocketsState)
    .handleAction(WEBSOCKET_CONNECTED, (state, action) => {
        return {
            ...state,
            [action.payload.namespace]: action.payload.socketId
        }
    })
    .handleAction(WEBSOCKET_RECONNECTED, (state, action) => {
        return {
            ...state,
            [action.payload.namespace]: action.payload.socketId
        }
    })
    .handleAction(WEBSOCKET_DISCONNECTED, (state, action) => {
        return {
            ...state,
            [action.payload.namespace]: null
        }
    });