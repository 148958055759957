import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {State} from "../reducers";
import {RECORD_SEARCH, RecordType} from "@axys-lab/smart-report-shared";
import {RecordsSearchMenu} from "../views/records/records-search-menu";
import {useEffect} from "react";
import {RemoteCommandStatus} from "../utils/remote-command-status";

type Props = {
    status: RemoteCommandStatus
    query: string | undefined
    recordType: RecordType | undefined
    page: number
    onSearch: (query?: string, recordType?: RecordType, page?: number) => void
    sessionOpened: boolean
}

export const ConnectedRecordsSearchMenu =
    connect(
        (state: State) => ({
            status: state.operations.recordsSearch.op.status,
            recordType: state.operations.recordsSearch.data.recordType,
            query: state.operations.recordsSearch.data.query,
            page: state.operations.recordsSearch.data.page,
            sessionOpened: state.operations.session.status === RemoteCommandStatus.SUCCESS
        }),
        (dispatch: Dispatch<Action>) => ({
            onSearch: (query?: string, recordType?: RecordType, page?: number) => dispatch(RECORD_SEARCH({
                recordType,
                query,
                page: page || 1,
            }))
        }),
    )((props: Props) => {
        useEffect(() => {
            if (props.sessionOpened) {
                props.onSearch(props.query, props.recordType, props.page);
            }
        }, [props.sessionOpened]);
        return (<RecordsSearchMenu
            {...props}
            onSearch={((query, recordType) => props.onSearch(query, recordType, props.page))}
        />)
    });