import {FormikProps, FormikValues, withFormik, WithFormikConfig} from "formik";
import {isEmpty} from "lodash";

import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {animated, config, useTransition} from "react-spring";
import {ButtonProps, Message} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "./constants";
import {RemoteCommandStatus} from "./remote-command-status";

export interface RemoteCommandProps {
    remoteStatus: RemoteCommandStatus;
    remoteErrors: string[];
}

type ComponentProps<P> = P & RemoteCommandProps;

export type InjectedRemoteFormProps = {
    isInError: boolean;
    joinedErrors: Set<string>;
};

type CombinedProps<P> = ComponentProps<P> & InjectedRemoteFormProps;

type RemoteFormHOC<P, DTO> = (Component: React.ComponentType<CombinedProps<P> & FormikProps<DTO>>) =>
    React.ComponentType<ComponentProps<P>>;

export const withRemoteForm = <P, DTO extends FormikValues>(
    formikConf: WithFormikConfig<ComponentProps<P>, DTO>,
    customConf?: {
        dontResetFormOnSuccess?: boolean
    }
): RemoteFormHOC<P, DTO> =>
    (Component) => withFormik<ComponentProps<P>, DTO>(formikConf)((props: ComponentProps<P> & FormikProps<DTO>) => {
        const {
            submitCount,
            remoteStatus,
            remoteErrors,
            values,
            touched,
            errors,
            resetForm
        } = props;

        if (!customConf || !customConf.dontResetFormOnSuccess) {
            useEffect(
                () => {
                    if (submitCount > 0 && remoteStatus === RemoteCommandStatus.SUCCESS) {
                        resetForm({values});
                    }
                },
                [remoteStatus, submitCount, resetForm, values]
            );
        }

        const touchedErrors: string[] = Object.keys(errors)
            .filter(key => touched[key] === true)
            .map(key => errors[key] as string);

        const joinedErrors: Set<string> = new Set();
        if (remoteErrors) {
            remoteErrors.forEach(error => joinedErrors.add(error));
        }
        if (touchedErrors) {
            touchedErrors.forEach(error => joinedErrors.add(error));
        }

        const isInError = joinedErrors.size > 0;

        return (
            <Component {...props} isInError={isInError} joinedErrors={joinedErrors}/>
        );
    });


export const FormMessages = (p: {
    messages: Array<{
        key: string
        error?: boolean;
        warn?: boolean;
        success?: boolean;
        header: string;
        content?: string;
        list?: string[]
    }>,
    attached?: boolean
}): JSX.Element => {
    const {messages, attached} = p;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const transitions = useTransition(
        messages,
        {
            from: {
                opacity: 0,
                height: 0,
                marginBottom: attached ? "-1px" : "0em",
                marginTop: attached ? "-1px" : "0em",
                paddingBottom: "0em",
                paddingTop: "0em",
                fontSize: "0em"
            },
            enter: {
                opacity: 1,
                height: "auto",
                marginBottom: attached ? "-1px" : "1em",
                marginTop: attached ? "-1px" : "1em",
                paddingBottom: "1em",
                paddingTop: "1em",
                fontSize: "1em"
            },
            leave: {
                opacity: 0,
                height: 0,
                marginBottom: attached ? "-1px" : "0em",
                marginTop: attached ? "-1px" : "0em",
                paddingTop: "0em",
                paddingBottom: "0em",
                fontSize: "0em"
            },
            config: {
                ...config.default,
                precision: 0.1,
                duration: 300
            }
        }
    );
    return (
        <>
            {
                transitions((style, item) => (
                    <animated.div style={style}>
                        <Message
                            style={{overflow: "hidden"}}
                            attached={attached}
                            negative={item.error}
                            warn={item.warn}
                            positive={item.success}
                            header={item.header && t(item.header)}
                            list={item.list}
                            content={item.content && t(item.content)}
                        />
                    </animated.div>
                ))
            }
        </>
    );
};

export const RemoteFormMessages = <P, DTO>(
    props: CombinedProps<P> & FormikProps<DTO> & {
        successHeaderMessageKey: string;
        successDetailsMessageKey: string;
        errorHeaderMessageKey: string;
        attached?: boolean;
        keepSuccessMessageWhenDirty?: boolean
    }): JSX.Element => {
    const {
        isInError,
        joinedErrors,
        remoteStatus,
        dirty,
        successHeaderMessageKey,
        successDetailsMessageKey,
        errorHeaderMessageKey,
        attached,
        keepSuccessMessageWhenDirty
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    const messages: Array<{
        key: string
        error?: boolean;
        warn?: boolean;
        success?: boolean;
        header: string;
        content?: string;
        list?: string[]
    }> = [];

    if (isInError) {
        messages.push({
            key: `error-${errorHeaderMessageKey}`,
            error: true,
            header: errorHeaderMessageKey,
            list: Array.from(joinedErrors).map(error => t(error))
        });
    }

    if (remoteStatus === RemoteCommandStatus.SUCCESS && !isInError && (!dirty || keepSuccessMessageWhenDirty)) {
        messages.push({
            key: `success-${successHeaderMessageKey}`,
            success: true,
            header: successHeaderMessageKey,
            content: successDetailsMessageKey
        });
    }

    return (
        <FormMessages
            messages={messages}
            attached={attached}
        />
    );
};

type RemoteFormProps = { onSubmit: () => void, error: boolean, loading: boolean };
export const remoteFormProps = <P, DTO>(props: CombinedProps<P> & FormikProps<DTO>): RemoteFormProps => {
    const {
        remoteStatus,
        isInError,
        handleSubmit,
    } = props;
    return {
        onSubmit: handleSubmit,
        error: isInError,
        loading: remoteStatus === RemoteCommandStatus.PENDING
    };
};

export enum FieldType {
    INPUT,
    CHECKBOX,
    RADIO
}

type RemoteFormFieldProps = {
    value?: number | string;
    checked?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement>, data: unknown) => void;
    onBlur?: (event: React.FormEvent<HTMLInputElement>, data: unknown) => void;
    error?: boolean;
    disabled?: boolean;
};

export const remoteFormFieldProps = <P, DTO extends Record<string, unknown>>(
    props: CombinedProps<P> & FormikProps<DTO>,
    fieldName: keyof DTO & string,
    fieldType: FieldType = FieldType.INPUT,
    radioValue?: string | number
): RemoteFormFieldProps => {
    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        remoteStatus,
        setFieldValue,
        setFieldTouched
    } = props;
    const newProps: RemoteFormFieldProps = {
        disabled: remoteStatus === RemoteCommandStatus.PENDING
    };

    switch (fieldType) {
        case FieldType.RADIO:
            newProps.value = radioValue;
            newProps.checked = values[fieldName] === radioValue;
            newProps.onChange = () => setFieldValue(fieldName, radioValue);
            newProps.onBlur = () => setFieldTouched(fieldName, true);
            break;

        case FieldType.CHECKBOX:
            newProps.checked = (values[fieldName] as unknown) as boolean;
            newProps.onBlur = handleBlur;
            newProps.onChange = (e) => setFieldValue(fieldName, (e.target as HTMLInputElement)?.checked || false)
            break;

        case FieldType.INPUT:
        default:
            newProps.value = (values[fieldName] as unknown) as string;
            newProps.onBlur = handleBlur;
            newProps.onChange = handleChange;
            newProps.error = !!(touched[fieldName] && errors[fieldName]);
    }

    return newProps;
};

export const remoteFormSubmitProps = <P, DTO>(
    props: CombinedProps<P> & FormikProps<DTO>,
    customConf?: {
        allowCleanSubmit?: boolean
    }
): ButtonProps => {
    const {
        remoteStatus,
        dirty,
        errors
    } = props;
    return {
        disabled: remoteStatus === RemoteCommandStatus.PENDING ||
            !isEmpty(errors) ||
            (!dirty && (!customConf || !customConf.allowCleanSubmit))
    };
};
