import {registerRemoteMessageChannel} from "../../../sagas/shared";
import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {DISPLAY_LOGIN, HIDE_LOGIN, LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {getType} from "typesafe-actions";
import {
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS, USER_LOGOUT,
    USER_SOCIAL_LOGIN, USER_SOCIAL_LOGIN_CANCEL, USER_SOCIAL_LOGIN_ERROR,
    USER_SOCIAL_LOGIN_SUCCESS
} from "@axys-lab/smart-report-shared";

export const login = operation(
    [
        {
            remote: true,
            sent: USER_LOGIN,
            success: USER_LOGIN_SUCCESS,
            errors: USER_LOGIN_ERROR,
            timeout: {
                trigger: () => USER_LOGIN_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        },
        {
            remote: true,
            sent: USER_SOCIAL_LOGIN,
            success: USER_SOCIAL_LOGIN_SUCCESS,
            errors: USER_SOCIAL_LOGIN_ERROR,
            timeout: {
                trigger: () => USER_SOCIAL_LOGIN_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }
    ],
    [LOCATION_CHANGED, DISPLAY_LOGIN, HIDE_LOGIN, USER_SOCIAL_LOGIN_CANCEL],
);

registerRemoteMessageChannel({
    sent: [getType(USER_LOGOUT)],
});