import {SerializedJWT} from "./jwt";

const JWT_REGEX = "^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$";

export type UserToken = {
    token: SerializedJWT;
}

export function isUserToken(object: any): object is UserToken {
    return "token" in object && typeof object.token === "string" && object.token.match(JWT_REGEX);
}