
import { Container, Grid, Segment } from "semantic-ui-react";
import { HomeFeature } from "../home-feature";
import "./style.css";

export const HomeFeatures = (): JSX.Element => {
    return (
        <Segment
            textAlign="center"
            vertical={true}
            padded="very"
        >
            <Container>
                <Grid
                    columns={5}
                    divided={true}
                    stackable={true}
                    celled="internally"
                >
                    <Grid.Column>
                        <HomeFeature icon="file audio outline" i18nKey="records_storing"/>
                    </Grid.Column>
                    <Grid.Column>
                        <HomeFeature icon="file alternate outline" i18nKey="records_automated_transcription"/>
                    </Grid.Column>
                    <Grid.Column>
                        <HomeFeature icon="tasks" i18nKey="records_automated_rida"/>
                    </Grid.Column>
                    <Grid.Column>
                        <HomeFeature icon="heartbeat" i18nKey="records_automated_emotion_recognition"/>
                    </Grid.Column>
                    <Grid.Column>
                        <HomeFeature icon="hand point down outline" i18nKey="record_key_moments"/>
                    </Grid.Column>
                </Grid>
            </Container>
        </Segment>
    );
};
