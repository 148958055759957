import {FormikProps} from "formik";

import {useTranslation} from "react-i18next";
import {Button, Form, Header, Segment} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {EmailUpdate, EmailUpdateSchema} from "@axys-lab/smart-report-shared";

type Props = RemoteCommandProps & {
    email: string;
    onSubmit: (emailUpdate: EmailUpdate) => void;
};

export const EmailUpdateForm = withRemoteForm<Props, EmailUpdate>(
    {
        validationSchema: EmailUpdateSchema,
        mapPropsToValues: (props) => ({
            email: props.email
        }),
        handleSubmit: (values, {props}) => {
            props.onSubmit(values);
        },
        displayName: "EmailUpdateForm",
    }
)((props: Props
    & InjectedRemoteFormProps
    & FormikProps<EmailUpdate>) => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Segment.Group stacked={true}>
            <Form
                size="large"
                {...remoteFormProps(props)}
            >
                <Header as="h2" attached="top">
                    {t("email_update")}
                </Header>

                <RemoteFormMessages
                    {...props}
                    errorHeaderMessageKey="email_update_error"
                    successHeaderMessageKey="email_update_success"
                    successDetailsMessageKey="email_update_success_details"
                    attached={true}
                />

                <Segment attached={true} padded={true}>

                    <Form.Input
                        id="email"
                        fluid={true}
                        label={t("email_address")}
                        icon="envelope outline"
                        iconPosition="left"
                        placeholder={t("email_address")}
                        type="email"
                        required={true}
                        {...remoteFormFieldProps(
                            props,
                            "email"
                        )}
                    />

                </Segment>

                <Segment attached={true} padded={true}>
                    <Button
                        color={PRIMARY_COLOR}
                        fluid={true}
                        size="large"
                        type="submit"
                        {...remoteFormSubmitProps(props)}
                    >
                        {t("email_update_submit")}
                    </Button>
                </Segment>
            </Form>
        </Segment.Group>
    );
});