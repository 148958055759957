import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {operation, OperationState} from "../common";
import {
    Feature,
    FeatureAction,
    FEATURES_GET,
    FEATURES_GET_ERROR,
    FEATURES_GET_SUCCESS
} from "@axys-lab/smart-report-shared";
import {combineReducers} from "redux";
import {createReducer} from "typesafe-actions";

export type FeaturesState = {
    getOp: OperationState;
    features: Feature[];
}

export const features = combineReducers({
    getOp: operation(
        [{
            remote: true,
            sent: FEATURES_GET,
            success: FEATURES_GET_SUCCESS,
            errors: FEATURES_GET_ERROR,
            timeout: {
                trigger: () => FEATURES_GET_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }],
    ),
    features: createReducer<Feature[], FeatureAction>([])
        .handleAction(
            FEATURES_GET_SUCCESS,
            (state, action) => (action.payload.features)
        )
});