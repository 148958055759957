import {ActionType, createAction, createReducer, PayloadActionCreator} from "typesafe-actions";
import {
    decodeJWT,
    Jwt,
    SerializedJWT,
    USER_AUTHENTICATION_REFRESH_ERROR,
    USER_AUTHENTICATION_REFRESH_SUCCESS,
    USER_BASIC_DETAILS_UPDATE_SUCCESS,
    USER_EMAIL_UPDATE_SUCCESS,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_PASSWORD_UPDATE_SUCCESS,
    USER_SESSION_OPEN_ERROR,
    USER_SOCIAL_LINK_CANCEL,
    USER_SOCIAL_LINK_ERROR,
    USER_SOCIAL_LINK_PREPARE,
    USER_SOCIAL_LINK_SUCCESS,
    USER_SOCIAL_LOGIN_CANCEL,
    USER_SOCIAL_LOGIN_ERROR,
    USER_SOCIAL_LOGIN_PREPARE,
    USER_SOCIAL_LOGIN_SUCCESS,
    USER_SOCIAL_UNLINK_SUCCESS,
    UserAction as SharedUserAction,
    UserSocialLoginPrepare,
    UserSocialLoginSuccess,
    UserToken
} from "@axys-lab/smart-report-shared";

export const USER_LOAD = createAction("user/LOAD")<Record<string, never>>();
export const USER_LOAD_NOT_FOUND = createAction("user/LOAD/not-found")<Record<string, never>>();
export const USER_LOAD_SUCCESS = createAction("user/LOAD/success")<{
    serialized: SerializedJWT,
    value: Jwt
}>();

export type UserAction = SharedUserAction | ActionType<typeof USER_LOAD_SUCCESS>;

const defaultLoginHandler = (state: UserState, payload: UserToken): UserState => ({
    token: {
        serialized: payload.token,
        value: decodeJWT(payload.token)
    },
    socialLoginId: state.socialLoginId
});

const socialLoginHandler = (state: UserState, payload: UserSocialLoginSuccess): UserState => {
    if (state && state.socialLoginId && payload.socialLoginId === state.socialLoginId) {
        return {
            ...defaultLoginHandler(state, payload),
            socialLoginId: undefined
        };
    }
    return state;
};

const resetSocialLoginIdHandler = (state: UserState) => ({
    token: state.token
});

const setSocialLoginIdHandler = (state: UserState, payload: UserSocialLoginPrepare) => ({
    ...state,
    socialLoginId: payload.socialLoginId
});


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const actionsTriggeringUserReload: Array<PayloadActionCreator<string, any>> = [
    USER_LOGIN_SUCCESS,
    USER_AUTHENTICATION_REFRESH_SUCCESS,
    USER_BASIC_DETAILS_UPDATE_SUCCESS,
    USER_PASSWORD_UPDATE_SUCCESS,
    USER_EMAIL_UPDATE_SUCCESS,
    USER_SOCIAL_LOGIN_SUCCESS,
    USER_SOCIAL_LINK_SUCCESS,
    USER_SOCIAL_UNLINK_SUCCESS
];

export type UserTokenState = {
    serialized: SerializedJWT
    value: Jwt
};

export type UserState = Readonly<{
    socialLoginId?: string,
    token?: UserTokenState
}>;

const initialState: UserState = {};

export const reducer = createReducer<UserState, UserAction>(initialState)
    .handleAction(USER_LOAD_SUCCESS, (state, action) => ({
        token: action.payload,
    }))
    .handleAction(USER_LOGOUT, () => ({}))
    .handleAction(USER_SESSION_OPEN_ERROR, () => ({}))
    .handleAction(USER_AUTHENTICATION_REFRESH_ERROR, () => ({}))
    .handleAction(USER_SOCIAL_LOGIN_PREPARE, (state, action) => setSocialLoginIdHandler(state, action.payload))
    .handleAction(USER_SOCIAL_LOGIN_ERROR, state => resetSocialLoginIdHandler(state))
    .handleAction(USER_SOCIAL_LOGIN_CANCEL, state => resetSocialLoginIdHandler(state))
    .handleAction(USER_SOCIAL_LINK_PREPARE, (state, action) => setSocialLoginIdHandler(state, action.payload))
    .handleAction(USER_SOCIAL_LINK_ERROR, state => resetSocialLoginIdHandler(state))
    .handleAction(USER_SOCIAL_LINK_CANCEL, state => resetSocialLoginIdHandler(state))
    .handleAction(USER_LOGIN_SUCCESS, (state, action) => defaultLoginHandler(state, action.payload))
    .handleAction(USER_AUTHENTICATION_REFRESH_SUCCESS, (state, action) => defaultLoginHandler(state, action.payload))
    .handleAction(USER_BASIC_DETAILS_UPDATE_SUCCESS, (state, action) => defaultLoginHandler(state, action.payload))
    .handleAction(USER_PASSWORD_UPDATE_SUCCESS, (state, action) => defaultLoginHandler(state, action.payload))
    .handleAction(USER_EMAIL_UPDATE_SUCCESS, (state, action) => defaultLoginHandler(state, action.payload))
    .handleAction(USER_SOCIAL_UNLINK_SUCCESS, (state, action) => defaultLoginHandler(state, action.payload))
    .handleAction(USER_SOCIAL_LOGIN_SUCCESS, (state, action) => socialLoginHandler(state, action.payload))
    .handleAction(USER_SOCIAL_LINK_SUCCESS, (state, action) => socialLoginHandler(state, action.payload));

