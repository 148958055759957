import { FormikProps } from "formik";

import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Header, Segment } from "semantic-ui-react";
import { PRIMARY_COLOR, TRANSLATIONS_NAMESPACE } from "../../utils/constants";
import {
    FieldType,
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {Avatar, NONE, UserBasicDetails, UserBasicDetailsSchema} from "@axys-lab/smart-report-shared";

type Props = RemoteCommandProps & UserBasicDetails & {
    availableAvatars: Array<Avatar | NONE>;
    onSubmit: (basicDetails: UserBasicDetails) => void
};

export const UserBasicDetailsForm = withRemoteForm<Props, UserBasicDetails>({
    validationSchema: UserBasicDetailsSchema,
    mapPropsToValues: (props) => ({
        username: props.username,
        avatar: props.avatar,
        firstname: props.firstname,
        lastname: props.lastname
    }),
    handleSubmit: (values, {props}) => {
        props.onSubmit(values);
    },
    displayName: "UserBasicDetailsForm",
})((props: Props
    & InjectedRemoteFormProps
    & FormikProps<UserBasicDetails>
) => {
    const {
        availableAvatars,
    } = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    return (
        <Segment.Group stacked={true} className="basic-details">
            <Form
                size="large"
                {...remoteFormProps(props)}
            >

                <Header as="h2" attached="top">
                    {t("user_basic_details")}
                </Header>

                <RemoteFormMessages
                    {...props}
                    errorHeaderMessageKey="user_basic_details_update_error"
                    successHeaderMessageKey="user_basic_details_update_success"
                    successDetailsMessageKey="user_basic_details_update_success_details"
                    attached={true}
                />

                <Segment attached={true} padded={true}>
                    <Form.Input
                        id="username"
                        fluid={true}
                        icon="user"
                        iconPosition="left"
                        label={t("username")}
                        placeholder={t("username")}
                        required={true}
                        {...remoteFormFieldProps(
                            props,
                            "username"
                        )}
                    />

                </Segment>
                <Segment attached={true} padded={true}>
                    <Form.Input
                        id="firstname"
                        fluid={true}
                        label={t("firstname")}
                        icon="id card outline"
                        iconPosition="left"
                        placeholder={t("firstname")}
                        required={true}
                        {...remoteFormFieldProps(
                            props,
                            "firstname"
                        )}
                    />
                    <Form.Input
                        id="lastname"
                        fluid={true}
                        label={t("lastname")}
                        icon="id card outline"
                        iconPosition="left"
                        placeholder={t("lastname")}
                        required={true}
                        {...remoteFormFieldProps(
                            props,
                            "lastname"
                        )}
                    />
                </Segment>
                <Segment attached={true} padded={true}>
                    <Form.Field required={true}>
                        <label>{t("avatar")}</label>
                    </Form.Field>

                    {
                        availableAvatars.map(avatarType => (
                            <Form.Field
                                key={avatarType}
                            >
                                <Checkbox
                                    name="avatar"
                                    radio={true}
                                    label={t(avatarType)}
                                    {...remoteFormFieldProps(
                                        props,
                                        "avatar",
                                        FieldType.RADIO,
                                        avatarType
                                    )}
                                />
                            </Form.Field>
                        ))
                    }

                </Segment>
                <Segment attached={true} padded={true}>
                    <Button
                        color={PRIMARY_COLOR}
                        fluid={true}
                        size="large"
                        type="submit"
                        {...remoteFormSubmitProps(props)}
                    >
                        {t("user_basic_details_update_submit")}
                    </Button>
                </Segment>
            </Form>
        </Segment.Group>
    );
});