import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {State} from "../reducers";
import {RecordViewPanel} from "../views/record-view/record-view-panel";
import {useEffect} from "react";
import {Record, RECORD_GET} from "@axys-lab/smart-report-shared";
import {RemoteCommandStatus} from "../utils/remote-command-status";

const mapStateToProps = (state: State) => {
    const edit = state.operations.recordView.editOp.op.status === RemoteCommandStatus.PENDING ||
        state.operations.recordView.editOp.op.status === RemoteCommandStatus.ERROR;

    return ({
        record: state.operations.recordView.data.record,
        errors: edit ? state.operations.recordView.editOp.op.errors : state.operations.recordView.getOp.errors,
        status: edit ? state.operations.recordView.editOp.op.status : state.operations.recordView.getOp.status,
        sessionOpened: state.operations.session.status === RemoteCommandStatus.SUCCESS
    });
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    load: (recordId: string) => dispatch(RECORD_GET({recordId}))
});

export const ConnectedRecordViewPanel =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )((props: {
        recordId: string,
        load: (recordId: string) => void,
        errors: string[],
        status: RemoteCommandStatus,
        record: Record | null,
        sessionOpened: boolean
    }) => {
        const {errors, status, recordId, load, record, sessionOpened} = props;
        useEffect(() => {
            if (sessionOpened) {
                load(recordId);
            }
        }, [recordId, sessionOpened])
        return (
            <RecordViewPanel
                errors={errors}
                status={status}
                record={record && recordId !== record.recordId ? null : props.record}
            />);
    });