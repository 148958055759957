import {useTranslation} from "react-i18next";
import {Container, Header} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {Layout} from "../../components/layout";
import {InnerLayout} from "../../components/inner-layout";
import {ConnectedRecordCreateForm} from "../../containers/connected-record-create-form";

export const CreateRecord = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Layout>
            <InnerLayout
                header={
                    <Container
                        textAlign="center"
                        text={true}
                    >
                        <Header
                            as="h2"
                            content={t("record_create")}
                            inverted={true}
                            size="huge"
                        />
                    </Container>
                }
            >
                <div className="create-record">
                    <ConnectedRecordCreateForm/>
                </div>
            </InnerLayout>
        </Layout>
    );
};
