import {object, ObjectSchema, string} from "yup";
import {RecordIdSchema} from "../../utils";
import {RecordReference} from "./record-reference";
import {EnumValues} from "enum-values";
import {RecordProcessStep} from "./record-process-step";

export type RecordReprocess = RecordReference & {
    processStep: RecordProcessStep
}

export const RecordReprocessSchema: ObjectSchema<RecordReprocess> = object({
    recordId: RecordIdSchema,
    processStep: string().oneOf(EnumValues.getValues<RecordProcessStep>(RecordProcessStep), "process_step_unknown").required()
}).required();