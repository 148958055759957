import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {USER_EMAIL_UPDATE, USER_EMAIL_UPDATE_ERROR, USER_EMAIL_UPDATE_SUCCESS} from "@axys-lab/smart-report-shared";

export const emailUpdate = operation(
    [ {
        remote: true,
        sent: USER_EMAIL_UPDATE,
        success: USER_EMAIL_UPDATE_SUCCESS,
        errors: USER_EMAIL_UPDATE_ERROR,
        timeout: {
            trigger: () => USER_EMAIL_UPDATE_ERROR({message_codes: [ TIMEOUT_MESSAGE_CODE ]})
        }
    } ],
    LOCATION_CHANGED,
);