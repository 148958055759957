import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {Grid, Header, Segment} from "semantic-ui-react";
import {ConnectedNotifications} from "../../containers/connected-notifications";
import logo from "../../media/picto-axys-invert.png";
import {Footer} from "../footer";
import {Language, LocaleSelector} from "../locale-selector";
import "./style.css";
import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";

const languages: Language[] = [
    {language: "fr", flag: {name: "fr"}},
    {language: "en", flag: {name: "gb"}}
];

export const Layout = (props: { children?: ReactNode, inverted?: boolean, hideLogo?: boolean }): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <div className="layout-global">
            <div className="layout-main">
                <Segment
                    className="layout-header"
                    basic={true}
                    inverted={props.inverted}
                    textAlign="center"
                    vertical={true}
                    padded={false}
                >
                    <Grid>
                        <Grid.Column floated="left" width={8}>
                            {
                                !props.hideLogo &&
                                <Link to="/">
                                    <Header
                                        as="h1"
                                        size="medium"
                                        content={
                                            <span>
                                            <img className="logo" src={logo} alt={t("site_name")}/>
                                                {t("site_name")}
                                        </span>
                                        }
                                        inverted={false}
                                    />
                                </Link>
                            }
                        </Grid.Column>
                        <Grid.Column floated="right" width={8}>
                            <div className="layout-locale-selector">
                                <LocaleSelector languages={languages}/>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <div className="layout-container">
                    <ConnectedNotifications/>
                    {props.children}
                </div>
            </div>
            <Footer/>
        </div>

    );
};