import {useTranslation} from "react-i18next";
import {Container, Header, Segment} from "semantic-ui-react";
import {ConnectedLoginButton} from "../../../containers/connected-login-button";
import logo from "../../../media/picto-axys.png";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../../utils/constants";

import "./style.css";

export const HomeHeader = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Segment inverted={true} vertical={true} padded="very" className="home-header">
            <Container
                textAlign="center"
                text={true}
            >
                <Header
                    as="h1"
                    size="huge"
                    content={
                        <span>
                                    <img className="logo" src={logo} alt={t("site_name")}/>
                            {t("site_name")}
                                </span>
                    }
                    inverted={true}
                />
                <Header
                    as="h2"
                    content={t("slogan")}
                    inverted={true}
                    size="huge"
                />

                <ConnectedLoginButton
                    color={PRIMARY_COLOR}
                    size="huge"
                />

            </Container>
        </Segment>
    );
};
