import {object, ObjectSchema} from "yup";
import {PasswordConfirmationSchema, PasswordSchema, TokenSchema} from "../../utils/schemas";

export type PasswordReset = {
    token: string;
    password: string;
    passwordConfirmation: string;
}

export const PasswordResetSchema: ObjectSchema<PasswordReset> = object({
    token: TokenSchema(),
    password: PasswordSchema(),
    passwordConfirmation: PasswordConfirmationSchema("password_confirmation", "password")
}).required();


