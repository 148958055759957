import {FormikProps} from "formik";

import {useTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Form, Segment} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {useMount} from "react-use";
import {PasswordReset, PasswordResetSchema} from "@axys-lab/smart-report-shared";

type Props = RemoteCommandProps & {
    onSubmit: (passwordReset: PasswordReset) => void
} ;

type FormProps = Props
    & RouteComponentProps<{ token: string }>
    & InjectedRemoteFormProps
    & FormikProps<PasswordReset>;

export const PasswordResetForm =
    withRemoteForm<Props, PasswordReset>({
        validationSchema: PasswordResetSchema,
        mapPropsToValues: () => ({
            token: "",
            password: "",
            passwordConfirmation: ""
        }),
        handleSubmit: (values, {props}) => {
            props.onSubmit(values);
        },
        displayName: "ResetPasswordForm",
    })(
        withRouter((props: FormProps) => {
                const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
                useMount(() => {
                    const {match, setValues} = props;
                    if (match.params.token) {
                        void setValues({
                            token: match.params.token,
                            password: "",
                            passwordConfirmation: ""
                        });
                    }
                });
                const {remoteStatus} = props;

                return (
                    <Form
                        size="large"
                        {...remoteFormProps(props)}
                    >
                        <Segment stacked={false}>
                            <RemoteFormMessages
                                {...props}
                                errorHeaderMessageKey="password_reset_error"
                                successHeaderMessageKey="password_reset_success"
                                successDetailsMessageKey="password_reset_success_details"
                            />
                            {
                                !(remoteStatus === RemoteCommandStatus.SUCCESS) &&
                                <div>
                                    <Form.Input
                                        id="token"
                                        fluid={true}
                                        icon="user secret"
                                        iconPosition="left"
                                        placeholder={t("password_reset_token")}
                                        required={true}
                                        {...remoteFormFieldProps(
                                            props,
                                            "token"
                                        )}
                                    />
                                    <Form.Input
                                        id="password"
                                        fluid={true}
                                        label={t("password")}
                                        icon="lock"
                                        iconPosition="left"
                                        placeholder={t("password")}
                                        type="password"
                                        required={true}
                                        {...remoteFormFieldProps(
                                            props,
                                            "password"
                                        )}
                                    />
                                    <Form.Input
                                        id="passwordConfirmation"
                                        fluid={true}
                                        label={t("password_confirmation")}
                                        icon="lock"
                                        iconPosition="left"
                                        placeholder={t("password_confirmation")}
                                        type="password"
                                        required={true}
                                        {...remoteFormFieldProps(
                                            props,
                                            "passwordConfirmation"
                                        )}
                                    />
                                    <Button
                                        color={PRIMARY_COLOR}
                                        fluid={true}
                                        size="large"
                                        type="submit"
                                        {...remoteFormSubmitProps(props)}
                                    >
                                        {t("submit_password_reset_token")}
                                    </Button>
                                </div>
                            }
                        </Segment>
                    </Form>
                );
            }
        )
    );