import {useTranslation} from "react-i18next";
import {Flag, FlagProps} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import "./style.css";

export interface Language {
    language: string;
    flag: FlagProps;
}

export const LocaleSelector = (props: {
    languages: Language[],
    style?: Record<string, string | number>
}): JSX.Element => {
    const {i18n} = useTranslation(TRANSLATIONS_NAMESPACE);
    const {languages, style} = props;
    return (
        <div style={style} className="locale-selector">
            {languages.map((language) =>
                <a
                    href="#locale-selector"
                    className={language.language === i18n.language ? "locale-selected" : ""}
                    key={language.language}
                    onClick={(e) => {
                        e.preventDefault();
                        void i18n.changeLanguage(language.language);
                    }}
                >
                    <Flag {...language.flag} />
                </a>
            )}
        </div>
    );
};