import {boolean, object, ObjectSchema, string} from "yup";
import {PasswordConfirmationSchema, PasswordSchema} from "../../utils";

export type PasswordUpdate = {
    creation: boolean;
    password?: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

export const PasswordUpdateSchema: ObjectSchema<PasswordUpdate> = object({
    creation: boolean().defined(),
    password: string().when("creation", ([creation]) => {
        return creation ?
            string().nullable() :
            PasswordSchema()
    }),
    newPassword: PasswordSchema("new_password"),
    newPasswordConfirmation: PasswordConfirmationSchema("new_password_confirmation", "newPassword")
}).required();
