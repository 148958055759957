import {actionChannel} from "redux-saga/effects";
import {Buffer, buffers} from 'redux-saga';
import {Action} from "redux";
import {ActionChannelEffect} from "@redux-saga/core/effects";


export type PrioritizedAction = {
    action: string;
    priority: number;
}

export const prioritizedActionChannel = (actions: PrioritizedAction[]): ActionChannelEffect => {
    return actionChannel(actions.map(action => action.action), prioritizedActionBuffer(actions));
}

export const prioritizedActionBuffer = (actions: PrioritizedAction[]): Buffer<Action> => {

    const actionsPriorities = [...actions].reduce((acc, value) => {
        return {
            ...acc,
            [value.action]: value.priority
        };
    }, {} as {[key: string]: number})

    const priorities = new Set(
        actions
            .sort((a, b) => b.priority - a.priority)
            .map(action => action.priority)
    );

    const prioritiesBuffers: { [key: string]: Buffer<Action> } = Array.from(priorities)
        .reduce((acc, priority) => {
            return {
                ...acc,
                [priority]: buffers.expanding(10)
            };
        }, {})

    const put = (action: Action) => {
        const priority = actionsPriorities[action.type];
        prioritiesBuffers[priority].put(action);
    };

    const take = () => {
        const priority = Object.keys(prioritiesBuffers)
            .reverse()
            .find(priority => !prioritiesBuffers[priority].isEmpty())

        if (priority === undefined) {
            return undefined;
        }
        return prioritiesBuffers[+priority].take();
    };

    const flush = () => {
        return ([] as Action[]).concat(
            ...Object.values(prioritiesBuffers)
                .map(buffer => buffer.flush())
        );
    };

    const isEmpty = () => {
        const anyNotEmpty = Object.values(prioritiesBuffers)
            .find(buffer => !buffer.isEmpty());
        return !anyNotEmpty;
    };

    return {
        flush,
        isEmpty,
        put,
        take,
    };
};