import {
    FileUploadProgress,
    RECORD_UPLOAD_CANCEL,
    RECORD_UPLOAD_CHUNK_REQUEST, RECORD_UPLOAD_ERROR, RECORD_UPLOAD_START,
    RECORD_UPLOAD_SUCCESS, RecordAction, RecordReference
} from "@axys-lab/smart-report-shared";
import {ActionType, createAction, createReducer} from "typesafe-actions";
import {LOCATION_CHANGED} from "../../../ui";
import {FileWithPath} from "react-dropzone";

export const RECORD_UPLOAD_SELECTED = createAction("record/upload/SELECTED")<RecordReference & {
    file: FileWithPath
}>();

export type AugmentedUploadAction = RecordAction |
    ActionType<typeof RECORD_UPLOAD_SELECTED> |
    ActionType<typeof LOCATION_CHANGED>;


export type RecordUploadState = {
    progress: FileUploadProgress;
    started: boolean;
    errors: string[]
};

export const initialUploadState: RecordUploadState = {
    progress: 0,
    started: false,
    errors: []
};

export const uploadOp = createReducer<RecordUploadState, AugmentedUploadAction>(initialUploadState)
    .handleAction(
        RECORD_UPLOAD_CHUNK_REQUEST,
        (state, action) => {
            if (state.started) {
                return ({
                    ...state,
                    progress: action.payload.progress
                });
            } else {
                return state;
            }
        }
    )
    .handleAction([LOCATION_CHANGED, RECORD_UPLOAD_CANCEL, RECORD_UPLOAD_SUCCESS], () => {
            return ({
                ...initialUploadState
            })
        }
    )
    .handleAction(RECORD_UPLOAD_ERROR, (state, action) => ({
            ...initialUploadState,
            errors: action.payload.message_codes,
        })
    )
    .handleAction(RECORD_UPLOAD_SELECTED, () => ({
            progress: 0,
            started: true,
            errors: []
        })
    )
    .handleAction(RECORD_UPLOAD_START, (state: RecordUploadState) => ({
            ...state,
            progress: 0,
            started: true,
        })
    );