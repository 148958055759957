import {OperationsState, reducer as operations} from "../ducks/operations";
import {reducer as ui, UIState} from "../ducks/ui";
import {reducer as user, UserState} from "../ducks/user";
import {combineReducers, Reducer} from "redux";
import {connectRouter, RouterState} from "connected-react-router"
import {createBrowserHistory} from "history";

export const history = createBrowserHistory();

export type State = {
    ui: UIState
    user: UserState
    operations: OperationsState
    router: RouterState
};

// @ts-expect-error TODO fix typing issue with initial state
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
export const rootReducer: Reducer<State, any, State> = combineReducers({
    ui,
    user,
    operations,
    router: connectRouter(history)
})!;

