import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { PasswordLostForm } from "../views/lost-password/password-lost-form";
import { State } from "../reducers";
import {PasswordLost, USER_PASSWORD_LOST_REQUEST} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => ({
    remoteStatus: state.operations.passwordLost.status,
    remoteErrors: state.operations.passwordLost.errors
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (lostPassword: PasswordLost) =>
        dispatch(USER_PASSWORD_LOST_REQUEST(lostPassword))
});

export const ConnectedPasswordLostForm = connect(mapStateToProps, mapDispatchToProps)(PasswordLostForm);