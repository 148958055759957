import {object, ObjectSchema, string} from "yup";
import {SocialProvider} from "../../utils/constants";
import {EmailSchema, SocialProviderSchema, TokenSchema, UsernameSchema} from "../../utils/schemas";

export type UserSocialLogin = {
    username: string;
    email: string;
    provider: SocialProvider;
    providerUserId: string;
    providerAvatar: string | undefined;
    socialLoginId: string;
}

export const UserSocialLoginSchema: ObjectSchema<UserSocialLogin> = object({
    username: UsernameSchema(),
    email: EmailSchema(),
    provider: SocialProviderSchema(),
    providerUserId: string()
        .required("provider_user_id_required"),
    providerAvatar: string()
        .url("provider_avatar_url_format"),
    socialLoginId: TokenSchema("social_login_id")
}).required();