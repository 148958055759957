
import {useTranslation} from "react-i18next";
import {Container, Header} from "semantic-ui-react";
import {ConnectedEmailUpdateForm} from "../../containers/connected-email-update-form";
import {ConnectedPasswordUpdateForm} from "../../containers/connected-password-update-form";
import {ConnectedUserBasicDetailsForm} from "../../containers/connected-user-basic-details-form";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {InnerLayout} from "../../components/inner-layout";
import {Layout} from "../../components/layout";

import "./style.css";
import {useFeaturesEnabled} from "../../containers/features-provider";
import {Feature} from "@axys-lab/smart-report-shared";
import {ConnectedSocialProvidersForm} from "../../containers/connected-social-providers-form";

export const UserProfile = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Layout>
            <InnerLayout
                header={
                    <Container
                        textAlign="center"
                        text={true}
                    >
                        <Header
                            as="h2"
                            content={t("user_profile")}
                            inverted={true}
                            size="huge"
                        />
                    </Container>
                }
            >
                <div className="user-profile">
                    <ConnectedUserBasicDetailsForm/>
                    {useFeaturesEnabled(Feature.LOCAL_ACCOUNT) ? (
                        <ConnectedPasswordUpdateForm/>
                    ) : <></>}
                    <ConnectedEmailUpdateForm/>
                    {useFeaturesEnabled(Feature.SOCIAL_LOGIN_GOOGLE, Feature.SOCIAL_LOGIN_MICROSOFT) ? (
                        <ConnectedSocialProvidersForm/>
                    ) : <></>}
                </div>
            </InnerLayout>
        </Layout>
    );
};