import {connect} from "react-redux";
import {State} from "../reducers";
import {EmotionRecognitionPanel} from "../views/record-view/record-view-panel/emotion-recognition-panel";
import {START_AT} from "../ducks/operations/records/record-view";

export const ConnectedEmotionRecognitionPanel = connect(
    (state: State) => ({
        emotionRecognition: state.operations.recordView.data.record?.data.emotion.result,
        status: state.operations.recordView.data.record?.data.emotion.status,
        currentTime: state.operations.recordView.data.currentTime
    }),
    (dispatch) => ({
        startAt: (currentTime: number) => {
            dispatch(START_AT({currentTime}));
        },
    })
)(EmotionRecognitionPanel);
