
import {ReactNode, Suspense} from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Loader} from "../components/loader";
import {FeaturesProvider} from "./features-provider";

type Props = RouteComponentProps & {
    children: ReactNode,
};

const UnderlyingAppContainer = (props: Props) => (
    <Suspense fallback={
        <Loader display={true} message="..."/>
    }>
        <FeaturesProvider>
            {props.children}
        </FeaturesProvider>
    </Suspense>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export const AppContainer =
    connect(mapStateToProps, mapDispatchToProps)(
        withRouter(UnderlyingAppContainer)
    );