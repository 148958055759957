export type Message<T> = { event: string, data: T };

export type PayloadAction<T> = {
    type: string;
    payload: T;
};

export type ActionToMessage<T> = (action: PayloadAction<T>) => Message<T>;

export type MessageToAction<T> = (message: Message<T>) => PayloadAction<T>;

export const StandardActionToMessage: ActionToMessage<any> = <T>(action: PayloadAction<T>) => ({
    event: action.type,
    data: action.payload
});

export const StandardMessageToAction: MessageToAction<any> = <T>(message: Message<T>) => ({
    type: message.event,
    payload: message.data
});