import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {
    USER_SOCIAL_LINK,
    USER_SOCIAL_LINK_CANCEL,
    USER_SOCIAL_LINK_ERROR,
    USER_SOCIAL_LINK_SUCCESS,
    USER_SOCIAL_UNLINK,
    USER_SOCIAL_UNLINK_ERROR,
    USER_SOCIAL_UNLINK_SUCCESS
} from "@axys-lab/smart-report-shared";

export const socialAccountLink = operation(
    [
        {
            remote: true,
            sent: USER_SOCIAL_LINK,
            success: USER_SOCIAL_LINK_SUCCESS,
            errors: USER_SOCIAL_LINK_ERROR,
        },
        {
            remote: true,
            sent: USER_SOCIAL_UNLINK,
            success: USER_SOCIAL_UNLINK_SUCCESS,
            errors: USER_SOCIAL_UNLINK_ERROR,
            timeout: {
                trigger: () => USER_SOCIAL_UNLINK_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
            }
        }
    ],
    [LOCATION_CHANGED, USER_SOCIAL_LINK_CANCEL],
);