import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {UserBasicDetailsForm} from "../views/user-profile/user-basic-details-form";
import {State} from "../reducers";
import {
    Avatar,
    GRAVATAR,
    LOCAL, MICROSOFT,
    NONE,
    SocialProvider,
    USER_BASIC_DETAILS_UPDATE,
    UserBasicDetails
} from "@axys-lab/smart-report-shared";

const mapStateToProps = (state: State) => {
    const providersAvatars = (state.user.token ?
            // we can't get microsoft avatars yet
            Object.keys(state.user.token.value.user.providers).filter(provider => provider !== LOCAL && provider !== MICROSOFT) :
            []
    ) as SocialProvider[];

    const availableAvatars: Array<Avatar | NONE> = [
        NONE,
        GRAVATAR,
        ...providersAvatars
    ];

    return {
        remoteStatus: state.operations.basicDetailsUpdate.status,
        remoteErrors: state.operations.basicDetailsUpdate.errors,
        username: state.user.token ? state.user.token.value.user.username : "",
        avatar: state.user.token && state.user.token.value.user.avatar ? state.user.token.value.user.avatar.type : NONE,
        firstname: state.user.token ? state.user.token.value.user.firstName : "",
        lastname: state.user.token ? state.user.token.value.user.lastName : "",
        availableAvatars
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (request: UserBasicDetails) => {
        dispatch(USER_BASIC_DETAILS_UPDATE(request));
    }
});

export const ConnectedUserBasicDetailsForm =
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(UserBasicDetailsForm);