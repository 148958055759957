import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {State} from "../reducers";
import {RECORD_SEARCH, RecordType} from "@axys-lab/smart-report-shared";
import {RecordsPaginatedTable} from "../views/records/records-paginated-table";


export const ConnectedRecordsPaginatedTable =
    connect(
        (state: State) => ({
            status: state.operations.recordsSearch.op.status,
            errors: state.operations.recordsSearch.op.errors,
            records: state.operations.recordsSearch.data.records,
            page: state.operations.recordsSearch.data.page,
            totalPages: state.operations.recordsSearch.data.totalPages,
            recordType: state.operations.recordsSearch.data.recordType,
            query: state.operations.recordsSearch.data.query
        }),
        (dispatch: Dispatch<Action>,) => ({
            onPageChange: (page: number, recordType?: RecordType, query?: string) => dispatch(RECORD_SEARCH({
                recordType,
                page,
                query
            }))
        }),
        (stateProps, dispatchProps) => ({
            status: stateProps.status,
            records: stateProps.records,
            errors: stateProps.errors,
            page: stateProps.page,
            totalPages: stateProps.totalPages,
            onPageChange: (page: number) => {
                dispatchProps.onPageChange(page, stateProps.recordType, stateProps.query);
            }
        })
    )(RecordsPaginatedTable);