import { FormikProps } from "formik";

import { useTranslation } from "react-i18next";
import { Button, Form, Segment } from "semantic-ui-react";
import { PRIMARY_COLOR, TRANSLATIONS_NAMESPACE } from "../../utils/constants";
import {
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {PasswordLost, PasswordLostSchema} from "@axys-lab/smart-report-shared";

type Props = RemoteCommandProps & {
    onSubmit: (lostPassword: PasswordLost) => void
};

export const PasswordLostForm = withRemoteForm<Props, PasswordLost>(
    {
        validationSchema: PasswordLostSchema,
        mapPropsToValues: () => ({
            email: ""
        }),
        handleSubmit: (values, {props}) => {
            props.onSubmit(values);
        },
        displayName: "LostPasswordForm",
    },
    {
        dontResetFormOnSuccess: true
    }
)((
    props: Props
        & InjectedRemoteFormProps
        & FormikProps<PasswordLost>
) => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Form
            size="large"
            {...remoteFormProps(props)}
        >
            <Segment stacked={false}>
                <RemoteFormMessages
                    {...props}
                    errorHeaderMessageKey="lost_password_request_error"
                    successHeaderMessageKey="lost_password_request_success"
                    successDetailsMessageKey="lost_password_request_success_details"
                    keepSuccessMessageWhenDirty={true}
                />
                <div>
                    <Form.Input
                        id="email"
                        fluid={true}
                        icon="envelope outline"
                        iconPosition="left"
                        placeholder={t("email_address")}
                        required={true}
                        {...remoteFormFieldProps(
                            props,
                            "email"
                        )}
                    />
                    <Button
                        color={PRIMARY_COLOR}
                        fluid={true}
                        size="large"
                        type="submit"
                        {...remoteFormSubmitProps(props)}
                    >
                        {t("lost_password_request_submit")}
                    </Button>
                </div>
            </Segment>
        </Form>
    );
});