import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {ReprocessButton} from "../views/record-view/record-view-panel/reprocess-button";
import {RECORD_REPROCESS_TRIGGER, RecordProcessStep} from "@axys-lab/smart-report-shared";
import {State} from "../reducers";

const mapStateToProps = (state: State) => ({
    recordId: state.operations.recordView.data.record?.recordId || "",
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onSubmit: (recordId: string, processStep: RecordProcessStep) => {
        dispatch(RECORD_REPROCESS_TRIGGER({recordId, processStep}))
    }
});

export const ConnectedReprocessButton = connect(mapStateToProps, mapDispatchToProps)(ReprocessButton);