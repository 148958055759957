import {Action} from "redux";
import {Channel, eventChannel, EventChannel} from "redux-saga";
import {call, cancelled, delay, fork, put, race, select, take} from "redux-saga/effects";
import {io, Socket} from "socket.io-client";
import {DEFAULT_WEBSOCKET_RESPONSE_TIMEOUT} from "../utils/constants";
import {
    WEBSOCKET_CONNECTED,
    WEBSOCKET_DISCONNECTED,
    WEBSOCKET_RECONNECTED,
    WEBSOCKET_START,
    WEBSOCKET_STARTED,
    WEBSOCKET_STOP,
    WEBSOCKET_STOPPED,
    WEBSOCKET_TIMEOUT
} from "../ducks/operations/sockets";
import {isActionOf} from "typesafe-actions";
import {
    ActionToMessage,
    Message,
    MessageToAction,
    StandardActionToMessage,
    StandardMessageToAction,
} from "@axys-lab/smart-report-shared";
import {State} from "../reducers";
import {PrioritizedAction, prioritizedActionChannel} from "../utils/prioritized-action-channel";

export type WebSocket = {
    namespace: string,
};

export type ConnectedWebSocket = WebSocket & {
    socketId: string | undefined
};

/**
 * When a channel is bound only to sent messages, we send them straight away
 * When a channel is bound only to received messages, we listen for them continuously
 * When a channel is bound to sent & received messages, we send them and wait for the FIRST received message.
 *      If no timeout is specified, we use a default timeout which doesn't trigger any action.
 */
export type MessageChannel = {
    sent?: (string | PrioritizedAction)[],
    received?: string[]
    timeout?: {
        ms?: number,
        trigger: () => Action
    }
};

export type Configuration = {
    serverUrl: string,
    namespace: string,
    channels: MessageChannel[],
    messageToAction?: MessageToAction<Record<string, unknown>>,
    actionToMessage?: ActionToMessage<Record<string, unknown>>
};

const createSocketChannel = (
    socket: Socket,
    channels: MessageChannel[]): EventChannel<Message<Record<string, unknown>>> =>
    eventChannel((emit) => {

        const received = [...new Set<string>(channels
            .filter(channel => channel.received && channel.received.length)
            .map(channel => channel.received as string[])
            .reduce((acc, val) => [...acc, ...val]))];

        const handlers = received
            .map(event => ({
                event,
                handler: (data: Record<string, unknown>) => emit({
                    event,
                    data
                })
            }));

        handlers.forEach(e => socket.on(e.event, e.handler));

        return () => {
            handlers.forEach(e => socket.off(e.event, e.handler));
        };
    });

const connected = (socket: Socket) => {
    return new Promise((resolve) => {
        const listener = () => {
            resolve(socket);
            socket.off("connect", listener);
        };
        socket.on("connect", listener);
    });
};

const disconnected = (socket: Socket) => {

    return new Promise((resolve) => {
        const listener = () => {
            resolve(socket);
            socket.off("disconnect", listener);
        };
        socket.on("disconnect", listener);
    });
};

const reconnected = (socket: Socket) => {
    return new Promise((resolve) => {
        const listener = () => {
            resolve(socket);
            socket.off("connect", listener);
        };
        socket.on("connect", listener);
    });
};

const listenConnectSaga = function* (socket: Socket, namespace: string) {
    const {timeout} = yield race({
        connected: call(connected, socket),
        timeout: delay(2000),
    });
    if (timeout) {
        yield put(WEBSOCKET_TIMEOUT({
            namespace
        }));
    } else {
        yield put(WEBSOCKET_CONNECTED({
            namespace,
            socketId: socket.id
        }));
    }
};

const listenDisconnectSaga = function* (socket: Socket, namespace: string) {
    while (true) {
        yield call(disconnected, socket);
        yield put(WEBSOCKET_DISCONNECTED({
            namespace,
        }));
    }
};

const listenReconnectSaga = function* (socket: Socket, namespace: string) {
    while (true) {
        yield call(reconnected, socket);
        yield put(WEBSOCKET_RECONNECTED({
            namespace,
            socketId: socket.id
        }));
    }
};

const listenSaga = function* (socketChannel: Channel<Record<string, unknown>>, messageToAction: MessageToAction<Record<string, unknown>>): any {
    while (true) {
        const message = yield take(socketChannel);
        const transformed = messageToAction(message);
        yield put(transformed);
    }
};

const awaitResponseSaga = function* (channel: MessageChannel) {

    if (!channel.timeout) {
        return;
    }

    const {timeout} = yield race({
        response: take(channel.received),
        timeout: delay(channel.timeout.ms || DEFAULT_WEBSOCKET_RESPONSE_TIMEOUT)
    });

    if (timeout) {
        yield put(channel.timeout.trigger());
    }
};

const sendSaga = function* (socket: Socket,
                            namespace: string,
                            channels: MessageChannel[],
                            actionToMessage: ActionToMessage<Record<string, unknown>>): any {
    const sent = [...new Set<PrioritizedAction>(
        channels.map(channel => channel.sent?.map(action => {
            if (typeof action === "string") {
                return {
                    action,
                    priority: 0
                }
            }
            return action;
        }) || [])
            .reduce((acc, val) => [
                ...acc,
                ...val
            ], [] as PrioritizedAction[])
    )];

    const bufferedChannel = yield prioritizedActionChannel(sent);

    while (true) {
        const isConnected = yield select((state: State): boolean => !!state.operations.sockets[namespace])
        if (!isConnected) {
            yield take([WEBSOCKET_CONNECTED, WEBSOCKET_RECONNECTED]);
            yield delay(500);
        }

        const raceResult = yield race({
            disconnect: take([WEBSOCKET_DISCONNECTED]),
            action: take(bufferedChannel)
        });

        if (raceResult.disconnect) {
            continue;
        }

        const action = raceResult.action;
        const concernedChannels = channels
            .filter(channel => channel.sent && channel.sent.indexOf(action.type) !== -1);
        const transformed = actionToMessage(action);
        socket.emit(transformed.event, transformed.data);
        if (concernedChannels && concernedChannels.length > 0) {
            for (const channel of concernedChannels) {
                if (channel.received && channel.received.length > 0 && channel.timeout) {
                    yield fork(awaitResponseSaga, channel);
                }
            }
        }
    }
};

const messageRouterSaga = function* (configuration: Configuration): any {
    const socket = io(`${configuration.serverUrl}${configuration.namespace}`, {transports: ['websocket']});
    const messageToAction = configuration.messageToAction || StandardMessageToAction;
    const actionToMessage = configuration.actionToMessage || StandardActionToMessage;
    try {
        yield put(WEBSOCKET_STARTED({
            namespace: configuration.namespace
        }));
        const socketChannel = yield call(createSocketChannel, socket, configuration.channels);
        yield call(listenConnectSaga, socket, configuration.namespace);
        yield fork(listenReconnectSaga, socket, configuration.namespace);
        yield fork(listenDisconnectSaga, socket, configuration.namespace);
        yield fork(listenSaga, socketChannel, messageToAction);
        yield fork(sendSaga, socket, configuration.namespace, configuration.channels, actionToMessage);
    } finally {
        if (yield cancelled()) {
            yield put(WEBSOCKET_STOPPED({
                namespace: configuration.namespace,
            }));
            if (socket) {
                socket.disconnect();
                yield put(WEBSOCKET_DISCONNECTED({
                    namespace: configuration.namespace,
                }));
            }
        }
    }
};

export function* websocketSaga(configuration: Configuration): Generator {
    while (true) {
        yield take((action: Action) => isActionOf(WEBSOCKET_START, action) &&
            action.payload && action.payload.namespace === configuration.namespace);
        yield race({
            task: call(messageRouterSaga, configuration),
            cancel: take((action: Action) => isActionOf(WEBSOCKET_STOP, action) &&
                action.payload && action.payload.namespace === configuration.namespace)
        });
    }
}