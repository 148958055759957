import {all, cancel, cancelled, delay, fork, put, StrictEffect, take, takeEvery} from "redux-saga/effects";
import {getType, PayloadAction} from "typesafe-actions";
import {
    RECORD_CREATE_SUCCESS,
    RECORD_REMOVE_SUCCESS,
    RECORD_UPLOAD_CANCEL,
    RECORD_UPLOAD_CHUNK,
    RECORD_UPLOAD_CHUNK_REQUEST,
    RECORD_UPLOAD_ERROR,
    RECORD_UPLOAD_START,
    RECORD_UPLOAD_SUCCESS,
    RecordChunkRequest,
    RecordReference,
} from "@axys-lab/smart-report-shared";
import {CHANGE_LOCATION} from "../ducks/ui";
import {FileWithPath} from "react-dropzone";
import {Channel, channel, Task} from "redux-saga";
import {Action} from "redux";
import {RECORD_UPLOAD_SELECTED} from "../ducks/operations/records/record-view/upload";

const chunkChannel: Channel<Action> = channel();

function* redirectOnCreate(action: PayloadAction<string, RecordReference>) {
    yield delay(1500);
    yield put(CHANGE_LOCATION({
        method: "push",
        args: [`/records/${action.payload.recordId}`]
    }));
}

function* redirectOnRemove() {
    yield put(CHANGE_LOCATION({
        method: "push",
        args: [`/records`]
    }));
}

function* fileUploadLoop(payload: { file: FileWithPath, recordId: string }): Generator {
    const {file, recordId} = payload;
    const backgroundChunkSend: Task = (yield fork(chunkSendLoop)) as Task;
    try {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            chunkChannel.put(RECORD_UPLOAD_CHUNK({
                recordId,
                data: event.target?.result as ArrayBuffer
            }));
        };

        yield put(RECORD_UPLOAD_START({
            recordId,
            size: file.size,
            name: file.name
        }));

        while (true) {
            const request: PayloadAction<string, RecordChunkRequest> = (yield take(RECORD_UPLOAD_CHUNK_REQUEST)) as PayloadAction<string, RecordChunkRequest>;
            const chunk = file.slice(
                request.payload.offset,
                Number(request.payload.offset) + Math.min(524288, (file.size - request.payload.offset))
            );
            fileReader.readAsArrayBuffer(chunk);
        }
    } finally {
        // the upload has either ended or failed
        if (yield cancelled()) {
            yield cancel(backgroundChunkSend);
        }
    }
}

export function* chunkSendLoop(): Generator<StrictEffect, number, Action> {
    try {
        while (true) {
            const action: Action = yield take(chunkChannel);
            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            // the upload has either ended or failed
        }
    }
}

export function* uploadSaga(): Generator {
    while (true) {
        const uploadStart: PayloadAction<string, RecordReference & { file: FileWithPath }> =
            (yield take(RECORD_UPLOAD_SELECTED)) as PayloadAction<string, RecordReference & { file: FileWithPath }>;
        const backgroundUpload: Task = (yield fork(fileUploadLoop, uploadStart.payload)) as Task;
        yield take([RECORD_UPLOAD_ERROR, RECORD_UPLOAD_SUCCESS, RECORD_UPLOAD_CANCEL]);
        yield cancel(backgroundUpload);
    }
}

export function* recordsSaga(): Generator {
    yield all([
        takeEvery(getType(RECORD_CREATE_SUCCESS), redirectOnCreate),
        takeEvery(getType(RECORD_REMOVE_SUCCESS), redirectOnRemove),
        uploadSaga()
    ]);
}