import {object, ObjectSchema} from "yup";
import {EmailSchema} from "../../utils";

export type EmailUpdate = {
    email: string;
}

export const EmailUpdateSchema: ObjectSchema<EmailUpdate> = object({
    email: EmailSchema()
}).required();
