import {TIMEOUT_MESSAGE_CODE} from "../../../utils/constants";
import {LOCATION_CHANGED} from "../../ui";
import {operation} from "../common";
import {
    USER_EMAIL_VALIDATION_TOKEN_RESEND, USER_EMAIL_VALIDATION_TOKEN_RESEND_ERROR,
    USER_EMAIL_VALIDATION_TOKEN_RESEND_SUCCESS
} from "@axys-lab/smart-report-shared";

export const emailValidationResend = operation(
    [{
        remote: true,
        sent: USER_EMAIL_VALIDATION_TOKEN_RESEND,
        success: USER_EMAIL_VALIDATION_TOKEN_RESEND_SUCCESS,
        errors: USER_EMAIL_VALIDATION_TOKEN_RESEND_ERROR,
        timeout: {
            trigger: () => USER_EMAIL_VALIDATION_TOKEN_RESEND_ERROR({message_codes: [TIMEOUT_MESSAGE_CODE]})
        }
    }],
    LOCATION_CHANGED,
);