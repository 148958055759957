import * as crypto from "crypto";

export const hashCode = (str: string): number => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};

export const intToColor = (int: number): string => {
    // tslint:disable-next-line:no-bitwise
    const c = (int & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();
    return "#00000".substring(0, 7 - c.length) + c;
};

export const stringToColor = (str: string): string => {
    const hash = crypto.createHash("md5").update(str).digest("hex");
    return intToColor(hashCode(hash));
};