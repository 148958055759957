import {all, delay, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {
    NOTIFICATION_DISMISS,
    NOTIFICATION_DISPLAY,
    NotificationType,
    Record,
    RECORD_PROCESS_ERROR,
    RECORD_PROCESS_SUCCESS
} from "@axys-lab/smart-report-shared";
import {WEBSOCKET_DISCONNECTED, WEBSOCKET_RECONNECTED} from "../ducks/operations/sockets";
import {v4} from "uuid";
import {getType, PayloadAction} from "typesafe-actions";
import {ErrorResponse} from "@axys-lab/microservices-common-shared";
import {State} from "../reducers";

export function* autoDismiss(): Generator {
    while (true) {
        const state: State = (yield select()) as State;
        for (const notification of state.ui.notifications) {
            if (Date.now() > (notification.timestamp + 5000)) {
                yield put(NOTIFICATION_DISMISS({id: notification.id}));
            }
        }
        yield delay(1000);
    }
}

export function* connectionLostSaga(): Generator {
    yield put(NOTIFICATION_DISPLAY({
        id: v4(),
        type: NotificationType.ERROR,
        message_codes: ["connection_lost"]
    }));
}

export function* connectionBackSaga(): Generator {
    yield put(NOTIFICATION_DISPLAY({
        id: v4(),
        type: NotificationType.INFO,
        message_codes: ["connection_back"]
    }));
}

function* recordProcessSuccess(action: PayloadAction<string, Record>): Generator {
    yield put(NOTIFICATION_DISPLAY({
        id: v4(),
        message_codes: [{
            message_code: "record_process_success",
            params: {
                name: action.payload.name
            },
            link: `/records/${action.payload.recordId}`
        }],
        type: NotificationType.INFO
    }))
}

function* recordProcessError(action: PayloadAction<string, ErrorResponse>): Generator {
    yield put(NOTIFICATION_DISPLAY({
        id: v4(),
        message_codes: action.payload.message_codes,
        type: NotificationType.ERROR
    }))
}

export function* notificationsSaga(): Generator {
    yield all([
        autoDismiss(),
        takeLatest(WEBSOCKET_DISCONNECTED, connectionLostSaga),
        takeLatest(WEBSOCKET_RECONNECTED, connectionBackSaga),
        takeEvery(getType(RECORD_PROCESS_ERROR), recordProcessError),
        takeEvery(getType(RECORD_PROCESS_SUCCESS), recordProcessSuccess),
    ]);
}