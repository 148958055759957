import {createRoot} from 'react-dom/client';
import {App} from "./app";

import "./utils/i18n";

import 'semantic-ui-less/semantic.less';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';


const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App/>);
