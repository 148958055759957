import {boolean, mixed, object, ObjectSchema} from "yup";
import {RecordReference} from "./record-reference";
import {RecordIdSchema} from "../../utils";
import {FileChunk} from "./file-chunk";

export type RecordChunk = RecordReference & FileChunk;

export const RecordChunkSchema: ObjectSchema<RecordChunk> = object({
    data: mixed<ArrayBuffer>()
        .required(`data_required`),
    last: boolean(),
    recordId: RecordIdSchema
}).required();
