import {ReactNode} from "react";
import {Container, Menu, Segment, Sidebar} from "semantic-ui-react";
import "./style.css";
import {ConnectedUserCardMobile} from "../../../containers/connected-user-card-mobile";
import {MenuItems} from "../menu-items";

export const MobileInnerLayout = (props: {
    displayBar: boolean,
    children?: ReactNode
}): JSX.Element => {
    return (
        <div
            className="layout-inner-mobile"
        >
            <Sidebar.Pushable as={Segment}>
                <Sidebar
                    as={Menu}
                    animation="overlay"
                    inverted={true}
                    vertical={true}
                    visible={props.displayBar}
                    width="wide"
                    size="massive"
                >
                    <Menu.Item>
                        <ConnectedUserCardMobile/>
                    </Menu.Item>
                    <MenuItems/>
                </Sidebar>

                <Sidebar.Pusher>
                    <Container text={true}>
                        {props.children}
                    </Container>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    );
};