import {useTranslation} from "react-i18next";
import {Container, Header} from "semantic-ui-react";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {Layout} from "../../components/layout";
import {InnerLayout} from "../../components/inner-layout";
import {ConnectedRecordsPaginatedTable} from "../../containers/connected-records-paginated-table";
import {ConnectedRecordsSearchMenu} from "../../containers/connected-records-search-menu";

export const Records = (): JSX.Element => {
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <Layout>
            <InnerLayout
                header={
                    <Container
                        textAlign="center"
                        text={true}
                    >
                        <Header
                            as="h2"
                            content={t("records")}
                            inverted={true}
                            size="huge"
                        />
                    </Container>
                }
                menuBox={
                    <ConnectedRecordsSearchMenu/>
                }
            >
                <div className="records">
                    <ConnectedRecordsPaginatedTable/>
                </div>
            </InnerLayout>
        </Layout>
    );
};