import {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Button, Icon} from "semantic-ui-react";
import {v4} from "uuid";
import {TRANSLATIONS_NAMESPACE} from "../../../utils/constants";
import {SocialProvider, UserSocialLoginAuthorized} from "@axys-lab/smart-report-shared";
import {SocialProviderColorScheme} from "../../../utils/social-providers-icons";
import {useInterval} from "react-use";
import {useEventListener} from "../../../utils/use-event-listener.hook";
import "./style.css";

type Props = WithTranslation & {
    onOpen?: (provider: SocialProvider, socialLoginId: string) => void;
    onUnexpectedClose: () => void;
    onAuthorized: (success: UserSocialLoginAuthorized) => void;
    provider: SocialProvider;
    colorScheme: SocialProviderColorScheme;
    buttonText: string;
    url: string;
    parameters?: Record<string, string | ReadonlyArray<string> | number>;
    disabled?: boolean;
    forcedClose?: boolean;
};

type State = {
    popup?: Window,
    checkForResponse: boolean,
    socialLoginId: string,
    expectedClose: boolean,
};

const POPUP_NAME = "socialLoginPopup";

const openPopup = (formRef: MutableRefObject<HTMLFormElement | null>) => {
    const formRefValue = formRef?.current;
    if (formRefValue) {
        const width = 600;
        const height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const popup = window.open(
            "",
            POPUP_NAME,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, 
                        scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
                        height=${height}, top=${top}, left=${left}`
        );

        formRefValue.submit();

        return popup;
    }
    return false;
}

const UnderlyingSocialLogin = (props: Props) => {
    const [state, setState] = useState<State>({socialLoginId: v4(), expectedClose: false, checkForResponse: false});
    const {
        t,
        colorScheme,
        disabled,
        buttonText,
        url,
        parameters,
        forcedClose,
        onAuthorized,
        provider,
        onUnexpectedClose,
        onOpen
    } = props;
    const {socialLoginId, expectedClose, checkForResponse, popup} = state;
    const prevForcedClose = useRef<boolean | undefined>();
    const formRef = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        if (!prevForcedClose.current && forcedClose) {
            closePopup();
        }
        prevForcedClose.current = forcedClose;
    }, [forcedClose, prevForcedClose]);

    useInterval(
        () => {
            if (!popup || popup.closed || popup.closed === undefined) {
                setState({...state, checkForResponse: false})
                if (!expectedClose) {
                    onUnexpectedClose();
                }
            }
        },
        checkForResponse ? 1000 : null
    )

    const authorizationCodeListener = useCallback((event: MessageEvent) => {
        if (checkForResponse) {
            const socialLoginId = event.data.socialLoginId;
            const code = event.data.code;
            if (!socialLoginId && !code) {
                return;
            }

            if (event.data.socialLoginId !== socialLoginId) {
                closePopup();
                return;
            }

            const oauthState = event.data.state;
            const cookies = event.data.cookies;

            if (code) {
                onAuthorized({
                    provider,
                    code,
                    state: oauthState,
                    cookies
                });
            }
            closePopup();
        }
    }, [socialLoginId, onAuthorized, provider, checkForResponse]);

    const startAuthentication = useCallback(() => {
        if (!disabled) {
            if (onOpen) {
                onOpen(provider, socialLoginId);
            }
            const result = openPopup(formRef);
            if (result) {
                setState({
                    socialLoginId,
                    expectedClose,
                    checkForResponse: true,
                    popup: result
                });
            }
        }
    }, [onOpen, socialLoginId, expectedClose, disabled]);

    const closePopup = useCallback(() => {
        setState({
            expectedClose,
            socialLoginId,
            checkForResponse: false,
            ...(popup ? {
                expectedClose: true,
                popup: undefined
            } : {})
        });
        if (popup) {
            popup.close();
        }
    }, [popup, expectedClose, socialLoginId])

    useEventListener("message", authorizationCodeListener);

    return (
        <form style={{display: "inline-block"}}
              ref={formRef}
              method="post"
              action={url}
              target={POPUP_NAME}
        >
            <Button
                color={colorScheme.color}
                onClick={startAuthentication}
                disabled={disabled}
            >
                <Icon name={colorScheme.icon}/> {t(buttonText)}
            </Button>
            {parameters && Object.keys(parameters).map(name => (
                <input key={name} type="hidden" name={name} value={parameters[name]}/>
            ))}
            <input type="hidden" name="socialLoginId" value={socialLoginId}/>
        </form>
    );
};

export const SocialLogin = withTranslation(TRANSLATIONS_NAMESPACE)(UnderlyingSocialLogin);