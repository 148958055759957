import {FormikProps} from "formik";

import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {PRIMARY_COLOR, TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {
    InjectedRemoteFormProps,
    RemoteCommandProps,
    remoteFormFieldProps,
    RemoteFormMessages,
    remoteFormProps,
    remoteFormSubmitProps,
    withRemoteForm
} from "../../utils/form";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {PasswordUpdate, PasswordUpdateSchema} from "@axys-lab/smart-report-shared";

type Props = RemoteCommandProps & {
    creation: boolean;
    onSubmit: (passwordChange: PasswordUpdate) => void;
};

export const PasswordUpdateForm = withRemoteForm<Props, PasswordUpdate>(
    {
        validationSchema: PasswordUpdateSchema,
        mapPropsToValues: (props: Props) => ({
            creation: props.creation,
            password: "",
            newPassword: "",
            newPasswordConfirmation: ""
        }),
        handleSubmit: (values, {props}) => {
            props.onSubmit(values);
        },
        displayName: "PasswordUpdateForm",
    },
    {
        dontResetFormOnSuccess: true
    }
)((props: Props
    & InjectedRemoteFormProps
    & FormikProps<PasswordUpdate>) => {
    const {
        creation,
        remoteStatus,
        setFieldValue,
        resetForm,
        values
    } = props;

    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);

    useEffect(
        () => {
            if (creation !== values.creation) {
                void setFieldValue("creation", creation);
            }
        },
        [creation, setFieldValue, values.creation]
    );

    useEffect(
        () => {
            if (remoteStatus === RemoteCommandStatus.SUCCESS) {
                resetForm({
                    values: {
                        creation,
                        password: "",
                        newPassword: "",
                        newPasswordConfirmation: ""
                    }
                });
            }
        },
        [remoteStatus, creation, resetForm]
    );

    return (
        <Segment.Group stacked={true}>
            <Form
                size="large"
                {...remoteFormProps(props)}
            >
                <Header as="h2" attached="top">
                    {t("password_update")}
                </Header>

                <RemoteFormMessages
                    {...props}
                    errorHeaderMessageKey="password_update_error"
                    successHeaderMessageKey="password_update_success"
                    successDetailsMessageKey="password_update_success_details"
                    attached={true}
                />

                {
                    creation &&
                    <Message info={true} attached={true}>
                        {t("password_creation_for_social_account")}
                    </Message>
                }

                <Segment attached={true} padded={true}>
                    <Grid columns={2}>

                        {
                            !creation &&
                            <Grid.Column>

                                <Form.Input
                                    id="password"
                                    fluid={true}
                                    label={t("password")}
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder={t("password")}
                                    type="password"
                                    required={true}
                                    {...remoteFormFieldProps(
                                        props,
                                        "password"
                                    )}
                                />
                            </Grid.Column>
                        }

                        <Grid.Row>
                            <Grid.Column>
                                <Form.Input
                                    id="newPassword"
                                    fluid={true}
                                    label={t("new_password")}
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder={t("new_password")}
                                    type="password"
                                    required={true}
                                    {...remoteFormFieldProps(
                                        props,
                                        "newPassword"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    id="newPasswordConfirmation"
                                    fluid={true}
                                    label={t("new_password_confirmation")}
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder={t("new_password_confirmation")}
                                    type="password"
                                    required={true}
                                    {...remoteFormFieldProps(
                                        props,
                                        "newPasswordConfirmation"
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Segment>

                <Segment attached={true} padded={true}>
                    <Button
                        color={PRIMARY_COLOR}
                        fluid={true}
                        size="large"
                        type="submit"
                        {...remoteFormSubmitProps(props)}
                    >
                        {t("password_update_submit")}
                    </Button>
                </Segment>
            </Form>
        </Segment.Group>
    );
});