import {Container, Message, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {TRANSLATIONS_NAMESPACE} from "../../utils/constants";
import {useState} from "react";
import {OneDrivePicker} from "./onedrive-picker";
import {OneDriveSettings, RemoteFile} from "@axys-lab/smart-report-shared";
import {RemoteCommandStatus} from "../../utils/remote-command-status";
import {Loader} from "../loader";

export type CloudFilePickerProps = {
    disabled?: boolean;
    errors: string[];
    status: RemoteCommandStatus;
    className?: string;
    onedriveSettings: OneDriveSettings | null;
    downloadFile: (remoteFile: RemoteFile) => void;
}

export type CloudFilePickerState = {
    provider: CloudFileProvider | null;
}

export enum CloudFileProvider {
    ONEDRIVE = "onedrive"
}

export const CloudFilePicker = (props: CloudFilePickerProps): JSX.Element => {
    const {onedriveSettings, disabled, status, downloadFile, className, errors} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    const [state, setState] = useState<CloudFilePickerState>({
        provider: null
    });
    const {provider} = state;

    return (
        <Container className={`onedrive-picker ${className || ""}`}>
            <Segment basic={true} textAlign="center">
                {errors && errors.length ? (<Message
                    negative={true}
                    list={errors.map(error => t(error))}
                />) : (<></>)}
                <Segment basic={true}>
                    {status === RemoteCommandStatus.PENDING ? (
                        <Loader page={false} display={true} message={t("record_remote_file_downloading")}/>
                    ) : (
                        <>

                            {onedriveSettings && (!provider || provider === CloudFileProvider.ONEDRIVE) ? (
                                <OneDrivePicker
                                    {...onedriveSettings}
                                    disabled={disabled}
                                    cancel={() => setState({provider: null})}
                                    pickProvider={() => setState({provider: CloudFileProvider.ONEDRIVE})}
                                    downloadFile={downloadFile}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </Segment>
            </Segment>
        </Container>
    );
};


