import { object, ObjectSchema } from "yup";
import { TokenSchema } from "../../utils/schemas";

export type EmailValidationToken = {
    token: string;
}

export const EmailValidationTokenSchema: ObjectSchema<EmailValidationToken> = object({
    token: TokenSchema()
}).required();


