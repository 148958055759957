import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Header, Image } from "semantic-ui-react";
import { TRANSLATIONS_NAMESPACE } from "../../utils/constants";

import "./style.css";

export const UserCardMobile = (props: {
    avatar: string;
    username: string;
    logout: () => void;
}): JSX.Element => {
    const {avatar, username, logout} = props;
    const {t} = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <div className="user-profile-card-mobile">
            {
                avatar &&
                <Image circular={true} floated="right" size="mini" src={avatar}/>
            }
            <Header as="h4">{username}</Header>
            <p>
                <Link to="/me">{t("user_profile")}</Link> - <a href="#logout" onClick={(e) => {
                    e.preventDefault();
                    logout();
            }}>{t("logout")}</a>
            </p>
        </div>
    );
};
